import {
    TOGGLE_THEME,
    FETCH_THEME
} from "./types";

export function payload(CONST, data) {
    return {
        type: CONST,
        payload: data
    };
}

export function fetch(cb = null) {
    if (sessionStorage.getItem("theme") === null) {
        sessionStorage.setItem("theme", false);
    }
    return function (dispatch) {
        dispatch(payload(FETCH_THEME, sessionStorage.getItem("theme") === 'true'));
        if (cb) cb();
    };
}

export function toggle(value) {
    sessionStorage.setItem("theme", value);
    return function (dispatch) {
        dispatch(payload(TOGGLE_THEME, sessionStorage.getItem("theme") === 'true'));
    };
}
