import React from "react";
import Loadable from "react-loadable";

import ErrorLayout from "../views/error/error-layout";

function Loading() {
  return <div>Loading...</div>;
}

const Error404Index = Loadable({
  loader: () => import("../views/error/error-404"),
  loading: Loading
});

const Error500Index = Loadable({
  loader: () => import("../views/error/error-500"),
  loading: Loading
});

const routes = [
  { path: "/error", name: "Auth Layout", component: ErrorLayout, exact: true },
  { path: "/error/404", name: "Error 404", component: Error404Index },
  { path: "/error/500", name: "Error 500", component: Error500Index },
];

export default routes;
