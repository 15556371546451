import React, { Component } from "react";
import Slider from "react-slick";
import Header from "../../../../components/sites/header";
import WOW from "wowjs"; 

const SETTINGS = {
  dots: false,
  infinite: true,
  fade: true,
  speed: 3500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  arrows: true,
  lazyLoad: true
};

class HeaderSection extends Component {
  constructor() {
    super();
    this.scrolled = false;
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {
    if (!this.scrolled) {
      this.scrolled = true;
      new WOW.WOW({
        live: false
      }).init();
    }
  };

  renderCarouseItem() {
    const { data } = this.props;
    const carouseItemElement = data.map((item, index) => (
      <div className="container carousel-container p-0 m-0" key={index}>
        <div className="row">
          <div className="col-md-6 mb-2 mb-xs-0">
            <div
              className="card bg-dark border-0 course-image carousel-image-shadow"
              style={{
                background: "linear-gradient(to right, #172a4d, rgba(38, 35, 31, .5) 20%, rgba(65, 60, 53, 0) 50%, rgba(38, 35, 31, .5) 70%, #172a4d), url(" + item.src + ")",
                height: "100%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "top"
              }}
            />
          </div>
          <div className="col-md-6 text-white">
            <div
              className="pl-md-5"
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
          </div>
          {/* <div className="col-md-12 mb-2 mb-xs-0">
            <div
              className="card bg-dark shadow border-0 course-image"
              style={{
                backgroundImage: "url(" + item.src + ")",
                height: "480px",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "top"
              }}
            />
          </div> */}
        </div>
      </div>
    ));
    return carouseItemElement;
  }

  render() {
    return (
      <Header>
        <div className="container carousel-container slider-xs wow fadeIn">
          <Slider {...SETTINGS}>{this.renderCarouseItem()}</Slider>
        </div>
      </Header>
    );
  }
}

export default HeaderSection;
