import React from "react";
import { FormGroup, Label, FormFeedback, FormText } from "reactstrap";
import { TextMask, InputAdapter } from "react-text-mask-hoc";

class InputMaskField extends React.Component {
  renderLabel(name, label) {
    return label ? (<Label htmlFor={name}>{label}</Label>) : '';
  }
  
  render() {
    const {
      input,
      custom,
      mask,
      name,
      label,
      placeholder,
      description,
      meta: { touched, error, valid }
    } = this.props;
    return (
      <FormGroup>
        {this.renderLabel(name, label)}
        <TextMask
          mask={mask}
          placeholder={placeholder}
          Component={InputAdapter}
          className={
            (touched && (valid === false || error !== undefined)
              ? "is-invalid "
              : "") + "form-control"
          }
          {...input}
          {...custom}
        />
        <FormText color="muted">{description}</FormText>
        {touched &&
          ((valid === false || error !== undefined) && (
            <FormFeedback>{error}</FormFeedback>
          ))}
      </FormGroup>
    );
  }
}

export default InputMaskField;
