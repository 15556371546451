import React, { Component } from "react";
import { Link } from "react-router-dom";
import navigation from "../../../routes/management-nav";
import { connect } from "react-redux";
import { PATIENT } from "../../../config";

class SideBar extends Component {
  getRoles = array => {
    let roles = [];
    let index = 0;
    if (array) {
      for (var key in array) {
        if (!array.hasOwnProperty(key)) continue;
        roles[index++] = array[key]["roleId"];
      }
    }
    return roles;
  };

  render() {
    const { data } = this.props;
    const roleMapping = (data && data.UserProfile.RoleMapping) || null;
    const permission = (data && data.UserProfile.Permission) || PATIENT;
    const roles = this.getRoles(roleMapping);
    return (
      <aside className="main-sidebar">
        <section className="sidebar">
          <ul className="sidebar-menu" data-widget="tree">
            {navigation.items.map((item, index) => {
              if (
                item.roles.length > 0 &&
                ((!item.roles.includes(permission) && permission === PATIENT) ||
                  (item.roles.includes(PATIENT) && permission < PATIENT))
              )
                return null;
              return item.roles.length === 0 ||
                roles.some(r => item.roles.includes(r)) ? (
                item.header ? (
                  <li className="header" key={index}>
                    {item.name}
                  </li>
                ) : (
                  <li className="treeview" key={index}>
                    <Link to={item.url}>
                      <i className={"fa " + item.icon} />
                      <span>{item.name}</span>
                    </Link>
                  </li>
                )
              ) : null;
            })}
          </ul>
        </section>
      </aside>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    data: state.auth.data
  };
}

export default connect(mapStateToProps)(SideBar);
