import React, { Component } from "react";
import ScrollIntoView from "../../../components/scroll-to-view";
import { HeaderSection } from "./sections";
import Contact from "../../../components/sites/contact";
import Footer from "../../../components/sites/footer";
import Certification from "../../../components/sites//certification";

class ContactIndex extends Component {
  render() {
    return (
      <ScrollIntoView>
        <HeaderSection />
        <Contact />
        <Certification />
        <div className="mt-5">&nbsp;</div>
        <Footer triangleStyle={false} />
      </ScrollIntoView>
    );
  }
}

export default ContactIndex;
