import React, { Component } from "react";
import ReactCompareImage from "react-compare-image";
import WOW from "wowjs";

const YELLOW_TRIANGLE_IMAGE = "/static/media/sites/home/works/yellow-triangle.png";

class WorksSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indexSelected: 0
    };
    this.scrolled = false;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {
    if (!this.scrolled) {
      this.scrolled = true;
      new WOW.WOW({
        live: false
      }).init();
    }
  };

  handleWorkItem = index => {
    this.setState({
      indexSelected: index
    });
  };

  renderWorksItem(data) {
    const worksItemElement = data.map((item, index) => (
      <div
        key={index}
        className={
          "description-box " +
          (index === this.state.indexSelected ? "active" : "")
        }
        onClick={() => this.handleWorkItem(index)}
      >
        <p>
          {item.description}
          <span className="line" />
          <span className="area">{item.area}</span>
        </p>
        <img src={YELLOW_TRIANGLE_IMAGE} alt="" className="yellow-triangle-image" />
      </div>
    ));
    return worksItemElement;
  }

  render() {
    const { data } = this.props;
    if (data == null) return null;
    return (
      <div className="py-5 bg-secondary">
        <div className="container">
          <div className="row row-grid align-items-center">
            <div className="col-md-6 order-md-2 zindex-100 wow fadeInRight">
              <ReactCompareImage
                leftImage={data[this.state.indexSelected].beforeImage}
                rightImage={data[this.state.indexSelected].afterImage}
                sliderLineWidth={1}
                handleSize={20}
                // hover
                // skeleton={<div>loading</div>}
              />
            </div>
            <div className="col-md-6 order-md-1">
              <div className="pr-md-5 our-works">
                <h3 className="wow fadeIn" data-wow-delay="0.5s">BEFORE &amp; AFTER</h3>
                <h4 className="display-3 wow fadeIn" data-wow-delay="0.6s">Gallery</h4>
                <div className="text-wrapper col-md-6 col-sm-12 col-xs-12">
                  <div className="descriptions-wrapper col-md-8 col-sm-12 p-0 wow fadeInDown">
                    {this.renderWorksItem(data)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WorksSection;
