export const API_URL = "https://splendoraesthetics.com/api";
export const MAIL_CONFIG = {
  email: "info@splendoraesthetics.com",
  senderName: "Splendor Aethetics"
};
export const MAIL_ATTACHMENT_SIZE_LIMIT = 1024 * 1024 * 20; // 20MB
export const MAIL_ATTACHMENT_LENGTH_LIMIT = 5;
export const CLINIC_ID = 1;
export const SUPERADMIN = 1;
export const ADMIN = 2;
export const EMPLOYEE = 3;
export const PATIENT = 4;
export const SYSTEM = 5;
export const ROLES = {
  1: "Super Admin",
  2: "Admin",
  3: "Employee",
  4: "Patient"
};
export const LIMIT = 10;
export const PRIMARY_COLOR = "#20a8d8";
export const SALMON_COLOR = "salmon";
export const SUCCESS_COLOR = "#4dbd74";
export const LIGHT_COLOR = "#c8ced3";
export const INFO_COLOR = "#63c2de";
export const WARNING_COLOR = "#ffc107";
export const DANGER_COLOR = "#f86c6b";
export const PURPLE_COLOR = "#8965e0";
export const STATES = [
  { value: "AL", text: "Alabama" },
  { value: "AK", text: "Alaska" },
  { value: "AZ", text: "Arizona" },
  { value: "AR", text: "Arkansas" },
  { value: "CA", text: "California" },
  { value: "CO", text: "Colorado" },
  { value: "CT", text: "Connecticut" },
  { value: "DE", text: "Delaware" },
  { value: "FL", text: "Florida" },
  { value: "GA", text: "Georgia" },
  { value: "HI", text: "Hawaii" },
  { value: "ID", text: "Idaho" },
  { value: "IL", text: "Illinois" },
  { value: "IN", text: "Indiana" },
  { value: "IA", text: "Iowa" },
  { value: "KS", text: "Kansas" },
  { value: "KY", text: "Kentucky" },
  { value: "LA", text: "Louisiana" },
  { value: "ME", text: "Maine" },
  { value: "MD", text: "Maryland" },
  { value: "MA", text: "Massachusetts" },
  { value: "MI", text: "Michigan" },
  { value: "MN", text: "Minnesota" },
  { value: "MS", text: "Mississippi" },
  { value: "MO", text: "Missouri" },
  { value: "MT", text: "Montana" },
  { value: "NE", text: "Nebraska" },
  { value: "NV", text: "Nevada" },
  { value: "NH", text: "New Hampshire" },
  { value: "NJ", text: "New Jersey" },
  { value: "NM", text: "New Mexico" },
  { value: "NY", text: "New York" },
  { value: "NC", text: "North Carolina" },
  { value: "ND", text: "North Dakota" },
  { value: "OH", text: "Ohio" },
  { value: "OK", text: "Oklahoma" },
  { value: "OR", text: "Oregon" },
  { value: "PA", text: "Pennsylvania" },
  { value: "RI", text: "Rhode Island" },
  { value: "SC", text: "South Carolina" },
  { value: "SD", text: "South Dakota" },
  { value: "TN", text: "Tennessee" },
  { value: "TX", text: "Texas" },
  { value: "UT", text: "Utah" },
  { value: "VT", text: "Vermont" },
  { value: "VA", text: "Virginia" },
  { value: "WA", text: "Washington" },
  { value: "WV", text: "West Virginia" },
  { value: "WI", text: "Wisconsin" },
  { value: "WY", text: "Wyoming" }
];

export const GENDERS = [
  { value: "male", text: "Male" },
  { value: "female", text: "Female" },
  { value: "other", text: "Other" }
];

export const URL_TYPES = [
  { value: "image", text: "Image" },
  { value: "video", text: "Video" }
];

export const DOCUMENT_TYPES = [
  { value: "before & after", text: "Before & After" },
  { value: "document", text: "Document" },
  { value: "other", text: "Other" }
];

export const PATIENT_FORM_TYPES = [
  { value: "fotona", text: "Fotona" },
  { value: "picosure laser treatment", text: "Picosure Laser Treatment" },
  { value: "scan form", text: "Scan Form" },
  // { value: "other", text: "Other" }
];

export const PLTR_FORM_DIAGNOSIS = [
  { value: "pigmented lesions", text: "Pigmented lesions" },
  { value: "acne scars", text: "Acene scars" },
  { value: "wrinkles", text: "Wrinkles" },
];

export const PLTR_FORM_LASER_WAVELENGTH = [
  { value: "755 nm", text: "755 nm" },
  { value: "532 nm", text: "532 nm" },
  { value: "1064 nm", text: "1064 nm" },
];

export const PLTR_FORM_ANESTHETIC = [
  { value: "topical", text: "Topical" },
];

export const PLTR_FORM_TREATMENT_AREA = [
  { value: "l-face", text: "L-Face" },
  { value: "r-face", text: "R-Face" },
];

export const FOTONA_FORM_TREATMENTS = [
  { value: "nightlase", text: "NightLase" },
  { value: "acne & acne scar", text: "Acne & Acne Scar" },
  { value: "fotona 4d", text: "Fotona 4D" },
  { value: "fotona 4d-men", text: "Fotona 4D-Men" },
  { value: "hair reduction", text: "Hair Reduction" },
  { value: "scar revision", text: "Scar Revision" },
  { value: "skin recurfacing", text: "Skin Recurfacing" },
  { value: "vascular lesion", text: "Vascular Lesion" },
  { value: "liplase", text: "LipLase" },
  { value: "smooth eye", text: "Smooth Eye" },
  { value: "hair restart", text: "HAIR Restart" },
  { value: "intimalase", text: "IntimaLase" },
  { value: "tightsculpting", text: "TightSculpting" },
  { value: "n/a", text: "N/A" },
];

export const FOTONA_FORM_LASERS = [
  { value: "Er:YAD (2940 nm)", text: "Er:YAD (2940 nm)" },
  { value: "Nd:YAD (1064 nm)", text: "Nd:YAD (1064 nm)" },
  { value: "n/a", text: "N/A" },
];

export const FOTONA_FORM_PULSE_DURATION_TYPE = [
  { value: "ms (milliseconds)", text: "ms (milliseconds)" },
  { value: "us (microseconds)", text: "us (microseconds)" },
];

export const FOTONA_FORM_PULSE_MODE = [
  { value: "MSR", text: "MSR" },
  { value: "SP", text: "SP" },
  { value: "LP", text: "LP" },
  { value: "VLP", text: "VLP" },
  { value: "XLP", text: "XLP" },
  { value: "SMOOTH", text: "SMOOTH" },
  { value: "V-SMOOTH", text: "V-SMOOTH" },
  { value: "TURBO 1", text: "TURBO 1" },
  { value: "TURBO 2", text: "TURBO 2" },
  { value: "TURBO 3", text: "TURBO 3" },
  { value: "TURBO 4", text: "TURBO 4" },
  { value: "TURBO 5", text: "TURBO 5" },
  { value: "TURBO 6", text: "TURBO 6" },
  { value: "FRAC-3", text: "FRAC-3" },
  { value: "VERSA", text: "VERSA" },
  { value: "PIANO", text: "PIANO" },
  { value: "n/a", text: "N/A" },
];

export const FOTONA_FORM_HANDPIECES = [
  { value: "R11", text: "R11" },
  { value: "R33-T", text: "R33-T" },
  { value: "FS01", text: "FS01" },
  { value: "PS03X", text: "PS03X" },
  { value: "LA-Addapter", text: "LA-Addapter" },
  { value: "G-Set Circular", text: "G-Set Circular" },
  { value: "G-Set Angular", text: "G-Set Angular" },
  { value: "T-Runner", text: "T-Runner" },
  { value: "L-Runner", text: "L-Runner" },
  { value: "n/a", text: "N/A" },
];

export const FOTONA_FORM_COOLINGS = [
  { value: "1", text: "Yes" },
  { value: "0", text: "No" },
];

export const FOTONA_FORM_STACKING_OF_PULSES = [
  { value: "1", text: "Yes" },
  { value: "0", text: "No" },
];

export const FOTONA_FORM_ANESTHETIC = [
  { value: "Topical Cream", text: "Topical Cream" },
  { value: "Local Block", text: "Local Block" },
  { value: "n/a", text: "N/A" },
];

export const CURRENCY = [{ value: "usd", text: "USD" }];

export const SERVICE_CATEGORY = [
  { value: "face", text: "Face" }, 
  { value: "body", text: "Body" },
  { value: "men health", text: "Men's Health" },
  { value: "neck", text: "Neck" },
  { value: "skin care", text: "Skin Care" },
  { value: "woman health", text: "Woman's Health" },
  { value: "general", text: "General" },
];

export const AVAILABILITIES_TYPE = [
  { value: "flexible", text: "Flexible" },
  { value: "fixed", text: "Fixed" }
];

export const LANGUAGES = [
  { value: "Afrikaans", text: "Afrikaans" },
  { value: "Albanian", text: "Albanian" },
  { value: "Amharic", text: "Amharic" },
  { value: "Arabic (Egyptian Spoken)", text: "Arabic (Egyptian Spoken)" },
  { value: "Arabic (Levantine)", text: "Arabic (Levantine)" },
  { value: "Arabic (Modern Standard)", text: "Arabic (Modern Standard)" },
  { value: "Arabic (Moroccan Spoken)", text: "Arabic (Moroccan Spoken)" },
  { value: "Arabic (Overview)", text: "Arabic (Overview)" },
  { value: "Aramaic", text: "Aramaic" },
  { value: "Armenian", text: "Armenian" },
  { value: "Assamese", text: "Assamese" },
  { value: "Aymara", text: "Aymara" },
  { value: "Azerbaijani", text: "Azerbaijani" },
  { value: "Balochi", text: "Balochi" },
  { value: "Bamanankan", text: "Bamanankan" },
  { value: "Bashkort (Bashkir)", text: "Bashkort (Bashkir)" },
  { value: "Basque", text: "Basque" },
  { value: "Belarusan", text: "Belarusan" },
  { value: "Bengali", text: "Bengali" },
  { value: "Bhojpuri", text: "Bhojpuri" },
  { value: "Bislama", text: "Bislama" },
  { value: "Bosnian", text: "Bosnian" },
  { value: "Brahui", text: "Brahui" },
  { value: "Bulgarian", text: "Bulgarian" },
  { value: "Burmese", text: "Burmese" },
  { value: "Cantonese", text: "Cantonese" },
  { value: "Catalan", text: "Catalan" },
  { value: "Cebuano", text: "Cebuano" },
  { value: "Chechen", text: "Chechen" },
  { value: "Cherokee", text: "Cherokee" },
  { value: "Croatian", text: "Croatian" },
  { value: "Czech", text: "Czech" },
  { value: "Dakota", text: "Dakota" },
  { value: "Danish", text: "Danish" },
  { value: "Dari", text: "Dari" },
  { value: "Dholuo", text: "Dholuo" },
  { value: "Dutch", text: "Dutch" },
  { value: "English", text: "English" },
  { value: "Esperanto", text: "Esperanto" },
  { value: "Estonian", text: "Estonian" },
  { value: "Ewe", text: "Éwé" },
  { value: "Finnish", text: "Finnish" },
  { value: "French", text: "French" },
  { value: "Georgian", text: "Georgian" },
  { value: "German", text: "German" },
  { value: "Gikuyu", text: "Gikuyu" },
  { value: "Greek", text: "Greek" },
  { value: "Guarani", text: "Guarani" },
  { value: "Gujarati", text: "Gujarati" },
  { value: "Haitian Creole", text: "Haitian Creole" },
  { value: "Hausa", text: "Hausa" },
  { value: "Hawaiian", text: "Hawaiian" },
  { value: "Hawaiian Creole", text: "Hawaiian Creole" },
  { value: "Hebrew", text: "Hebrew" },
  { value: "Hiligaynon", text: "Hiligaynon" },
  { value: "Hindi", text: "Hindi" },
  { value: "Hungarian", text: "Hungarian" },
  { value: "Icelandic", text: "Icelandic" },
  { value: "Igbo", text: "Igbo" },
  { value: "Ilocano", text: "Ilocano" },
  {
    value: "Indonesian (Bahasa Indonesia)",
    text: "Indonesian (Bahasa Indonesia)"
  },
  { value: "Inuit/Inupiaq", text: "Inuit/Inupiaq" },
  { value: "Irish Gaelic", text: "Irish Gaelic" },
  { value: "Italian", text: "Italian" },
  { value: "Japanese", text: "Japanese" },
  { value: "Jarai", text: "Jarai" },
  { value: "Javanese", text: "Javanese" },
  { value: "Kiche’", text: "K’iche’" },
  { value: "Kabyle", text: "Kabyle" },
  { value: "Kannada", text: "Kannada" },
  { value: "Kashmiri", text: "Kashmiri" },
  { value: "Kazakh", text: "Kazakh" },
  { value: "Khmer", text: "Khmer" },
  { value: "Khoekhoe", text: "Khoekhoe" },
  { value: "Korean", text: "Korean" },
  { value: "Kurdish", text: "Kurdish" },
  { value: "Kyrgyz", text: "Kyrgyz" },
  { value: "Lao", text: "Lao" },
  { value: "Latin", text: "Latin" },
  { value: "Latvian", text: "Latvian" },
  { value: "Lingala", text: "Lingala" },
  { value: "Lithuanian", text: "Lithuanian" },
  { value: "Macedonian", text: "Macedonian" },
  { value: "Maithili", text: "Maithili" },
  { value: "Malagasy", text: "Malagasy" },
  { value: "Malay (Bahasa Melayu)", text: "Malay (Bahasa Melayu)" },
  { value: "Malayalam", text: "Malayalam" },
  { value: "Mandarin (Chinese)", text: "Mandarin (Chinese)" },
  { value: "Marathi", text: "Marathi" },
  { value: "Mende", text: "Mende" },
  { value: "Mongolian", text: "Mongolian" },
  { value: "Nahuatl", text: "Nahuatl" },
  { value: "Navajo", text: "Navajo" },
  { value: "Nepali", text: "Nepali" },
  { value: "Norwegian", text: "Norwegian" },
  { value: "Ojibwa", text: "Ojibwa" },
  { value: "Oriya", text: "Oriya" },
  { value: "Oromo", text: "Oromo" },
  { value: "Pashto", text: "Pashto" },
  { value: "Persian", text: "Persian" },
  { value: "Polish", text: "Polish" },
  { value: "Portuguese", text: "Portuguese" },
  { value: "Punjabi", text: "Punjabi" },
  { value: "Quechua", text: "Quechua" },
  { value: "Romani", text: "Romani" },
  { value: "Romanian", text: "Romanian" },
  { value: "Russian", text: "Russian" },
  { value: "Rwanda", text: "Rwanda" },
  { value: "Samoan", text: "Samoan" },
  { value: "Sanskrit", text: "Sanskrit" },
  { value: "Serbian", text: "Serbian" },
  { value: "Shona", text: "Shona" },
  { value: "Sindhi", text: "Sindhi" },
  { value: "Sinhala", text: "Sinhala" },
  { value: "Slovak", text: "Slovak" },
  { value: "Slovene", text: "Slovene" },
  { value: "Somali", text: "Somali" },
  { value: "Spanish", text: "Spanish" },
  { value: "Swahili", text: "Swahili" },
  { value: "Swedish", text: "Swedish" },
  { value: "Tachelhit", text: "Tachelhit" },
  { value: "Tagalog", text: "Tagalog" },
  { value: "Tajiki", text: "Tajiki" },
  { value: "Tamil", text: "Tamil" },
  { value: "Tatar", text: "Tatar" },
  { value: "Telugu", text: "Telugu" },
  { value: "Thai", text: "Thai" },
  { value: "Tibetic languages", text: "Tibetic languages" },
  { value: "Tigrigna", text: "Tigrigna" },
  { value: "Tok Pisin", text: "Tok Pisin" },
  { value: "Turkish", text: "Turkish" },
  { value: "Turkmen", text: "Turkmen" },
  { value: "Ukrainian", text: "Ukrainian" },
  { value: "Urdu", text: "Urdu" },
  { value: "Uyghur", text: "Uyghur" },
  { value: "Uzbek", text: "Uzbek" },
  { value: "Vietnamese", text: "Vietnamese" },
  { value: "Warlpiri", text: "Warlpiri" },
  { value: "Welsh", text: "Welsh" },
  { value: "Wolof", text: "Wolof" },
  { value: "Xhosa", text: "Xhosa" },
  { value: "Yakut", text: "Yakut" },
  { value: "Yiddish", text: "Yiddish" },
  { value: "Yoruba", text: "Yoruba" },
  { value: "Yucatec", text: "Yucatec" },
  { value: "Zapotec", text: "Zapotec" },
  { value: "Zulu", text: "Zulu" }
];

export const MARITAL_STATUS = [
  { id: "married", value: "married", label: "Married" },
  { id: "single", value: "single", label: "Single" },
  { id: "divorced", value: "divorced", label: "Divorced" },
  { id: "separated", value: "separated", label: "Separated" },
  { id: "widowed", value: "widowed", label: "Widowed" },
  { id: "life-partner", value: "life partner", label: "Life Partner" }
];

export const PREFERRED_CONTACT = [
  { id: "mail", value: "mail", label: "Mail" },
  { id: "home-phone", value: "home phone", label: "Home Phone" },
  { id: "work-phone", value: "work phone", label: "Work Phone" },
  { id: "cell-phone", value: "cell phone", label: "Cell Phone" }
];

export const ETHNICITY = [
  { id: "cambodian", value: "cambodian", label: "Cambodian" },
  { id: "filipino", value: "filipino", label: "Filipino" },
  { id: "hispanic-latino", value: "hispanic/latino", label: "Hispanic/Latino" },
  { id: "non-hispanic", value: "non-hispanic", label: "Non-Hispanic" }
];

export const RACE = [
  {
    id: "american-indian-or-alaskan-native",
    value: "american indian or alaskan native",
    label: "American Indian or Alaskan Native",
  },
  { id: "asian", value: "asian", label: "Asian" },
  {
    id: "black-or-african-american",
    value: "black or african american",
    label: "Black or African American"
  },
  {
    id: "native-hawaiian-other-pacific-islander",
    value: "native hawaiian/other pacific islander",
    label: "Native Hawaiian/Other Pacific Islander"
  },
  { id: "white", value: "white", label: "White" },
  { id: "other", value: "other", label: "Other" }
];

export const IS_WALK_IN = [
  { id: "isWalkIn", value: "1", label: "Walk-in" }
];

export const IS_PROVIDER = [
  { id: "isProvider", value: "1", label: "Is Provider" }
];

export const PATIENT_SHARE = [
  { id: "patientShare", value: "1", label: "Patient Share" }
];

export const PATIENT_SHARE_DISABLED = [
  { id: "patientShare", value: "1", label: "Patient Share", disabled: "1" }
];

export const DISPLAY_COOKIE_NOTICE = [
  { id: "display_cookie_notice", value: "1", label: "Display Cookie Notice", disabled: "1" }
];

export const DISPLAY_TERMS_AND_CONDITIONS = [
  {
    id: "display_terms_and_conditions",
    value: "1",
    label: "Display Terms & Conditions",
    disabled: "1"
  }
];

export const DISPLAY_PRIVACY_POLICY = [
  { id: "display_privacy_policy", value: "1", label: "Display Privacy Policy" }
];

export const DAYS = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday"
];

export const DAYS_SELECT = [
  { value: "sunday", text: "Sunday" },
  { value: "monday", text: "Monday" },
  { value: "tuesday", text: "Tuesday" },
  { value: "wednesday", text: "Wednesday" },
  { value: "thursday", text: "Thursday" },
  { value: "friday", text: "Friday" },
  { value: "saturday", text: "Saturday" }
];

export const DATE_FORMAT = [
  { value: "DD-MM-YYYY", text: "DMY" },
  { value: "MM-DD-YYYY", text: "MDY" },
  { value: "YYYY-MM-DD", text: "YMD" }
];

export const TIME_FORMAT = [
  { value: "hh:mm A", text: "H:MM AM/PM" },
  { value: "HH:mm", text: "HH:MM" }
];

export const RECEIVE_NOTIFICATIONS = [
  { id: "receive_notifications", value: "1", label: "Receive Notifications" }
];

export const REQUIRE_CAPTCHA = [
  { id: "require_captcha", value: "1", label: "Require Captcha" }
];
