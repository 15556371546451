import React, { Component } from "react";
import {
  CLINIC,
  PHONE,
  EMAIL,
  ADDRESS,
  LOCALTION
} from "../../../data/app.json";

class FooterCopyright extends Component {
  render() {
    return (
      <div className="row d-flex align-items-center wow fadeIn" data-wow-delay="0.3s">
        <div className="col-sm-6 col-lg-7 mb-0 text-center text-sm-left">
          <a href="/" className="text-uppercase text-info">
            <div id="logo" alt={CLINIC} />
          </a>
          {/* <small className="d-block mb-0 text-muted">© 2019</small> */}
          <small className="d-block mb-0 text-uppercase">
            {ADDRESS} <br />
            {LOCALTION}
          </small>
        </div>
        <div className="col-sm-6 col-lg-5 ml-lg-0">
          <div className="text-center text-sm-right">
            <small className="d-block mb-0">
              <i className="fa fa-envelope mr-2" />{" "}
              <a
                href={"mailto:" + EMAIL + "?Subject=Splendor%20Aesthetics"}
                target="_top"
                className="text-muted"
              >
                {EMAIL}
              </a>
            </small>
            <small className="d-block mb-0">
              <i className="fa fa-phone mr-2" />{" "}
              <a
                href={"tel:+1-" + PHONE}
                className="text-muted"
              >
                {PHONE}
              </a>
            </small>
          </div>
        </div>
      </div>
    );
  }
}

export default FooterCopyright;
