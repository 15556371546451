import React, { Component } from "react";
import Menu from "./menu";
import Banner from "./banner";

class Header extends Component {
  render() {
    const { background } = this.props;
    const hasChildren = this.props.children ? true : false;

    return (
      <div
        className="position-relative"
        style={
          background !== undefined
            ? {
                backgroundImage: "url('" + background + "')",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat"
              }
            : null
        }
      >
        <Menu />
        <div style={background !== undefined ? { opacity: 0.95 } : null}>
          <Banner hasChildren={hasChildren}>{this.props.children}</Banner>
        </div>
        {/* SVG separator */}
        {this.props.children ? (
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              x={0}
              y={0}
              viewBox="0 0 2560 100"
              preserveAspectRatio="none"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Header;
