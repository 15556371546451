import axios from "axios";
import history from "../../../routes/history";
import { API_URL, PATIENT, SYSTEM } from "../../../config";
import {
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  SIGNUP_RESEND_FAILURE,
  VERIFY_EMAIL_FAILURE,
  SIGNIN_FAILURE,
  AUTH_USER,
  UNAUTH_USER,
  ACTIVATE_ACCOUNT_SUCCESS,
  ACTIVATE_ACCOUNT_FAILURE,
  FETCH_USER
} from "./types";

export function payload(CONST, data) {
  return {
    type: CONST,
    payload: data
  };
}

export function signUp(props) {
  return function(dispatch) {
    axios
      .post(`${API_URL}/auth/signup`, props, {
        headers: {
          Authorization: "Bearer NoAuth",
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        if (response.data) {
          const message = response.data["Message"];
          const status = response.data["Status"];
          if (status === "Successed") {
            dispatch({ type: SIGNUP_SUCCESS });
            history.push({
              pathname: `/auth/signin`,
              state: { message: message }
            });
          } else {
            dispatch(payload(SIGNUP_FAILURE, message));
          }
        }
      })
      .catch(response => {
        dispatch(payload(SIGNUP_FAILURE, response.message));
      });
  };
}

export function activateAccount(props) {
  return function(dispatch) {
    axios
      .post(`${API_URL}/auth/account-activation`, props, {
        headers: {
          Authorization: "Bearer NoAuth",
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        if (response.data) {
          const message = response.data.Message;
          const status = response.data.Status;
          if (status === "Successed") {
            dispatch({ type: ACTIVATE_ACCOUNT_SUCCESS });
            setTimeout(() => {
              history.push({
                pathname: `/auth/signin`,
                state: { message: "" }
              });
            }, 5000);
          } else {
            dispatch(payload(ACTIVATE_ACCOUNT_FAILURE, message));
          }
        }
      })
      .catch(response => {
        dispatch(payload(ACTIVATE_ACCOUNT_FAILURE, response.message));
      });
  };
}

export function signIn(props) {
  const { email, password } = props;

  return function(dispatch) {
    axios
      .post(
        `${API_URL}/auth/signin`,
        { email, password },
        {
          headers: {
            Authorization: "Bearer NoAuth",
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        if (response.data) {
          const status = response.data.Status;
          const message = response.data.Message;
          if (status === "Successed") {
            const userData = response.data;
            const roleMapping = userData && userData.RoleMapping;
            if (roleMapping && roleMapping.length > 0) {
              const roleId = roleMapping[0].roleId;
              const length = roleMapping.length;

              if (roleId === SYSTEM) {
                sessionStorage.removeItem("user");
                dispatch(payload(SIGNIN_FAILURE, "Permission denied"));
                return;
              } else if (
                roleId < PATIENT &&
                (length > 0 && roleMapping[length - 1].roleId === PATIENT)
              ) {
                history.push({
                  pathname: `/auth/signin-as`,
                  state: { data: response.data }
                });
                return;
              }
              response.data["Permission"] = roleId;
              sessionStorage.setItem("user", JSON.stringify(response.data));
              dispatch(payload(AUTH_USER, response.data));
            } else {
              sessionStorage.removeItem("user");
              dispatch(payload(SIGNIN_FAILURE, message));
            }
          } else {
            sessionStorage.removeItem("user");
            dispatch(payload(SIGNIN_FAILURE, message));
          }
        }
      })
      .catch(() =>
        dispatch(
          payload(SIGNIN_FAILURE, "Email or password isn't right")
        )
      );
  };
}

export function setSignInData(data) {
  return function(dispatch) {
    sessionStorage.setItem("user", JSON.stringify(data));
    dispatch(payload(AUTH_USER, data));
  };
}

export function resendVerification(props) {
  return function(dispatch) {
    axios
      .post(`${API_URL}/resend-verify-code`, props)
      .then(() => {
        dispatch({ type: SIGNUP_SUCCESS });
      })
      .catch(response =>
        dispatch(payload(SIGNUP_RESEND_FAILURE, response.data))
      );
  };
}

export function verifyEmail(props) {
  return function(dispatch) {
    axios
      .post(`${API_URL}/signup/verify-email`, props)
      .then(response => {
        const userData = response.data;
        const roleMapping = userData && userData.RoleMapping;
        if (roleMapping && roleMapping.length > 0) {
          const roleId = roleMapping[0].roleId;
          const length = roleMapping.length;
          if (
            roleId < PATIENT &&
            (length > 0 && roleMapping[length - 1].roleId === PATIENT)
          ) {
            history.push({
              pathname: `/auth/signin-as`,
              state: { data: response.data }
            });
          }
          sessionStorage.setItem("user", JSON.stringify(response.data));
          // sessionStorage.setItem("role", roleId);
          dispatch({ type: AUTH_USER });
          history.push("/auth/users");
        }
      })
      .catch(response =>
        dispatch(payload(VERIFY_EMAIL_FAILURE, response.data.error))
      );
  };
}

export function signOut() {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const accessToken = (user && user.AccessToken) || null;
  const token = (accessToken && accessToken.id) || "";
  return function(dispatch) {
    axios
      .get(`${API_URL}/auth/signout`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        sessionStorage.clear();
        dispatch(payload(UNAUTH_USER, response.data));
      });
  };
}

export function fetchProfile(cb = null) {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const accessToken = (user && user.AccessToken) || null;
  const token = (accessToken && accessToken.id) || "";
  return function(dispatch) {
    axios
      .get(`${API_URL}/auth/profile`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        dispatch(payload(FETCH_USER, response.data));
        if (cb) cb();
      });
  };
}
