import React, { Component } from "react";
import Header from "../../../../components/sites/header";

class HeaderSection extends Component {

  render() {
    const { hideBanner } = this.props;
    const space = hideBanner?"":" ";
    return <Header>{space}</Header>;
  }
}

export default HeaderSection;
