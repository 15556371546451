import React from "react";
import { FormGroup, Label, Input, FormFeedback, FormText } from "reactstrap";
import { isArray, ucFirstAllWords } from "../../../utils";

class DropDownSelect extends React.Component {
  generateTextOption = (option, textKey) => {
    if (isArray(textKey)) {
      let textOption = "";
      textKey.forEach(element => {
        if (option[element] === undefined) return "";
        textOption += option[element] + " ";
      });
      return ucFirstAllWords(textOption);
    } else {
      return option[textKey];
    }
  };

  renderSelectOptions = (option, index) => {
    const { valueKey, textKey } = this.props;
    return valueKey && textKey ? (
      option[valueKey] === undefined ? null : (
        <option key={index} value={option[valueKey]}>
          {this.generateTextOption(option, textKey)}
        </option>
      )
    ) : (
      <option key={option.value} value={option.value}>
        {option.text}
      </option>
    );
  };

  renderLabel(label) {
    return label ? (<Label htmlFor={label}>{label}</Label>) : '';
  }

  render() {
    const {
      input,
      label,
      data,
      description,
      requiredValue,
      meta: { touched, error, warning },
      placeholder
    } = this.props;

    return (
      <FormGroup>
        {this.renderLabel(label)}
        <Input
          invalid={touched && (warning || error) ? true : false}
          type="select"
          {...input}
        >
          {requiredValue ? null : <option value="">{placeholder || "Please select"}</option>}
          {data.map(this.renderSelectOptions)}
        </Input>
        <FormText color="muted">{description}</FormText>
        {touched &&
          ((warning || error) && <FormFeedback>{error}</FormFeedback>)}
      </FormGroup>
    );
  }
}

export default DropDownSelect;
