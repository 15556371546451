import React, { Component } from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";
import { CLINIC, PHONE } from "../../../data/app.json";

const WHITE_LOGO_IMAGE = "/static/media/theme/white.png";
const BLUE_LOGO_IMAGE = "/static/media/theme/blue.png";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.scrolled = false;
    this.state = {
      data: null,
      collapsed: true,
      subMenuCollapsed: [],
      isHide: false,
      isHeadroomShadow: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    let subMenuCollapsed = [];
    const link = "/static/data/components/sites/header/menu/index.json";

    fetch(link)
      .then(r => r.json())
      .then(data => {
        data.menu.map(() => subMenuCollapsed.push(false));
        if (this.mounted) {
          this.setState({
            subMenuCollapsed,
            data
          });
        }
      });
    window.addEventListener("scroll", this.handleHideBar);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleHideBar);
    this.mounted = false;
  }

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  toggleSubMenu = index => {
    let subMenuCollapsed = this.state.subMenuCollapsed;
    subMenuCollapsed[index] = !subMenuCollapsed[index];
    this.setState({ subMenuCollapsed });
  };

  handleHideBar = () => {
    if (!this.scrolled) {
      this.scrolled = true;
      new WOW.WOW({
        live: false
      }).init();
    }
    const { isHide, isHeadroomShadow } = this.state;
    window.scrollY > this.prev && window.scrollY > 85
      ? !isHide && this.setState({ isHide: true })
      : isHide && this.setState({ isHide: false });

    window.scrollY === 0
      ? isHeadroomShadow && this.setState({ isHeadroomShadow: false })
      : !isHeadroomShadow && this.setState({ isHeadroomShadow: true });

    this.prev = window.scrollY;
  };

  renderMenu() {
    if (this.state.data == null) return null;
    const menuElement = this.state.data.menu.map((item, index) => (
      <div key={index}>
        <li
          className="nav-item dropdown menu-main"
          onClick={() => this.toggleSubMenu(index)}
        >
          <Link to={item.href} className="nav-link" data-toggle="dropdown">
            <i className={"fa " + item.icon + " d-lg-none"} />
            <span className="nav-link-inner--text">
              {item.text}
            </span>
          </Link>
          {item.subMenu !== undefined ? (
            <div
              className={
                (item.dropdownMenuStyle
                  ? item.dropdownMenuStyle
                  : "dropdown-menu dropdown-menu-xl") +
                (this.state.subMenuCollapsed[index] ? " show" : "")
              }
            >
              <div className="pl-3 pr-3">
                {item.subMenu.map((subItem, subIndex) => {
                  return (
                    <div key={subIndex} className="row pt-1 pr-1 pb-0">
                      {subItem.rowMenu ? (
                        subItem.rowMenu.map((rowItem, rowIndex) => (
                          <div
                            key={rowIndex}
                            className={
                              "col-md-6 media d-flex mb-3 " +
                              (rowIndex % 2 === 0 ? "border-right" : "")
                            }
                          >
                            <img
                              src={rowItem.img}
                              alt={rowItem.imgAlt}
                              className={
                                rowItem.imageClassName +
                                " " +
                                (rowItem.imgBorder
                                  ? "img-fluid rounded-circle border"
                                  : "img-fluid rounded-circle")
                              }
                            />

                            <div className="media-body ml-3 mb-0 mt-0">
                              <span className="text-uppercase">
                                {rowItem.text}
                              </span>

                              {rowItem.description !== undefined ? (
                                <p className="description d-none d-md-inline-block mb-0">
                                  {rowItem.description}
                                </p>
                              ) : null}
                              {rowItem.links !== undefined
                                ? rowItem.links.map((link, linkIndex) => (
                                    <div key={linkIndex}>
                                      <Link
                                        to={link.href}
                                        // className="text-primary"
                                        data-toggle="dropdown"
                                      >
                                        <small className="font-weight-light">
                                          {link.text}
                                        </small>
                                      </Link>
                                    </div>
                                  ))
                                : null}
                            </div>
                          </div>
                        ))
                      ) : (
                        <Link
                          to={subItem.href}
                          className="media d-flex align-items-center ml-3 mb-1 mt-1"
                          key={subIndex}
                        >
                          <img
                            src={subItem.img}
                            alt={subItem.imgAlt}
                            className={
                              subItem.imageClassName +
                              " " +
                              (subItem.imgBorder
                                ? "img-fluid rounded-circle border"
                                : "img-fluid rounded-circle")
                            }
                          />

                          <div className="media-body ml-3">
                            <h6 className="heading text-default mb-md-1">
                              {subItem.text}
                            </h6>
                            {subItem.description !== undefined ? (
                              <p className="description d-none d-md-inline-block mb-0">
                                {subItem.description}
                              </p>
                            ) : null}
                          </div>
                        </Link>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </li>
      </div>
    ));
    return menuElement;
  }

  render() {
    const collapsed = this.state.collapsed;
    const classNavbarCollapse = collapsed
      ? "collapse navbar-collapse"
      : "collapse navbar-collapse show";
    const classNavbarToggler = collapsed
      ? "navbar-toggler navbar-toggler-right collapsed"
      : "navbar-toggler navbar-toggler-right";
    const classHideNavbar = this.state.isHide ? "hide" : "";
    const classHeadroomShadow = this.state.isHeadroomShadow
      ? " headroom-shadow"
      : " headroom-no-shadow";
    const menuElement = this.renderMenu();

    return (
      <header className="header-global">
        <nav
          id="navbar-main"
          className={
            "navbar navbar-main navbar-expand-lg navbar-transparent navbar-light headroom " +
            classHideNavbar +
            classHeadroomShadow
          }
          style={{ opacity: 0.97 }}
        >
          <div className="container">
            <div className="logo-wrapper">
              <Link to="/index" className="navbar-brand mr-lg-5">
                <img src={WHITE_LOGO_IMAGE} alt={CLINIC} />
              </Link>
            </div>
            <button
              className={`${classNavbarToggler}`}
              type="button"
              data-toggle="collapse"
              data-target="#navbar_global"
              aria-controls="navbar_global"
              aria-expanded="false"
              aria-label="Toggle Navigation"
              onClick={this.toggleNavbar}
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className={`${classNavbarCollapse}`} id="navbar_global">
              <div className="navbar-collapse-header">
                <div className="row">
                  <div className="col-6 collapse-brand">
                    <Link to="/index">
                      <img src={BLUE_LOGO_IMAGE} alt={CLINIC} />
                    </Link>
                  </div>
                  <div className="col-6 collapse-close">
                    <button
                      type="button"
                      className="navbar-toggler"
                      data-toggle="collapse"
                      data-target="#navbar_global"
                      aria-controls="navbar_global"
                      aria-expanded="false"
                      aria-label="Toggle Navigation"
                      onClick={this.toggleNavbar}
                    >
                      <span />
                      <span />
                    </button>
                  </div>
                </div>
              </div>
              <ul className="navbar-nav navbar-nav-hover align-items-lg-center menu-mini-md-show">
                {menuElement}
              </ul>
            </div>
            <div className="align-items-lg-center ml-lg-auto d-lg-block wow pulse contact">
              <h3 className="mb-0 text-primary phone-wrapper">
                <a href={"tel:+1-" + PHONE} className="text-info">
                  {PHONE}
                </a>
              </h3>
              <small className="mb-0 text-white">
                CALL FOR FREE CONSULTATION
              </small>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Menu;
