import {
    FETCH_THEME,
    TOGGLE_THEME,
  } from "../../actions/management/toggle-theme/types";
  
  export default function(state = {}, action) {
    switch (action.type) {
      case TOGGLE_THEME:
        return { ...state, data: action.payload };
      case FETCH_THEME:
        return { ...state, data: action.payload };
      default:
        return state;
    }
  }
  