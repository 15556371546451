import {
  FETCH_FORM,
  LIST_FORM,
  SAVE_FORM_SUCCESS,
  SAVE_FORM_FAILURE,
  UPDATE_FORM_SUCCESS,
  UPDATE_FORM_FAILURE,
  DEACTIVATE_FORM_SUCCESS,
  DEACTIVATE_FORM_FAILURE,
  DEACTIVATE_ALL_FORM_SUCCESS,
  DEACTIVATE_ALL_FORM_FAILURE
} from "../../actions/management/patient-form/types";

export default function(state = {}, action) {
  switch (action.type) {
    case SAVE_FORM_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case SAVE_FORM_FAILURE:
      return { ...state, error: { message: action.payload } };
    case UPDATE_FORM_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case UPDATE_FORM_FAILURE:
      return { ...state, error: { message: action.payload } };
    case DEACTIVATE_FORM_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case DEACTIVATE_FORM_FAILURE:
      return { ...state, error: { message: action.payload } };
    case DEACTIVATE_ALL_FORM_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case DEACTIVATE_ALL_FORM_FAILURE:
      return { ...state, error: { message: action.payload } };
    case LIST_FORM:
      return { ...state, data: action.payload };
    case FETCH_FORM:
      return { ...state, item: action.payload };
    default:
      return state;
  }
}
