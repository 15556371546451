import {
    FETCH_SYSTEM_SETTING_GENERAL,
    SAVE_SYSTEM_SETTING_GENERAL_SUCCESS,
    SAVE_SYSTEM_SETTING_GENERAL_FAILURE
  } from "../../actions/management/general/types";
  
  export default function(state = {}, action) {
    switch (action.type) {
      case SAVE_SYSTEM_SETTING_GENERAL_SUCCESS:
        return { ...state, success: { message: action.payload } };
      case SAVE_SYSTEM_SETTING_GENERAL_FAILURE:
        return { ...state, error: { message: action.payload } };
      case FETCH_SYSTEM_SETTING_GENERAL:
        return { ...state, data: action.payload };
      default:
        return state;
    }
  }
  