import React, { Component } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import WOW from "wowjs";
// import { connect } from "react-redux";
import { API_URL } from "../../../config";
import { toast } from "react-toastify";
import * as actions from "../../../actions/management/toggle-theme";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      message: "",
      phone: "",
      name: "",
      recaptcha: ""
    };
    this.baseState = this.state;
    this.scrolled = false;
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.fetch(() => {
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = e => {
    if (!this.scrolled) {
      this.scrolled = true;
      new WOW.WOW({
        live: false
      }).init();
    }
  };

  handleEmailChange = e => {
    this.setState({
      email: e.target.value
    });
  };

  handleMessageChange = e => {
    this.setState({
      message: e.target.value
    });
  };

  handleNameChange = e => {
    this.setState({
      name: e.target.value
    });
  };

  handlePhoneChange = e => {
    this.setState({
      phone: e.target.value
    });
  };

  handleResetForm = e => {
    this.setState(this.baseState);
  };

  handleReCaptchaChange = value => {
    this.setState({
      recaptcha: value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    let data = {
      email: this.state.email,
      message: this.state.message,
      name: this.state.name,
      phone: this.state.phone,
      recaptcha: this.state.recaptcha
    };
    axios.post(`${API_URL}/contacts/send`, data).then(response => {
      if (response.status === 200) {
        if (response.data && response.data.success) {
          this.setState(
            {
              email: "",
              message: "",
              phone: "",
              name: "",
              recaptcha: ""
            },
            () => {
              this.captcha.reset();
              toast("Thank you for your message!", {
                type: toast.TYPE.SUCCESS
              });
            }
          );
        }
      }
    });
  };

  renderForm() {
    const { btnClassName } = this.props;

    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group mt-5">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fa fa-user" />
                </span>
              </div>
              <input
                className="form-control"
                placeholder="Enter your name"
                type="text"
                required
                value={this.state.name}
                onChange={this.handleNameChange}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fa fa-envelope" />
                </span>
              </div>
              <input
                className="form-control"
                placeholder="Enter your e-mail"
                type="email"
                required
                value={this.state.email}
                onChange={this.handleEmailChange}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fa fa-phone" />
                </span>
              </div>
              <input
                className="form-control"
                placeholder="Enter your phone number"
                type="text"
                required
                value={this.state.phone}
                onChange={this.handlePhoneChange}
              />
            </div>
          </div>
          <div className="form-group mb-4">
            <textarea
              className="form-control"
              required
              rows={4}
              cols={80}
              placeholder="Type a message..."
              value={this.state.message}
              onChange={this.handleMessageChange}
            />
          </div>
          <div className="form-group">
            <div className="recaptcha-customize" id="g-recaptcha">
              <ReCAPTCHA
                key={sessionStorage.getItem("theme") === 'true'}
                size="normal"
                // ref="recaptcha"
                ref={e => (this.captcha = e)}
                sitekey="6LdVZ38UAAAAANiHYrMFqZutKD0fC_payFQeEcaz"
                onChange={this.handleReCaptchaChange}
                data-size="invisible"
                theme={sessionStorage.getItem("theme") === 'true' ? "dark" : 'light'} 
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className={
                "btn btn-round btn-block btn-lg " +
                (btnClassName ? btnClassName : "btn-default")
              }
            >
              Send Message
            </button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    const { sideContainer } = this.props;

    return sideContainer ? (
      <div>
        <div
          className="card bg-gradient-secondary wow fadeIn"
          data-wow-delay="0.4s"
        >
          <div className="ml-2 mr-2 mt-3 mb-5">
            <h4 className="mb-1">CONTACT US</h4>
            <p className="mt-0">Make an appointment</p>
            {this.renderForm()}
          </div>
        </div>
      </div>
    ) : (
      <section
        className="section section-lg pt-lg-0 section-contact-us wow fadeIn"
        style={{ zIndex: 90 }}
      >
        <div className="container">
          <div className="row justify-content-center mt--150">
            <div className="col-lg-8">
              <div className="card bg-gradient-secondary">
                <div className="card-body p-lg-5">
                  <h4 className="mb-1">CONTACT US</h4>
                  <p className="mt-0">Make an appointment</p>
                  {this.renderForm()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    loaded: !!state.toggleTheme.data,
    data: state.toggleTheme.data,
  };
}

Contact = reduxForm({ form: "toggleTheme" })(
  Contact
);

export default connect(
  mapStateToProps,
  actions
)(Contact);