export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE';
export const DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS";
export const DELETE_MESSAGE_FAILURE = "DELETE_MESSAGE_FAILURE";
export const DEACTIVATE_MESSAGE_SUCCESS = "DEACTIVATE_MESSAGE_SUCCESS";
export const DEACTIVATE_MESSAGE_FAILURE = "DEACTIVATE_MESSAGE_FAILURE";
export const DELETE_ALL_MESSAGE_SUCCESS = "DELETE_ALL_MESSAGE_SUCCESS";
export const DELETE_ALL_MESSAGE_FAILURE = "DELETE_ALL_MESSAGE_FAILURE";
export const DEACTIVATE_ALL_MESSAGE_SUCCESS = "DEACTIVATE_ALL_MESSAGE_SUCCESS";
export const DEACTIVATE_ALL_MESSAGE_FAILURE = "DEACTIVATE_ALL_MESSAGE_FAILURE";
export const FETCH_MESSAGE = 'FETCH_MESSAGE';
export const LIST_MESSAGE = "LIST_MESSAGE";
export const COUNT_NEW_MESSAGE = "COUNT_NEW_MESSAGE";
