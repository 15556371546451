import {
  FETCH_CLINIC,
  SAVE_CLINIC_SUCCESS,
  SAVE_CLINIC_FAILURE
} from "../../actions/management/clinic/types";

export default function(state = {}, action) {
  switch (action.type) {
    case SAVE_CLINIC_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case SAVE_CLINIC_FAILURE:
      return { ...state, error: { message: action.payload } };
    case FETCH_CLINIC:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}
