import {
  FETCH_DOCUMENT,
  LIST_DOCUMENT,
  SAVE_DOCUMENT_SUCCESS,
  SAVE_DOCUMENT_FAILURE,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_FAILURE,
  DEACTIVATE_DOCUMENT_SUCCESS,
  DEACTIVATE_DOCUMENT_FAILURE,
  DEACTIVATE_ALL_DOCUMENT_SUCCESS,
  DEACTIVATE_ALL_DOCUMENT_FAILURE
} from "../../actions/management/document/types";

export default function(state = {}, action) {
  switch (action.type) {
    case SAVE_DOCUMENT_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case SAVE_DOCUMENT_FAILURE:
      return { ...state, error: { message: action.payload } };
    case UPDATE_DOCUMENT_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case UPDATE_DOCUMENT_FAILURE:
      return { ...state, error: { message: action.payload } };
    case DEACTIVATE_DOCUMENT_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case DEACTIVATE_DOCUMENT_FAILURE:
      return { ...state, error: { message: action.payload } };
    case DEACTIVATE_ALL_DOCUMENT_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case DEACTIVATE_ALL_DOCUMENT_FAILURE:
      return { ...state, error: { message: action.payload } };
    case LIST_DOCUMENT:
      return { ...state, data: action.payload };
    case FETCH_DOCUMENT:
      return { ...state, item: action.payload };
    default:
      return state;
  }
}
