import {
  FETCH_SYSTEM_BUSSINESS_LOGIC,
  SAVE_SYSTEM_BUSSINESS_LOGIC_SUCCESS,
  SAVE_SYSTEM_BUSSINESS_LOGIC_FAILURE,
  DELETE_BREAK_PLAN,
  ADD_BREAK_PLAN,
  UPDATE_BREAK_PLAN,
  DEACTIVATE_WORKING_PLAN,
  ACTIVATE_WORKING_PLAN,
  UPDATE_WORKING_PLAN
} from "../../actions/management/system-bussiness-logic/types";

export default function(state = {}, action) {
  switch (action.type) {
    case SAVE_SYSTEM_BUSSINESS_LOGIC_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case SAVE_SYSTEM_BUSSINESS_LOGIC_FAILURE:
      return { ...state, error: { message: action.payload } };
    case FETCH_SYSTEM_BUSSINESS_LOGIC:
      return { ...state, data: action.payload };
    case DELETE_BREAK_PLAN:
      return { ...state, break: { delete: action.payload } };
    case ADD_BREAK_PLAN:
      return { ...state, break: { add: action.payload } };
    case UPDATE_BREAK_PLAN:
      return { ...state, break: { update: action.payload } };
    case DEACTIVATE_WORKING_PLAN:
      return { ...state, working: { deactivate: action.payload } };
    case ACTIVATE_WORKING_PLAN:
      return { ...state, working: { activate: action.payload } };
    case UPDATE_WORKING_PLAN:
      return { ...state, working: { update: action.payload } };
    default:
      return state;
  }
}
