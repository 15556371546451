import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import WOW from "wowjs";

class SubMenuSection extends Component {
  constructor() {
    super();
    this.scrolled = false;
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {
    if (!this.scrolled) {
      this.scrolled = true;
      new WOW.WOW({
        live: false
      }).init();
    }
  };

  render() {
    const { title, items, className, vertical } = this.props;
    return items.length === 0 ? null : (
      <div className={className + " wow fadeIn"}>
        <p className="lead">{title}</p>
        <Nav vertical={vertical ? vertical : false}>
          {items.map((item, index) => (
            <NavItem key={index}>
              <Link
                to={item.url}
                className={"nav-link " + (item.active ? "bg-secondary" : "")}
                data-toggle="dropdown"
              >
                {item.text}
              </Link>
            </NavItem>
          ))}
        </Nav>
        <hr />
      </div>
    );
  }
}

export default SubMenuSection;
