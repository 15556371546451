import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  // NavItem,
  // NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import history from "../../../routes/history";
import * as actions from "../../../actions/management/auth";
import { PATIENT } from "../../../config";
import { connect } from "react-redux";
import { ROLES } from "../../../config";
import { capitalizeFirstLetter, formatDate } from "../../../utils";

const USER_IMAGE = "/static/media/management/user.png";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  renderLogo = () => {
    return (
      <Link to="/management" className="logo">
        <span className="logo-mini">
          <b>C</b>OS
        </span>
        <span className="logo-lg">
          <b>Clinix</b>OS
        </span>
      </Link>
    );
  };

  renderNavbarBrand = () => {
    const { toggle } = this.props;
    return (
      <NavbarBrand>
        <span onClick={toggle} className="sidebar-toggle text-white">
          <span className="sr-only">Toggle Navigation</span>
          <span className="icon-bar" />
          <span className="icon-bar" />
          <span className="icon-bar" />
        </span>
      </NavbarBrand>
    );
  };

  getRoleName = array => {
    if (array) {
      // array.sort((a, b) => a.roleId - b.roleId);
      return ROLES[array[0].roleId];
    } else {
      return "";
    }
  };

  navigateMyProfile = () => {
    history.push(`/management/profile`);
  };

  handleSignOut = () => {
    this.props.signOut();
  };

  navigateMessageContent = id => {
    history.push({
      pathname: `/management/message/content`,
      state: { id }
    });
  };

  navigateMessageInbox = type => {
    history.push({
      pathname: `/management/message/inbox`,
      state: { type }
    });
  };

  navigateMessageCompose = () => {
    history.push({
      pathname: `/management/message/compose`,
    });
  };

  renderMessages = messages => {
    return messages
      ? messages.map((message, index) => {
        const id = (message && message.id) || 0;
        const senderName = (message && message.senderName) || "";
        const from = (message && message.from) || "";
        const shortContent = (message && message.shortContent) || "";
        const subject = (message && message.subject) || "";
        const type = (message && message.type) || "";
        const created = formatDate((message && message.created) || "", 8);
        return (
          <div key={index} onClick={() => this.navigateMessageContent(id)}>
            <DropdownItem>
              <div className="message">
                <div className="py-0 mr-3 float-left">
                  <div className="avatar">
                    <img
                      className="img-avatar"
                      src="/static/media/management/user.png"
                      alt={from}
                    />
                  </div>
                </div>
                <div>
                  <small className="text-muted">{senderName}</small>
                  <small className="text-muted float-right mt-1">
                    {created}
                  </small>
                </div>
                <div className="text-truncate font-weight-bold">
                  <span
                    className={
                      type === "notification"
                        ? "text-danger fa fa-bell-o"
                        : "text-success fa fa-envelope-o"
                    }
                  />{" "}
                  {subject}
                </div>
                <div className="small text-muted text-truncate">
                  {shortContent}
                  {"..."}
                </div>
              </div>
            </DropdownItem>
            <DropdownItem divider />
          </div>
        );
      })
      : null;
  };

  isEmployeeRole = () => {
    const { data } = this.props;
    const roleMapping = (data && data.UserProfile.RoleMapping) || null;
    const roleId =
      (roleMapping && roleMapping.length > 0 && roleMapping[0].roleId) ||
      PATIENT;
    // const permission = (auth && auth.UserProfile.Permission) || PATIENT;
    const isEmployeeRole = roleId < PATIENT;
    return isEmployeeRole;
  };

  renderNavItems = () => {
    const { authenticated, data } = this.props;
    const user = (data && data.UserProfile.User) || null;
    const userData = (data && data.UserProfile.UserData) || null;
    const firstName = (user && userData.firstName) || "";
    const lastName = (user && userData.lastName) || "";
    const fullName =
      capitalizeFirstLetter(firstName) + " " + capitalizeFirstLetter(lastName);
    const roleMapping = (data && data.UserProfile.RoleMapping) || null;
    const role = this.getRoleName(roleMapping);
    const countMessage =
      this.props.countNewMessage && this.props.countNewMessage.Count;
    const newMessage =
      (countMessage &&
        countMessage.newMessage &&
        countMessage.newMessage.count) ||
      0;
    const newNotification =
      (countMessage &&
        countMessage.newNotification &&
        countMessage.newNotification.count) ||
      0;

    const messageData =
      this.props.countNewMessage && this.props.countNewMessage.Data;
    // const notificationElement = this.renderMessages(
    //   messageData && messageData.newNotification
    // );
    const messageElement = this.renderMessages(
      messageData && messageData.newMessage
    );

    return authenticated ? (
      <Nav className="ml-auto" navbar>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret className="text-white">
            <i className="fa fa-envelope-o" />
            <span className="label label-success">{newMessage}</span>
          </DropdownToggle>
          <DropdownMenu right className="dropdown-menu-lg">
            <DropdownItem header>
              {newMessage === 0
                ? "You have no new messages"
                : newMessage > 1
                  ? `You have ${newMessage} new messages`
                  : "You have 1 new message"}
            </DropdownItem>
            {
              (this.isEmployeeRole()) ?
                <div>
                  <DropdownItem divider />
                  {messageElement}
                  <DropdownItem
                    onClick={() => this.navigateMessageCompose()}
                    className="font-weight-light"
                  >
                    Compose new message
                  </DropdownItem>
                </div>
                : null
            }

            {/* <DropdownItem
              onClick={() => this.navigateMessageInbox("email")}
              className="font-weight-light"
              disabled
            >
              View all messages
            </DropdownItem> */}
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret className="text-white">
            <i className="fa fa-bell-o" />
            <span className="label label-warning">{newNotification}</span>
          </DropdownToggle>
          <DropdownMenu right className="dropdown-menu-lg">
            <DropdownItem header>
              {newNotification === 0
                ? "You have no notifications"
                : newNotification > 1
                  ? `You have ${newNotification} new notifications`
                  : "You have 1 new notification"}
            </DropdownItem>
            {/* <DropdownItem divider /> */}
            {/* {notificationElement} */}
            {/* <DropdownItem
              onClick={() => this.navigateMessageInbox("notification")}
              className="font-weight-light"
              disabled
            >
              View all notifications
            </DropdownItem> */}
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            <img src={USER_IMAGE} className="user-image" alt="User" />
            <span className="hidden-xs pl-2 text-white text-uppercase text-center">
              {fullName}
            </span>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem className="pl-5 pr-5">
              <div className="text-center text-uppercase">
                <div>
                  <img
                    id="userimg"
                    src={USER_IMAGE}
                    alt="User"
                    className="border"
                  />
                </div>
                <span className="">
                  {fullName}
                </span>
                <br />
                <small>{role}</small>
              </div>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem className="d-flex justify-content-center">
              <div
                className="btn btn-1 btn-outline-default p-2"
                onClick={this.navigateMyProfile}
              >
                <small>Profile</small>
              </div>
              <div
                className="btn btn-1 btn-outline-default p-2"
                onClick={this.handleSignOut}
              >
                <small>Sign out</small>
              </div>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    ) : null;
  };

  renderNavbar = () => {
    return (
      <Navbar color="#30588f" light expand="md">
        {this.renderNavbarBrand()}
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          {this.renderNavItems()}
        </Collapse>
      </Navbar>
    );
  };

  render = () => {
    return (
      <header className="main-header">
        {this.renderLogo()}
        {this.renderNavbar()}
      </header>
    );
  };
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    data: state.auth.data,
    countNewMessage: state.message.countNewMessage
  };
}

export default connect(
  mapStateToProps,
  actions
)(Header);
