import {
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAILURE,
  DEACTIVATE_MESSAGE_SUCCESS,
  DEACTIVATE_MESSAGE_FAILURE,
  DELETE_ALL_MESSAGE_SUCCESS,
  DELETE_ALL_MESSAGE_FAILURE,
  DEACTIVATE_ALL_MESSAGE_SUCCESS,
  DEACTIVATE_ALL_MESSAGE_FAILURE,
  FETCH_MESSAGE,
  LIST_MESSAGE,
  COUNT_NEW_MESSAGE
} from "../../actions/management/message/types";

export default function(state = {}, action) {
  switch (action.type) {
    case SEND_MESSAGE_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case SEND_MESSAGE_FAILURE:
      return { ...state, error: { message: action.payload } };
    case DELETE_MESSAGE_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case DELETE_MESSAGE_FAILURE:
      return { ...state, error: { message: action.payload } };
    case DEACTIVATE_MESSAGE_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case DEACTIVATE_MESSAGE_FAILURE:
      return { ...state, error: { message: action.payload } };
    case DELETE_ALL_MESSAGE_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case DELETE_ALL_MESSAGE_FAILURE:
      return { ...state, error: { message: action.payload } };
    case DEACTIVATE_ALL_MESSAGE_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case DEACTIVATE_ALL_MESSAGE_FAILURE:
      return { ...state, error: { message: action.payload } };
    case FETCH_MESSAGE:
      return { ...state, content: action.payload };
    case LIST_MESSAGE:
      return { ...state, data: action.payload };
    case COUNT_NEW_MESSAGE:
      return { ...state, countNewMessage: action.payload };
    default:
      return state;
  }
}
