import {
    FETCH_WORKING_PLAN,
    SAVE_WORKING_PLAN_SUCCESS,
    SAVE_WORKING_PLAN_FAILURE,
    DELETE_BREAK_PLAN,
    ADD_BREAK_PLAN,
    UPDATE_BREAK_PLAN,
    DEACTIVATE_WORKING_PLAN,
    ACTIVATE_WORKING_PLAN,
    UPDATE_WORKING_PLAN
  } from "../../actions/management/working-plan/types";
  
  export default function(state = {}, action) {
    switch (action.type) {
      case SAVE_WORKING_PLAN_SUCCESS:
        return { ...state, success: { message: action.payload } };
      case SAVE_WORKING_PLAN_FAILURE:
        return { ...state, error: { message: action.payload } };
      case FETCH_WORKING_PLAN:
        return { ...state, data: action.payload };
      case DELETE_BREAK_PLAN:
        return { ...state, break: { delete: action.payload } };
      case ADD_BREAK_PLAN:
        return { ...state, break: { add: action.payload } };
      case UPDATE_BREAK_PLAN:
        return { ...state, break: { update: action.payload } };
      case DEACTIVATE_WORKING_PLAN:
        return { ...state, working: { deactivate: action.payload } };
      case ACTIVATE_WORKING_PLAN:
        return { ...state, working: { activate: action.payload } };
      case UPDATE_WORKING_PLAN:
        return { ...state, working: { update: action.payload } };
      default:
        return state;
    }
  }
  