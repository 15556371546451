import {
  FETCH_EMPLOYEE,
  LIST_EMPLOYEE,
  SAVE_EMPLOYEE_SUCCESS,
  SAVE_EMPLOYEE_FAILURE,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_FAILURE,
  DEACTIVATE_EMPLOYEE_SUCCESS,
  DEACTIVATE_EMPLOYEE_FAILURE,
  ACTIVATE_EMPLOYEE_SUCCESS,
  ACTIVATE_EMPLOYEE_FAILURE,
  DEACTIVATE_ALL_EMPLOYEE_SUCCESS,
  DEACTIVATE_ALL_EMPLOYEE_FAILURE,
  ACTIVATE_ALL_EMPLOYEE_SUCCESS,
  ACTIVATE_ALL_EMPLOYEE_FAILURE,
  LIST_SERVICE_PROVIDER,
  LIST_SERVICE_MAPPING,
  LIST_PROVIDER,
} from "../../actions/management/employee/types";

export default function(state = {}, action) {
  switch (action.type) {
    case SAVE_EMPLOYEE_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case SAVE_EMPLOYEE_FAILURE:
      return { ...state, error: { message: action.payload } };
    case UPDATE_EMPLOYEE_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case UPDATE_EMPLOYEE_FAILURE:
      return { ...state, error: { message: action.payload } };
    case DEACTIVATE_EMPLOYEE_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case DEACTIVATE_EMPLOYEE_FAILURE:
      return { ...state, error: { message: action.payload } };
    case ACTIVATE_EMPLOYEE_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case ACTIVATE_EMPLOYEE_FAILURE:
      return { ...state, error: { message: action.payload } };
    case DEACTIVATE_ALL_EMPLOYEE_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case DEACTIVATE_ALL_EMPLOYEE_FAILURE:
      return { ...state, error: { message: action.payload } };
    case ACTIVATE_ALL_EMPLOYEE_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case ACTIVATE_ALL_EMPLOYEE_FAILURE:
      return { ...state, error: { message: action.payload } };
    case LIST_EMPLOYEE:
      return { ...state, data: action.payload };
    case FETCH_EMPLOYEE:
      return { ...state, data: action.payload };
    case LIST_SERVICE_PROVIDER:
      return { ...state, serviceProvider: action.payload };
    case LIST_SERVICE_MAPPING:
      return { ...state, serviceMapping: action.payload };
    case LIST_PROVIDER:
      return { ...state, provider: action.payload };
    default:
      return state;
  }
}
