import React, { Component } from "react";
import { Link } from "react-router-dom";
import FooterMap from "./footer-map";
import FooterTestimonials from "./footer-testimonials";
import ToggleSwitch from "../../../components/form/toggle-switch";
import WOW from "wowjs";
import * as actions from "../../../actions/management/toggle-theme";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";

const LINKS = [
  // {
  //   text: "Services",
  //   subLinks: [
  //     {
  //       href: "/face",
  //       text: "Face"
  //     },
  //     {
  //       href: "/neck",
  //       text: "Neck"
  //     },
  //     {
  //       href: "/body",
  //       text: "Body"
  //     },
  //     {
  //       href: "/skin-care",
  //       text: "Skin Care"
  //     },
  //     {
  //       href: "/men-health-wellness",
  //       text: "Men"
  //     },
  //     {
  //       href: "/woman-health-wellness",
  //       text: "Woman"
  //     }
  //   ]
  // },
  // {
  //   text: "Technology",
  //   subLinks: [
  //     {
  //       href: "/hydrafacial-md",
  //       text: "Hydrafacial"
  //     },
  //     {
  //       href: "/precisiontx",
  //       text: "Precision TX 2"
  //     },
  //     {
  //       href: "/body-mini-fx",
  //       text: "Body + Mini FX"
  //     },
  //     {
  //       href: "/coolsculpting",
  //       text: "CoolSculpting 2"
  //     }
  //   ]
  // },
  {
    text: "Menu",
    subLinks: [
      {
        href: "/price",
        text: "Price"
      },
      {
        href: "/contact",
        text: "Contact"
      },
      {
        href: "/dr-trieu",
        text: "Dr. Trieu"
      },
      {
        href: "",
        text: "Account",
        type: "divider"
      },
      {
        href: "/auth/signin",
        text: "Login"
      },
      {
        href: "/auth/signup",
        text: "Register"
      }
    ]
  }
];

class FooterLink extends Component {
  constructor() {
    super();
    this.scrolled = false;
    this.toggleTheme = this.toggleTheme.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.fetch(() => {
      this.toggleTheme(sessionStorage.getItem("theme") === 'true');
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = e => {
    if (!this.scrolled) {
      this.scrolled = true;
      new WOW.WOW({
        live: false
      }).init();
    }
  };

  renderLinks() {
    const linksElement = LINKS.map((item, index) => (
      <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-1" key={index}>
        <h6 className="text-uppercase pl-1">
          {item.href ? <Link to={item.href}>{item.text}</Link> : item.text}
        </h6>
        <hr
          className="teal accent-3 m-1 ml-0"
          style={{ width: "60px" }}
        />
        {item.subLinks.map((subLink, subIndex) => (
          <div className="p-0 m-0 pl-1" key={subIndex}>
            {subLink.type === "divider" ? (
              <div>
                <h6 className="text-uppercase mt-4 mb-0">
                  {subLink.href ? (
                    <Link to={subLink.href}>{subLink.text}</Link>
                  ) : (
                    subLink.text
                  )}
                </h6>
                <hr
                  className="teal accent-3 m-1 ml-0"
                  style={{ width: "60px" }}
                />
              </div>
            ) : (
              <Link className="text-default text-small" to={subLink.href}>
                {subLink.text}
              </Link>
            )}
          </div>
        ))}
        <div className="p-0 m-0">
          <h6 className="text-uppercase mt-3 mb-0 pl-1">
            Theme
          </h6>
          <hr
            className="teal accent-3 m-1 ml-0"
            style={{ width: "60px" }}
          />
          <ToggleSwitch
            className="pl-3"
            id='theme'
            optionLabels={['Dark theme', 'Light theme']}
            onChange={this.toggleTheme}
            checked={sessionStorage.getItem("theme") === 'true'}
          />
        </div>
      </div>
    ));
    return linksElement;
  }

  toggleTheme(value) {
    const theme = value;//true ? "dark" : "light";
    this.props.toggle(theme);
    document.documentElement.classList.add("color-theme-in-transition");
    document.documentElement.setAttribute("data-theme", theme ? 'dark' : 'light');
    window.setTimeout(() => {
      document.documentElement.classList.remove("color-theme-in-transition");
    }, 1000);
  }

  render() {
    const linksElement = this.renderLinks();

    return (
      <div>
        <div className="container text-center text-md-left mt-5 mb-5">
          <div
            className="row mt-3 dark-grey-text wow fadeIn"
            data-wow-delay="0.2s"
          >
            <FooterTestimonials />
            {linksElement}
            <FooterMap />
          </div>
        </div>
        <hr className="mb-3" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loaded: !!state.toggleTheme.data,
    data: state.toggleTheme.data,
    errorMessage: state.toggleTheme.error,
    successMessage: state.toggleTheme.success
  };
}

FooterLink = reduxForm({ form: "toggleTheme" })(
  FooterLink
);

export default connect(
  mapStateToProps,
  actions
)(FooterLink);