import React, { Component } from "react";

class Breadcrumb extends Component {
  render() {
    const { breadcrumb, name, description, icon } = this.props;
    return (
      <section className="content-container">
        <h1 className="font-weight-light">
          <i className={"lead mt-0 fa " + icon} />
          {" " + name}
          <small>{description}</small>
        </h1>
        <ol className="breadcrumb">
          <li>
            <i className="fa fa-home" /> Home
          </li>
          {breadcrumb.map((item, index) => (
            <li key={index}>{item.text}</li>
          ))}
        </ol>
      </section>
    );
  }
}

export default Breadcrumb;
