import React, { Component } from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

const API_KEY = "AIzaSyCfhU_djowVgHAg8pJUR2cZ4RjkYWxaxmA";
const ZOOM = 10;
const LAT = 38.829267;
const LNG = -77.1851964;
const googleMapDarkStyles = require('../../../data/sites/google-map-dark-styles.json');
const MapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%`, minHeight: `230px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap 
    defaultZoom={ZOOM} 
    defaultCenter={{ lat: LAT, lng: LNG }}
    defaultOptions={{
      disableDefaultUI: true, // disable default map UI
      draggable: true, // make map draggable
      keyboardShortcuts: false, // disable keyboard shortcuts
      scaleControl: true, // allow scale controle
      scrollwheel: true, // allow scroll wheel
      styles: googleMapDarkStyles // change default map styles
    }}
  >
    <Marker position={{ lat: LAT, lng: LNG }} />
  </GoogleMap>
));

class FooterMap extends Component {
  render() {
    return (
      <div className="col-md-12 col-lg-12 col-xl-6 mb-4">
        {/* <h6 className="text-uppercase">Map</h6>
        <hr
          className="teal accent-3 mb-3 mt-0 d-inline-block mx-auto"
          style={{ width: "60px" }}
        /> */}
        <MapComponent key="map" />
      </div>
    );
  }
}

export default FooterMap;
