import {
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  SIGNUP_RESEND_FAILURE,
  VERIFY_EMAIL_FAILURE,
  SIGNIN_FAILURE,
  AUTH_USER,
  UNAUTH_USER,
  ACTIVATE_ACCOUNT_SUCCESS,
  ACTIVATE_ACCOUNT_FAILURE,
  FETCH_USER
} from "../../actions/management/auth/types";

export default function(state = {}, action) {
  switch (action.type) {
    case SIGNUP_SUCCESS:
      return { ...state, signup: true, error: {} };
    case SIGNUP_FAILURE:
      return { ...state, signup: false, error: { SignUp: action.payload } };
    case SIGNUP_RESEND_FAILURE:
      return {
        ...state,
        signup: true,
        error: { signupResend: action.payload }
      };
    case VERIFY_EMAIL_FAILURE:
      return { ...state, signup: true, error: { VerifyEmail: action.payload } };
    case SIGNIN_FAILURE:
      return { ...state, error: { signin: action.payload } };
    case AUTH_USER:
      return {
        ...state,
        authenticated: true,
        data: { UserProfile: action.payload },
        error: {}
      };
    case UNAUTH_USER:
      return { ...state, authenticated: false, error: {} };
    case ACTIVATE_ACCOUNT_SUCCESS:
      return { ...state, error: { accountActivation: action.payload } };
    case ACTIVATE_ACCOUNT_FAILURE:
      return { ...state, error: { accountActivation: action.payload } };
    case FETCH_USER:
      return { ...state, profile: action.payload };
    default:
      return state;
  }
}
