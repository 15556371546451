import {
  MAIL_ATTACHMENT_SIZE_LIMIT
  // MAIL_ATTACHMENT_LENGTH_LIMIT
} from "../config";
import history from "../routes/history";

export function auth401(e) {
  if (
    e.response
    && e.response.status === 401
    && e.response.data
    && e.response.data.error
    && e.response.data.error.name === "UnauthorizedError"
  ) {
    sessionStorage.removeItem("user");
    history.push(`/auth/signin`);
  }
}

/* Validation */
export const limitLength = (name, text, min = 0, max = 0) => {
  const length = text.length;
  if (min === 0 && max === 0) {
    return name + " should not be empty";
  } else if (length === 0 || length >= max || length < min) {
    return (
      name + " should not be empty / length be between " + min + " to " + max
    );
  }
  return "";
};

export const allLetter = (name, text) => {
  const letters = /^[A-Za-z]+$/;
  if (text.match(letters)) {
    return "";
  } else {
    return name + " must have alphabet characters only";
  }
};

export const alphanumeric = (name, text) => {
  const letters = /^[0-9a-zA-Z]+$/;
  if (text.match(letters)) {
    return "";
  } else {
    return name + " must have alphanumeric characters only";
  }
};

export const allnumeric = (name, text) => {
  const numbers = /^[0-9]+$/;
  if (text.match(numbers)) {
    return "";
  } else {
    return name + " must have numeric characters only";
  }
};

export const validateUsername = userName => {
  const name = "Username";
  let message = limitLength(name, userName, 5, 30);
  if (!message) {
    return "";
  } else {
    return message;
  }
};

export const strongPassworValidation = password => {
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/;
  const validPassword = regExp.test(password);
  if (validPassword) {
    return "";
  } else {
    return "Use 8 or more characters with a mix of upper case letters, lower case letters, numbers & symbols";
  }
};

export const strongPassworValidation2 = password => {
  const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowercase = "abcdefghijklmnopqrstuvwxyz";
  const digits = "0123456789";
  const splChars = "!@#$%&*()";
  const ucaseFlag = contains(password, uppercase);
  const lcaseFlag = contains(password, lowercase);
  const digitsFlag = contains(password, digits);
  const splCharsFlag = contains(password, splChars);
  let validPassword = false;

  if (password.length >= 8 && password.length < 30 && ucaseFlag && lcaseFlag && digitsFlag && splCharsFlag)
    validPassword = true;

  if (validPassword) {
    return "";
  } else {
    return "Use 8 to 30 characters with a mix of upper case letters, lower case letters, numbers & symbols";
  }
};

export const contains = (password, allowedChars) => {
  for (let i = 0; i < password.length; i++) {
    var char = password.charAt(i);
    if (allowedChars.indexOf(char) >= 0) { return true; }
  }
  return false;
}

export const validatePassword = password => {
  const message = limitLength("Password", password, 8, 30);
  if (!message) {
    return "";
  } else {
    return "Password should not be empty / length be between 8 to 30";
  }
};

export const validateEmail = email => {
  const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  if (email.match(mailformat)) {
    return "";
  } else {
    return "You have entered an invalid email address";
  }
};

export const validateConfirmPassword = (confirmPassword, value) => {
  if (confirmPassword === value.password) {
    return "";
  } else {
    return "Must match the previous entry";
  }
};

// export const formatDate = date => {
//   const d = new Date(date),
//     month = "" + (d.getMonth() + 1),
//     day = "" + d.getDate(),
//     year = d.getFullYear();

//   if (month.length < 2) month = "0" + month;
//   if (day.length < 2) day = "0" + day;

//   return [year, month, day].join("-");
// };

export const formatDate = (date, format = 4) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = d.getHours(),
    minute = d.getMinutes(),
    second = d.getSeconds();

  if (month.toString().length < 2) month = "0" + month;
  if (day.toString().length < 2) day = "0" + day;
  if (hour.toString().length < 2) hour = "0" + hour;
  if (minute.toString().length < 2) minute = "0" + minute;
  if (second.toString().length < 2) second = "0" + second;

  if (format === 1) {
    //yyyy-mm-dd
    return [year, month, day].join("-");
  } else if (format === 2) {
    //dd-mm-yyyy
    return [day, month, year].join("-");
  } else if (format === 3) {
    //dd/mm/yyyy
    return [day, month, year].join("/");
  } else if (format === 4) {
    //mm/dd/yyyy
    return [month, day, year].join("/");
  } else if (format === 5) {
    //mm/dd/yyyy HH:mm:ss
    return (
      [month, day, year].join("/") + " " + [hour, minute, second].join(":")
    );
  } else if (format === 6) {
    //dd/mm/yyyy HH:mm:ss
    return (
      [day, month, year].join("/") + " " + [hour, minute, second].join(":")
    );
  } else if (format === 7) {
    //yyyy-mm-dd HH:mm:ss
    return (
      [year, month, day].join("-") + " " + [hour, minute, second].join(":")
    );
  } else if (format === 8) {
    //H:mm A
    let dd = "AM";
    let h = Number(hour);
    if (h >= 12) {
      h = hour - 12;
      dd = "PM";
    }
    if (h === 0) {
      h = 12;
    }
    return [h, minute].join(":") + " " + dd;
  }
};

export const formatDateTime = date => {
  //must delete this function
  return new Date(date).toLocaleString();
};

export const formatBytes = (bytes, decimals) => {
  if (bytes === 0) return "0 Bytes";
  var k = 1024,
    dm = decimals <= 0 ? 0 : decimals || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const capitalizeFirstLetter = string => {
  return (
    string && string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  );
};

export const upperCase = string => {
  return (
    string && string.toUpperCase()
  );
};

export const ucFirstAllWords = string => {
  if (string) {
    var pieces = string.split(" ");
    for (var i = 0; i < pieces.length; i++) {
      var j = pieces[i].charAt(0).toUpperCase();
      pieces[i] = j + pieces[i].substr(1).toLowerCase();
    }
    return pieces.join(" ");
  }
  return "";
};

export const boolToYesNo = value => {
  if (value === 1 || value === '1' || value === true) {
    return 'Yes';
  }
  return "No";
};

export const downloadFile = MIMEType => {
  return MIMEType === "application/pdf" ||
    MIMEType === "image/bmp" ||
    MIMEType === "image/gif" ||
    MIMEType === "image/jpeg" ||
    MIMEType === "image/png" ||
    MIMEType === "application/x-bzip"
    ? false
    : true;
};

export const getFileType = MIMEType => {
  let type = "file";
  if (MIMEType === "application/pdf") type = "pdf";
  if (
    MIMEType === "image/bmp" ||
    MIMEType === "image/gif" ||
    MIMEType === "image/jpeg" ||
    MIMEType === "image/png" ||
    MIMEType === "application/x-bzip"
  )
    type = "image";
  return type;
};

export const getIconFile = MIMEType => {
  switch (MIMEType) {
    case "application/pdf":
      return "fa fa-file-pdf-o";
    case "image/bmp":
      return "fa fa-file-image-o";
    case "image/gif":
      return "fa fa-file-image-o";
    case "image/jpeg":
      return "fa fa-file-image-o";
    case "image/png":
      return "fa fa-file-image-o";
    case "application/x-bzip":
      return "fa fa-file-archive-o";
    case "application/x-bzip2":
      return "fa fa-file-archive-o";
    case "application/x-rar-compressed":
      return "fa fa-file-archive-o";
    case "application/x-tar":
      return "fa fa-file-archive-o";
    case "application/zip":
      return "fa fa-file-archive-o";
    case "application/x-7z-compressed":
      return "fa fa-file-archive-o";
    case "text/csv":
      return "fa fa-file-excel-o";
    case "text/plain":
      return "fa fa-file-text-o";
    case "application/msword":
      return "fa fa-file-word-o";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "fa fa-file-word-o";
    case "application/vnd.ms-powerpoint":
      return "fa fa-file-powerpoint-o";
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return "fa fa-file-powerpoint-o";
    case "application/vnd.ms-excel":
      return "fa fa-file-excel-o";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "fa fa-file-excel-o";
    default:
      return "fa fa-file-o";
  }
};

//redux form validation
export const requiredValidation = value => {
  return (value || typeof value === "number") && value !== "<p><br></p>"
    ? undefined
    : "This field is required";
}
export const requiredFileValidation = (value, allValues) => {
  if (allValues && allValues.name === 'scan form') {
    return (value || typeof value === "number") && value !== "<p><br></p>"
      ? undefined
      : "This field is required";
  } else {
    return undefined;
  }
}
export const minLengthValidation = min => value =>
  value && value.length <= min ? `Must be ${min} characters` : undefined;
export const minLength10Validation = minLengthValidation(10);

export const maxLengthValidation = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const maxLength512Validation = maxLengthValidation(512);
export const maxLength255Validation = maxLengthValidation(255);
export const maxLength125Validation = maxLengthValidation(125);
export const maxLength100Validation = maxLengthValidation(100);
export const maxLength50Validation = maxLengthValidation(50);
export const maxLength32Validation = maxLengthValidation(32);

export const numberValidation = value =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;

export const positiveIntegerValidation = value =>
  value >>> 0 === parseFloat(value) ? undefined : "Must be a positive integer";

export const positiveNumberValidation = value =>
  value < 0 ? "Must be a positive number" : undefined;

export const minValueValidation = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined;

export const tooOldValidation = value =>
  value && value > 150 ? "You might be too old for this" : undefined;

export const emailsValidation = value => {
  if (value && value !== "") {
    const emailArray = value.split(",");
    for (const email of emailArray) {
      if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) && email.toLowerCase() !== 'all_patients') {
        return "You have entered an invalid email address";
      }
    }
  }
  return undefined;
};

export const emailValidation = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "You have entered an invalid email address"
    : undefined;

export const phoneValidation = value =>
  value && !/^\d{3}-\d{3}-\d{4}$/i.test(value)
    ? "You have entered an invalid phone number"
    : undefined;

// export const phoneValidation = value =>
//   value && !/^\(\d{3}\)\s\d{3}-\d{4}$/i.test(value)
//     ? "You have entered an invalid phone number"
//     : undefined;

export const ssnValidation = value =>
  value && !/^\(\d{3}\)\s\d{2}-\d{4}$/i.test(value)
    ? "You have entered an invalid ssn"
    : undefined;

export const urlValidation = value =>
  value &&
    !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/i.test(
      value
    )
    ? "You have entered an invalid url"
    : undefined;

export const zipValidation = value =>
  value && !/^\d{5}(?:[-\s]\d{4})?$/i.test(value)
    ? "You have entered an invalid zip"
    : undefined;

export const updateFilesValidation = value => {
  if (value && value.length > 0) {
    // if (value.length > MAIL_ATTACHMENT_LENGTH_LIMIT) {
    //   return "";
    // } else {
    let totalSize = 0;
    for (const attachment of value) {
      totalSize += attachment.size;
    }
    if (totalSize > MAIL_ATTACHMENT_SIZE_LIMIT) {
      return "The server does not send email messages with attachments that exceed 20MB";
    }
    // }
  }
  return undefined;
};

export const time24to12Array = time => {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
    const hour = Number(time[0]);
    const minute = Number(time[2]);
    time[0] = hour < 10 ? `0${hour}` : hour;
    time[2] = minute < 10 ? `0${minute}` : minute;
  }
  return time;
};

export const formatDateTime2 = value => {
  let date = new Date(value);
  let month = padValue(date.getMonth() + 1);
  let day = padValue(date.getDate());
  let year = date.getFullYear();
  let hour = date.getHours();
  let minute = padValue(date.getMinutes());
  let meridian = "AM";
  var hourCheck = parseInt(hour);
  if (hourCheck > 12) {
    meridian = "PM";
    hour = hourCheck - 12;
  } else if (hourCheck === 0) {
    hour = "12";
  }
  hour = padValue(hour);

  return (
    month + "-" + day + "-" + year + " " + hour + ":" + minute + " " + meridian
  );
};

export const getRoundedDate = (minutes, d = new Date()) => {
  let ms = 1000 * 60 * minutes; // convert minutes to ms
  let roundedDate = new Date(Math.round(d.getTime() / ms) * ms);
  return roundedDate;
};

export const getCeiledDate = (minutes, d = new Date()) => {
  let ms = 1000 * 60 * minutes; // convert minutes to ms
  let ceiledDate = new Date(Math.ceil(d.getTime() / ms) * ms);
  return ceiledDate;
};

export const getFlooredDate = (minutes, d = new Date()) => {
  let ms = 1000 * 60 * minutes; // convert minutes to ms
  let flooredDate = new Date(Math.floor(d.getTime() / ms) * ms);
  return flooredDate;
};

export const padValue = value => {
  return value < 10 ? "0" + value : value;
};

export const time24to12String = time => {
  return time24to12Array(time).join(""); // return adjusted time or original string
};

export const time12to24Array = time => {
  // Convert a string like 10:05:23 PM to 24h format, returns like [22,5,23]
  time = time.toString().match(/(\d+):(\d+) (\w)/) || [time];
  let hour = 0;
  let minute = 0;
  if (time.length === 4) {
    hour = Number(time[1]);
    minute = Number(time[2]);
    let meridian = time[3].toLowerCase();
    if (meridian === "p" && hour < 12) {
      hour += 12;
    } else if (meridian === "a" && hour === 12) {
      hour -= 12;
    }
  } else {
    time = time[0].toString().match(/(\d+):(\d+)/) || [time];
    hour = Number(time[1]);
    minute = Number(time[2]);
  }
  return [hour < 10 ? `0${hour}` : hour, minute < 10 ? `0${minute}` : minute];
};

export const time12to24String = time => {
  const timeArray = time12to24Array(time);
  return timeArray[0] + ":" + timeArray[1];
};

export const parseBusinessLogic = (workingPlan, breakPlan) => {
  let parseData = {};
  for (let row of workingPlan) {
    const day = row.day;
    const start = row.start;
    const end = row.end;
    parseData[day] = { start, end, breaks: [] };
  }

  for (let row of breakPlan) {
    const day = row.day;
    const start = row.start;
    const end = row.end;
    parseData[day].breaks.push({ start, end });
  }
  return JSON.stringify(parseData);
};

export const isArray = obj => {
  return !!obj && obj.constructor === Array;
};
