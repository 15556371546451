/* eslint-disable */
import { combineReducers } from "redux";
import { reducer as FormReducer } from "redux-form";
import AuthReducer from "./auth";
import ResetPasswordReducer from "./auth/reset-password";
import ClinicReducer from "./clinic";
import EmployeeReducer from "./employee";
import PatientReducer from "./patient";
import AdvertisementReducer from "./advertisement";
import ToggleThemeReducer from "./toggle-theme";
import DocumentReducer from "./document";
import ServiceReducer from "./service";
import LegalContentReducer from "./legal-content";
import SystemBussinessLogicReducer from "./system-bussiness-logic";
import AppointmentReducer from "./appointment";
import WorkingPlanReducer from "./working-plan";
import GeneralReducer from "./general";
import MessageReducer from "./message";
import AttachmentReducer from "./attachment";
import DashboardReducer from "./dashboard";
import PatientFormReducer from "./patient-form";
import PltrFormReducer from "./pltr-form";
import FotonaFormReducer from "./fotona-form";

const RootReducer = combineReducers({
  form: FormReducer,
  auth: AuthReducer,
  resetPass: ResetPasswordReducer,
  clinic: ClinicReducer,
  employee: EmployeeReducer,
  patient: PatientReducer,
  advertisement: AdvertisementReducer,
  toggleTheme: ToggleThemeReducer,
  document: DocumentReducer,
  service: ServiceReducer,
  legalContent: LegalContentReducer,
  systemBussinessLogic: SystemBussinessLogicReducer,
  appointment: AppointmentReducer,
  workingPlan: WorkingPlanReducer,
  general: GeneralReducer,
  message: MessageReducer,
  attachment: AttachmentReducer,
  dashboard: DashboardReducer,
  patientForm: PatientFormReducer,
  pltrForm: PltrFormReducer,
  fotonaForm: FotonaFormReducer,
});

export default RootReducer;
