import React from "react";
import { FormGroup, Label, Input, FormFeedback, FormText } from "reactstrap";

class InputField extends React.Component {
  renderLabel(name, label) {
    return label ? (<Label htmlFor={name}>{label}</Label>) : '';
  }

  render() {
    const {
      input,
      type = "text",
      name,
      label,
      placeholder,
      readonly,
      description,
      meta: { touched, error, warning }
    } = this.props;
    return type === "hidden" ? (
      <Input type={type} readOnly {...input} />
    ) : (
      <FormGroup>
        {this.renderLabel(name, label)}
        <Input
          invalid={touched && (warning || error) ? true : false}
          placeholder={placeholder}
          type={type}
          readOnly={readonly}
          {...input}
        />
        <FormText color="muted">{description}</FormText>
        {touched &&
          ((warning || error) && <FormFeedback>{error}</FormFeedback>)}
      </FormGroup>
    );
  }
}

export default InputField;
