import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../../../actions/management/auth";
import { connect } from "react-redux";
import { CLINIC } from "../../../../data/app.json";
import history from "../../../../routes/history";
// import InputField from "../../../../components/form/input-field";
import {
  strongPassworValidation2,
  requiredValidation,
  emailValidation,
  maxLength255Validation
} from "../../../../utils/index";

const renderField = ({
  input,
  icon,
  type,
  placeholder,
  meta: { touched, error, warning }
}) => (
  <div className="form-group">
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className={"fa " + icon} />
        </span>
      </div>
      <input
        className="form-control"
        placeholder={placeholder}
        type={type}
        {...input}
      />
      {touched &&
        ((error && <div className="invalid-feedback d-block">{error}</div>) ||
          (warning && (
            <div className="invalid-feedback d-block">{warning}</div>
          )))}
    </div>
  </div>
);

class SignInIndex extends Component {
  constructor() {
    super();

    this.state = {
      message: ""
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const message = location.state && location.state.message;
    if (history.action === "PUSH") {
      this.setState({
        message
      });
    }
  }

  handleFormSubmit = props => {
    this.props.signIn(props);
  };

  renderHeaderForm() {
    return (
      <div className="card-header pb-5">
        <div className="btn-wrapper text-center">
          <div id="lglogo" className="img-fluid" alt={CLINIC}></div>
        </div>
      </div>
    );
  }

  renderMessage(message, valid = true) {
    return message !== "" ? (
      <div className={(valid ? "valid" : "invalid") + "-feedback d-block pb-3"}>
        {message}
      </div>
    ) : null;
  }

  renderForm() {
    const { handleSubmit, submitting } = this.props;
    const errorMessage =
      (this.props.errorMessage && this.props.errorMessage.signin) || "";
    return (
      <div className="card-body px-lg-5 py-lg-5">
        <form onSubmit={handleSubmit(this.handleFormSubmit)}>
          {errorMessage === ""
            ? this.renderMessage(this.state.message)
            : this.renderMessage(errorMessage, false)}
          <Field
            name="email"
            component={renderField}
            type="email"
            placeholder="Email"
            icon="fa-at"
            validate={[requiredValidation, emailValidation, maxLength255Validation]}
          />
          <Field
            name="password"
            component={renderField}
            type="password"
            placeholder="Password"
            icon="fa-lock"
            validate={[requiredValidation, strongPassworValidation2]}
          />
          <div className="text-center">
            <button
              type="submit"
              className="btn-round btn btn-default mt-4"
              disabled={submitting}
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    );
  }

  renderLinks() {
    return (
      <div className="row mt-3">
        <div className="col-6">
          <Link to="/auth/forgot-password" className="text-light">
            <small className="font-weight-light">Forgot password?</small>
          </Link>
        </div>
        <div className="col-6 text-right">
          <Link to="/auth/signup" className="text-light">
            <small className="font-weight-light">Create new account</small>
          </Link>
        </div>
      </div>
    );
  }

  render() {
    return (
      <section className="section section-shaped section-lg">
        <div className="shape shape-style-1 bg-gradient-default" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="card bg-gradient-secondary shadow border-0">
                {this.renderHeaderForm()}
                {this.renderForm()}
              </div>
              {this.renderLinks()}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return { errorMessage: state.auth.error };
}

SignInIndex = reduxForm({ form: "signin" })(SignInIndex);

export default connect(
  mapStateToProps,
  actions
)(SignInIndex);
