import React, { Component } from "react";
import Slider from "react-slick";

const SETTINGS = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
  lazyLoad: true
};

class FooterTestimonials extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      data: null
    };

  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      const link = "/static/data/sites/general/testimonials.json";
      fetch(link)
        .then(r => r.json())
        .then(data => {
          this._isMounted && this.setState({
            data
          });
        });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  renderTestimonialsItem() {
    if (this.state.data == null) return null;
    const testimonialsItemElement = this.state.data.testimonials.map((item, index) => (
      <div key={index} className="container p-0 mb-2">
        <div className="slide-wrapper">
          <div className="text-wrapper justify-content-md-end justify-content-center">
            <p>{item.message}</p>
          </div>
          <div className="client-name-wrapper border-left">
            <h4 className="mb-0 pl-2">
              {item.name}
              <span>{item.age}</span>
            </h4>
            <div className="line" />
            <p className="mb-0 pl-2">{item.description}</p>
          </div>
        </div>
      </div>
    ));
    return testimonialsItemElement;
  }

  render() {
    return (
      <div className="col-md-6 col-lg-4 col-xl-4">
        <h6 className="text-uppercase">Testimonials</h6>
        <hr
          className="teal accent-3 mb-3 mt-0 d-inline-block mx-auto"
          style={{ width: "60px" }}
        />
        <Slider {...SETTINGS}>{this.renderTestimonialsItem()}</Slider>
      </div>
    );
  }
}

export default FooterTestimonials;
