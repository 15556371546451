import React, { Component } from "react";
// import ReCAPTCHA from "react-google-recaptcha";
import {
 // limitLength,
  // allLetter,
  // validateEmail,
  validateConfirmPassword,
  strongPassworValidation2,
  requiredValidation
} from "../../../../utils/index";
import history from "../../../../routes/history";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../../../actions/management/auth/reset-password";
import { connect } from "react-redux";
import InputField from "../../../../components/form/input-field";
//import { Button, Col, FormGroup, Row, InputGroup } from "reactstrap";

// http://localhost:3001/auth/reset-password/mbooc7jf98vpn2ai12uc

class ResetPasswordIndex extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      showConfirmPassword: false,
      validation: {
        password: "",
        confirmPassword: ""
      },
      recaptcha: "",
      status: "",
      message: ""
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const id = location.state && location.state.id;
    if (history.action === "PUSH") {
      this.setState(
        {
          id
        },
        () => this.props.fetch(id)
      );
    }
  }

  //handlePasswordChange = e => {
  //  this.setState(
  //    {
  //      password: e.target.value,
  //      showConfirmPassword: e.target.value === "" ? false : true
  //    },
  //    () => this.validate("password")
  //  );
  //};

  //handleConfirmPasswordChange = e => {
  //  this.setState(
  //    {
  //      confirmPassword: e.target.value
  //    },
  //    () => this.validate("confirmPassword")
  //  );
  //};

  handleSubmit = props => {
    props.token = this.props.match.params.token;
    this.props.resetPassword(props);

    //e.preventDefault();
    //if (this.validateForm()) {
    //  const data = {
    //    password: this.state.password,
    //    // recaptcha: this.state.recaptcha
    //    token: this.props.match.params.token
    //  };
    //  axios
    //    .post(API_URL + "/auth/reset-password", data, {
    //      headers: {
    //        Authorization: "Bearer NoAuth",
    //        "Content-Type": "application/json"
    //      }
    //    })
    //    .then(response => {
    //      const message = response.data["Message"];
    //      const status = response.data["Status"];
    //      if (status === "Successed") {
    //        history.push({
    //          pathname: `/auth/signin`,
    //          state: { message: message }
    //        });
    //      } else {
    //        this.setState({
    //          message,
    //          status
    //        });
    //        this.username.focus();
    //      }
    //    });
    //}
  };

  //validatePassword = () => {
  //  const message = limitLength("Password", this.state.password, 7, 12);
  //  let validation = this.state.validation;
  //  validation.password = message;
  //  this.setState(
  //    {
  //      validation
  //    },
  //    () => { }
  //  );
  //  return message === "";
  //};
  ////redux form validate confirm password
  //validateConfirmPassword = () => {
  //  const message = validateConfirmPassword(
  //    this.state.password,
  //    this.state.confirmPassword
  //  );
  //  let validation = this.state.validation;
  //  validation.confirmPassword = message;
  //  this.setState(
  //    {
  //      validation
  //    },
  //    () => { }
  //  );
  //  return message === "";
  //};

  //validate = key => {
  //  let message = "";
  //  switch (key) {
  //    case "password":
  //      message = this.validatePassword();
  //      break;

  //    case "confirmPassword":
  //      message = this.validateConfirmPassword();
  //      break;
  //    default:
  //      break;
  //  }
  //  return message;
  //};

  //validateForm = () => {
  //  return (
  //    this.validatePassword() &&
  //    this.validateConfirmPassword()
  //  );
  //};

  renderMessage(message, valid = true) {
    return message !== "" ? (
      <div className={(valid ? "valid" : "invalid") + "-feedback d-block pb-3"}>
        {message}
      </div>
    ) : null;
  }

  renderForm() {
    const { handleSubmit, submitting } = this.props;
    const errorMessage = (this.props.errorMessage && this.props.errorMessage.resetPassword) || "";
    return (
      <div className="card-body px-lg-5 py-lg-5">
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          {errorMessage === ""
            ? this.renderMessage(this.state.message)
            : this.renderMessage(errorMessage, false)}
          
          <Field
            name="password"
            component={InputField}
            type="password"
            placeholder="Password"
            icon = "fa-lock"
            validate={[requiredValidation, strongPassworValidation2]}
            />

          <Field
            name="confirm_password"
            component={InputField}
            type="password"
            placeholder="Confirm Password"
            icon="fa-lock"
            validate={[requiredValidation, validateConfirmPassword]}
          />
     
          <div className="text-center">
            <button
              type="submit"
              className="btn-round btn btn-default font-weight-light mt-4"
              disabled={submitting}
            >
              Reset
            </button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return (
      <section className="section section-shaped section-lg">
        <div className="shape shape-style-1 bg-gradient-default" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="card bg-gradient-secondary shadow border-0">
                <div className="card-header pb-4 pt-4">
                  <div className="btn-wrapper text-center">
                    <p className="lead mt-0">RESET PASSWORD</p>
                    <small>
                      Enter new password
                    </small>
                  </div>
                </div>
                {this.renderForm()}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

//export default ResetPasswordIndex;


function mapStateToProps(state) {
  return { errorMessage: state.resetPass.error };
}

ResetPasswordIndex = reduxForm({ form: "resetPassword" })(ResetPasswordIndex);

export default connect(
  mapStateToProps,
  actions
)(ResetPasswordIndex);
