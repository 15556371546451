import React, { Component } from "react";
import Slider from "react-slick";
import WOW from "wowjs";

class TreatmentSection extends Component {
  constructor() {
    super();
    this.scrolled = false;
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {
    if (!this.scrolled) {
      this.scrolled = true;
      new WOW.WOW({
        live: false
      }).init();
    }
  };

  renderTreatmentVideo() {
    const { videoUrl, title } = this.props;

    return videoUrl ? (
      <div className="mb-5 wow fadeIn" data-wow-delay="0.3s">
        <p className="lead pt-5">{title}</p>
        <div className="embed-responsive embed-responsive-16by9">
          <video
              loop
              autoPlay
              controls
              playsInline
              className={"service-video"}
            >
              <source src={videoUrl} type="video/mp4" />
            </video>
          {/* <iframe
            className="embed-responsive-item"
            src={videoUrl}
            allowFullScreen
            title="treatment"
          /> */}
        </div>
      </div>
    ) : null;
  }

  renderTreatmentPhoto() {
    const { images, subTitle } = this.props;
    const SETTINGS = {
      className: "slider variable-width",
      dots: true,
      infinite: true,
      centerMode: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      autoplay: true,
      speed: 700,
      arrows: true,
      lazyLoad: true
    };

    return images.length > 0 ? (
      <div className="mb-5 wow fadeIn" data-wow-delay="0.4s">
        <p className="lead pt-5">{subTitle}</p>
        <div className="treatment-slider-xs">
          <Slider {...SETTINGS}>
            {images.map((image, index) => (
              <div key={index}>
                <img src={image} height="200px" alt="Treatment" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    ) : null;
  }

  render() {
    return (
      <div>
        {this.renderTreatmentVideo()}
        {this.renderTreatmentPhoto()}
      </div>
    );
  }
}

export default TreatmentSection;
