import React, { Component } from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers";
import thunk from "redux-thunk";
import { AUTH_USER, UNAUTH_USER } from "./actions/management/auth/types";
import { Router, Route, Switch } from "react-router-dom";
import { ManagementLayout } from "./views/management";
import { AuthLayout } from "./views/management/auth";
import { ErrorLayout } from "./views/error/index";
import { SiteLayout } from "./views/sites/index";
import RequireAuth from "./components/management/auth/hoc/require-auth";
import RequireNotAuth from "./components/management/auth/hoc/require-not-auth";
import history from "./routes/history";
import { ToastContainer } from "react-toastify";

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
const store = createStoreWithMiddleware(reducers);
const user = JSON.parse(sessionStorage.getItem("user"));

export function authSuccess(CONST, roleMapping) {
  return {
    type: CONST,
    payload: roleMapping
  };
}

class App extends Component {
  componentDidMount() {
    if (user && user.AccessToken.id) {
      store.dispatch(authSuccess(AUTH_USER, user));
      // store.dispatch({ type: AUTH_USER });
      // history.push("/management/");
    } else {
      store.dispatch({ type: UNAUTH_USER });
    }
  }

  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Route
              path="/auth"
              name="Auth Layout"
              component={RequireNotAuth(AuthLayout)}
            />
            <Route
              path="/management"
              name="Management Layout"
              component={RequireAuth(ManagementLayout)}
            />
            <Route path="/error" name="Error Layout" component={ErrorLayout} />
            <Route path="/" name="Site Layout" component={SiteLayout} />
            <Route component={ErrorLayout} />
          </Switch>
        </Router>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </Provider>
    );
  }
}

export default App;
