import React, { Component } from "react";
import { CLINIC_ID, STATES, GENDERS } from "../../../../config";
import {
  strongPassworValidation2,
  validateConfirmPassword,
  requiredValidation,
  phoneValidation,
  emailValidation,
  zipValidation,
  maxLength512Validation,
  maxLength255Validation,
  maxLength50Validation
} from "../../../../utils";
import history from "../../../../routes/history";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../../../actions/management/auth";
import { connect } from "react-redux";
import DropDownSelect from "../../../../components/form/drop-down-select";
import DatePicker from "../../../../components/form/date-picker";
import InputField from "../../../../components/form/input-field";
import { Col, Row } from "reactstrap";
import renderField from "../../../../components/form/input-field";
import InputMaskField from "../../../../components/form/input-mask-field";

// const renderField = ({
//   input,
//   icon,
//   type,
//   placeholder,
//   meta: { touched, error, warning }
// }) => (
//   <div className="form-group">
//     <div className="input-group">
//       <div className="input-group-prepend">
//         <span className="input-group-text">
//           <i className={"fa " + icon} />
//         </span>
//       </div>
//       <input
//         className="form-control font-weight-light"
//         placeholder={placeholder}
//         type={type}
//         {...input}
//       />
//       {touched &&
//         ((error && <div className="invalid-feedback d-block">{error}</div>) ||
//           (warning && (
//             <div className="invalid-feedback d-block">{warning}</div>
//           )))}
//     </div>
//   </div>
// );

class SignUpIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmPassword: "",
      agreedPolicy: false,
      showConfirmPassword: false,
      status: "",
      message: ""
    };
  }

  handleAgreedPolicyChange = e => {
    this.setState({
      agreedPolicy: !this.state.agreedPolicy
    });
  };

  handleFormSubmit = props => {
    if (props.confirm_password) {
      delete props.confirm_password;
    }
    props.clinicId = CLINIC_ID;
    this.props.signUp(props);
  };

  renderMessage(message, valid = true) {
    return message !== "" ? (
      <div className={(valid ? "valid" : "invalid") + "-feedback d-block pb-3"}>
        {message}
      </div>
    ) : null;
  }

  handleNavLegalContent = () => {
    history.push({
      pathname: `/auth/legal-content`,
      state: {}
    });
  };

  renderForm() {
    const { handleSubmit/*, submitting*/ } = this.props;
    const errorMessage =
      (this.props.errorMessage && this.props.errorMessage.SignUp) || "";
    return (
      <div className="card-body px-lg-4 py-lg-4">
        <form onSubmit={handleSubmit(this.handleFormSubmit)}>
          {errorMessage === ""
            ? this.renderMessage(this.state.message)
            : this.renderMessage(errorMessage, false)}
          <Row>
            <Col xs="12" sm="6">
              <Field
                name="firstName"
                component={renderField}
                type="text"
                placeholder="First name"
                icon="fa-id-card-o"
                validate={[requiredValidation, maxLength255Validation]}
              />
            </Col>
            <Col xs="12" sm="6">
              <Field
                name="lastName"
                component={renderField}
                type="text"
                placeholder="Last name"
                icon="fa-id-card-o"
                validate={[requiredValidation, maxLength255Validation]}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="6">
              <Field
                name="birthday"
                component={DatePicker}
                placeholder="Birthday (MM/DD/YYYY)"
                // icon="fa-birthday-cake"
                validate={[requiredValidation]}
              />
            </Col>
            <Col xs="12" sm="6">
              <Field
                name="gender"
                component={DropDownSelect}
                validate={[requiredValidation]}
                data={GENDERS}
                placeholder="Gender"
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="6">
              <Field
                name="cellPhone"
                component={InputMaskField}
                type="text"
                placeholder="Cell phone 000-000-0000"
                // icon="fa-phone"
                validate={[requiredValidation, phoneValidation, maxLength50Validation]}
                mask={[
                  /[1-9]/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/
                ]}
              />
            </Col>
            <Col xs="12" sm="6">
              <Field
                name="email"
                component={renderField}
                type="email"
                placeholder="Email"
                icon="fa-at"
                validate={[requiredValidation, emailValidation, maxLength255Validation]}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="6">
              <Field
                name="password"
                component={renderField}
                type="password"
                placeholder="Password"
                icon="fa-lock"
                validate={[requiredValidation, strongPassworValidation2]}
              />
            </Col>
            <Col xs="12" sm="6">
              <Field
                name="confirm_password"
                component={renderField}
                type="password"
                placeholder="Confirm Password"
                icon="fa-lock"
                validate={[requiredValidation, validateConfirmPassword]}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="6">
              <Field
                name="address"
                component={renderField}
                type="text"
                placeholder="Address"
                validate={[requiredValidation, maxLength512Validation]}
                icon="fa-map-marker"
              />
            </Col>
            <Col xs="12" sm="6">
              <Row>
                <Col xs="4" className="pr-0">
                  <Field
                    name="city"
                    component={InputField}
                    type="text"
                    placeholder="City"
                    validate={[requiredValidation, maxLength255Validation]}
                  />
                </Col>
                <Col xs="4" className="pr-0">
                  <Field
                    name="state"
                    component={DropDownSelect}
                    placeholder="State"
                    validate={[requiredValidation, maxLength255Validation]}
                    data={STATES}
                  />
                </Col>
                <Col xs="4">
                  <Field
                    name="zip"
                    component={InputField}
                    type="text"
                    placeholder="Zip"
                    validate={[requiredValidation, zipValidation]}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="row my-4">
            <div className="col-12">
              <div className="custom-control custom-checkbox">
                <input
                  className="custom-control-input font-weight-light text-default"
                  id="customCheckRegister"
                  type="checkbox"
                  value={this.state.agreedPolicy}
                  onChange={this.handleAgreedPolicyChange}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheckRegister"
                >
                  I agree with the
                </label>
                <label className="pl-1 text-info" onClick={this.handleNavLegalContent}>Privacy Policy</label>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="btn-round btn btn-default font-weight-light mt-4"
              disabled={!this.state.agreedPolicy}
            >
              <i className="fa fa-user-plus pr-2" aria-hidden="true"></i>
              Sign Up
            </button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return (
      <section className="section section-shaped section-lg">
        <div className="shape shape-style-1 bg-gradient-default" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="card bg-gradient-secondary shadow border-0">
                <div className="card-header pb-4 pt-4">
                  <div className="btn-wrapper text-center">
                    <p className="lead mt-0">CREATE ACCOUNT</p>
                    <small>
                      Enter the following details to create your user account
                    </small>
                  </div>
                </div>
                {this.renderForm()}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return { errorMessage: state.auth.error };
}

SignUpIndex = reduxForm({ form: "signup" })(SignUpIndex);

export default connect(
  mapStateToProps,
  actions
)(SignUpIndex);
