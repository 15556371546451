import React from "react";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
// import PropTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";
import "react-dates/lib/css/_datepicker.css";
import { FormGroup, Label, FormFeedback, FormText, Input } from "reactstrap";

const StyledDatePickerWrapper = styled.div`
  & .SingleDatePicker,
  .SingleDatePickerInput {
    display: block;

    .form-control {
        padding: 0px !important;
    }

    .DateInput {
      width: 100%;
      height: 40px;
      display: flex;
      background-color: var(--form-control-background-color);

      .DateInput_input {
        font-size: 1rem;
        border-bottom: 0;
        padding: 12px 16px 14px;
      }
    }

    .SingleDatePickerInput__withBorder {
      border-radius: 4px;
      overflow: initial;
      border: 0px solid;
      height: calc(2.75rem);
      background-color: var(--form-control-background-color);

      input {
        height: calc(2.75rem);
        padding: 0.625rem 0.75rem !important;
        font-size: 0.875rem !important;
        border-radius: 0.25rem;
        border: 0px solid;
      }

      :hover,
      .DateInput_input__focused {
        border: 0px solid red;
      }

      .CalendarDay__selected {
        background: blue;
        border: blueviolet;
      }
    }

    .SingleDatePicker_picker.SingleDatePicker_picker {
      top: 43px;
      left: 2px;
      /* top: 43px !important;
      left: 2px !important; */
      background-color: transparent !important;
    }
  }
`;

class DatePicker extends React.Component {
    // The props are supplied via redux-form's <Field /> component
    // static propTypes = {
    //     autoFocus: PropTypes.bool.isRequired,
    //     input: PropTypes.shape({
    //         value: PropTypes.string,
    //         onChange: PropTypes.func.isRequired,
    //         onFocus: PropTypes.func.isRequired,
    //         onBlur: PropTypes.func.isRequired
    //     }).isRequired
    // }

    constructor(props) {
        super(props)
        const { value } = this.props.input
        this.state = {
            date: (!value || value === '') ? null : moment(value, ["YYYY-MM-DD", "MM/DD/YYYY"]),
            focused: this.props.autoFocus
        }
    }

    renderLabel(name, label) {
        return label ? (<Label htmlFor={name}>{label}</Label>) : '';
    }

    getValueAsString = date => (date ? date.toISOString().substring(0, 10) : '')

    handleDateChange = (date) => {
        this.setState({ date }, () => {
            const dateStr = this.getValueAsString(this.state.date)
            this.props.input.onChange(dateStr)
        })
    }

    handleFocusChange = (e) => {
        this.setState({ focused: e.focused }, () => {
            const date = this.state.date
            const dateStr = this.getValueAsString(date)
            if (e.focused) {
                this.props.input.onFocus(dateStr)
            } else {
                this.props.input.onBlur(dateStr)
            }
        })
    }

    render() {
        const {
            input,
            name,
            label,
            placeholder,
            readonly,
            description,
            disabled,
            defaultDate,
            isOutsideRange,
            meta: { touched, error, valid },
            ...rest
        } = this.props;
        const dateStr = this.getValueAsString(this.state.date || (!input.value || input.value === '' || input.value === '0000-00-00') ? null : moment(input.value, ["YYYY-MM-DD", "MM/DD/YYYY"]));
        return <FormGroup>
            {this.renderLabel(name, label)}
            <StyledDatePickerWrapper
                className={
                    (touched && (valid === false || error !== undefined) && this.state.date === null
                        ? "is-invalid "
                        : "") + "form-control p-0"
                }>
                <SingleDatePicker
                    displayFormat="MM/DD/YYYY"
                    numberOfMonths={1}
                    disabled={disabled}
                    onDateChange={this.handleDateChange}
                    onFocusChange={this.handleFocusChange}
                    focused={this.state.focused}
                    date={(!input.value || input.value === '' || input.value === '0000-00-00') ? null : moment(input.value, ["YYYY-MM-DD", "MM/DD/YYYY"])}
                    placeholder={placeholder}
                    readOnly={readonly}
                    id={`_wrapped_${input.name}`}
                    isOutsideRange={() => isOutsideRange === false || isOutsideRange === undefined ? false : true}
                    {...rest}
                />
                <Input
                    type="hidden"
                    id={input.name}
                    value={dateStr}
                />
            </StyledDatePickerWrapper>
            <FormText color="muted">{description}</FormText>
            <FormFeedback>{error}</FormFeedback>
        </FormGroup>
    }
}

export default DatePicker;
