export const SAVE_DOCUMENT_SUCCESS = "SAVE_DOCUMENT";
export const SAVE_DOCUMENT_FAILURE = "SAVE_DOCUMENT_FAILURE";
export const UPDATE_DOCUMENT_SUCCESS = "UPDATE_DOCUMENT";
export const UPDATE_DOCUMENT_FAILURE = "UPDATE_DOCUMENT_FAILURE";
export const DEACTIVATE_DOCUMENT_SUCCESS = "DEACTIVATE_DOCUMENT";
export const DEACTIVATE_DOCUMENT_FAILURE = "DEACTIVATE_DOCUMENT_FAILURE";
export const DEACTIVATE_ALL_DOCUMENT_SUCCESS = "DEACTIVATE_ALL_DOCUMENT";
export const DEACTIVATE_ALL_DOCUMENT_FAILURE = "DEACTIVATE_ALL_DOCUMENT_FAILURE";
export const LIST_DOCUMENT = "LIST_DOCUMENT";
export const FETCH_DOCUMENT = "FETCH_DOCUMENT";
