export const SAVE_FORM_SUCCESS = "SAVE_FORM";
export const SAVE_FORM_FAILURE = "SAVE_FORM_FAILURE";
export const UPDATE_FORM_SUCCESS = "UPDATE_FORM";
export const UPDATE_FORM_FAILURE = "UPDATE_FORM_FAILURE";
export const DEACTIVATE_FORM_SUCCESS = "DEACTIVATE_FORM";
export const DEACTIVATE_FORM_FAILURE = "DEACTIVATE_FORM_FAILURE";
export const DEACTIVATE_ALL_FORM_SUCCESS = "DEACTIVATE_ALL_FORM";
export const DEACTIVATE_ALL_FORM_FAILURE = "DEACTIVATE_ALL_FORM_FAILURE";
export const LIST_FORM = "LIST_FORM";
export const FETCH_FORM = "FETCH_FORM";
