import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
import WOW from "wowjs"; 

class TreatmentAreasSection extends Component {
  constructor(props) {
    super(props);
    this.scrolled = false;
    this.state = {
      activeTab: 0
    };
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {
    if (!this.scrolled) {
      this.scrolled = true;
      new WOW.WOW({
        live: false
      }).init();
    }
  };

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  render() {
    const { data, title } = this.props;
    return data.length > 0 ? (
      <div className="mb-5 wow fadeIn" data-wow-delay="0.2s">
        <p className="lead">{title}</p>
        <Nav tabs>
          {data.map((tab, index) => (
            <NavItem key={index}>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === index
                })}
                onClick={() => {
                  this.toggle(index);
                }}
              >
                {tab.nav}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          {data.map((tab, index) => (
            <TabPane key={index} tabId={index}>
              <Row>
                <Col sm="12" className="p-3">
                  <p>{tab.description}</p>
                  {/* <p className="lead text-muted">{tab.benefits.title}</p> */}
                  <h6 className="mb-3 text-uppercase">{tab.benefits.title}</h6>
                  <ul className="mb-3">
                    {tab.benefits.items.map((item, itemIndex) => (
                      <li key={itemIndex}>{item}</li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </TabPane>
          ))}
        </TabContent>
      </div>
    ) : null;
  }
}

export default TreatmentAreasSection;
