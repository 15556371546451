import React, { Component } from "react";

class InstructionSection extends Component {
  render() {
    const { steps, title } = this.props;
    return (
      <div>
        {title ? <p className="lead mt-0">{title}</p> : null}
        {steps.map((step, index) => (
          <div key={index}>
            <p className="lead mt-0">{step.title}</p>

            <ul className="mb-3 list-unstyled">
              <li>
                <span dangerouslySetInnerHTML={{ __html: step.description }} />
              </li>
            </ul>
          </div>
        ))} 
      </div>
    );
  }
}

export default InstructionSection;
