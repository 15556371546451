import React from "react";
import Loadable from "react-loadable";

import ManagementLayout from "../views/management/layout";

function Loading() {
  return <div className="loading">Loading...</div>;
}

const DashboardIndex = Loadable({
  loader: () => import("../views/management/dashboard"),
  loading: Loading
});

const PatientListIndex = Loadable({
  loader: () => import("../views/management/patient/list"),
  loading: Loading
});

const AddPatientIndex = Loadable({
  loader: () => import("../views/management/patient/form"),
  loading: Loading
});

const EditPatientIndex = Loadable({
  loader: () => import("../views/management/patient/form"),
  loading: Loading
});

const PatientFormListIndex = Loadable({
  loader: () => import("../views/management/patient-form/list"),
  loading: Loading
});

const PltrFormListIndex = Loadable({
  loader: () => import("../views/management/pltr-form/list"),
  loading: Loading
});

const FotonaFormListIndex = Loadable({
  loader: () => import("../views/management/fotona-form/list"),
  loading: Loading
});

const AppointmentCalendarIndex = Loadable({
  loader: () => import("../views/management/appointment/calendar"),
  loading: Loading
});

const AppointmentHistoryIndex = Loadable({
  loader: () => import("../views/management/appointment/history"),
  loading: Loading
});

const DocumentListIndex = Loadable({
  loader: () => import("../views/management/document/list"),
  loading: Loading
});

const ClinicFormIndex = Loadable({
  loader: () => import("../views/management/clinic/form"),
  loading: Loading
});

const EmployeeListIndex = Loadable({
  loader: () => import("../views/management/employee/list"),
  loading: Loading
});

const AddEmployeeIndex = Loadable({
  loader: () => import("../views/management/employee/form"),
  loading: Loading
});

const EditEmployeeIndex = Loadable({
  loader: () => import("../views/management/employee/form"),
  loading: Loading
});

const AdvertisementListIndex = Loadable({
  loader: () => import("../views/management/advertisement/list"),
  loading: Loading
});

const AddAdvertisementIndex = Loadable({
  loader: () => import("../views/management/advertisement/form"),
  loading: Loading
});

const EditAdvertisementIndex = Loadable({
  loader: () => import("../views/management/advertisement/form"),
  loading: Loading
});

const ServiceListIndex = Loadable({
  loader: () => import("../views/management/service/list"),
  loading: Loading
});

const AddServiceIndex = Loadable({
  loader: () => import("../views/management/service/form"),
  loading: Loading
});

const EditServiceIndex = Loadable({
  loader: () => import("../views/management/service/form"),
  loading: Loading
});


const LegalContentIndex = Loadable({
  loader: () => import("../views/management/legal-content"),
  loading: Loading
});

const SystemBussinessLogicIndex = Loadable({
  loader: () => import("../views/management/system-bussiness-logic"),
  loading: Loading
});

const WorkingPlanIndex = Loadable({
  loader: () => import("../views/management/working-plan"),
  loading: Loading
});

const ProfileIndex = Loadable({
  loader: () => import("../views/management/profile"),
  loading: Loading
});

const GeneralIndex = Loadable({
  loader: () => import("../views/management/general"),
  loading: Loading
});

const InboxIndex = Loadable({
  loader: () => import("../views/management/message/inbox"),
  loading: Loading
});

const ComposeIndex = Loadable({
  loader: () => import("../views/management/message/compose"),
  loading: Loading
});

const ContentIndex = Loadable({
  loader: () => import("../views/management/message/content"),
  loading: Loading
});

const routes = [
  {
    path: "/management",
    name: "Management",
    component: ManagementLayout,
    exact: true,
    description: "Layout",
    breadcrumb: [{ text: "Management", url: "" }],
    icon: "fa-dashboard"
  },
  {
    path: "/management/dashboard",
    name: "Dashboard",
    component: DashboardIndex,
    description: "",
    breadcrumb: [{ text: "Dashboard", url: "" }],
    icon: "fa-dashboard"
  },
  {
    path: "/management/patient",
    name: "Patients",
    component: PatientListIndex,
    description: "List",
    breadcrumb: [{ text: "Patient", url: "" }],
    icon: "fa-users",
    exact: true,
  },
  {
    path: "/management/patient/add",
    name: "Patients",
    component: AddPatientIndex,
    description: "",
    breadcrumb: [
      { text: "Patients", url: "/management/patient" },
      { text: "Add Patient", url: "" }
    ],
    icon: "fa-users"
  },
  {
    path: "/management/patient/edit",
    name: "Patients",
    component: EditPatientIndex,
    description: "",
    breadcrumb: [
      { text: "Patients", url: "/management/patient" },
      { text: "Edit Patient", url: "" }
    ],
    icon: "fa-users"
  },
  {
    path: "/management/document",
    name: "Documents",
    component: DocumentListIndex,
    description: "List",
    breadcrumb: [{ text: "Document", url: "" }],
    icon: "fa-folder",
    exact: true,
  },
  {
    path: "/management/patient-form",
    name: "Patient Forms",
    component: PatientFormListIndex,
    description: "List",
    breadcrumb: [{ text: "Patient Form", url: "" }],
    icon: "fa-list-alt",
    exact: true,
  },
  {
    path: "/management/pltr-form",
    name: "Picosure Laser Treatment Form",
    component: PltrFormListIndex,
    description: "List",
    breadcrumb: [{ text: "Picosure Form", url: "" }],
    icon: "fa-list-alt",
    exact: true,
  },
  {
    path: "/management/fotona-form",
    name: "Fotona Form",
    component: FotonaFormListIndex,
    description: "List",
    breadcrumb: [{ text: "Fotona Form", url: "" }],
    icon: "fa-list-alt",
    exact: true,
  },
  {
    path: "/management/appointment/calendar",
    name: "Appointment",
    component: AppointmentCalendarIndex,
    description: "",
    breadcrumb: [{ text: "Appointment", url: "" }],
    icon: "fa-calendar"
  },
  {
    path: "/management/appointment/history",
    name: "Appointment History",
    component: AppointmentHistoryIndex,
    description: "List",
    breadcrumb: [
      { text: "Appointment", url: "" },
      { text: "History", url: "" }
    ],
    icon: "fa-history",
    exact: true,
  },
  {
    path: "/management/clinic",
    name: "Clinic",
    component: ClinicFormIndex,
    description: "Info",
    breadcrumb: [{ text: "Clinic", url: "" }],
    icon: "fa-building"
  },
  {
    path: "/management/employee",
    name: "Employees",
    component: EmployeeListIndex,
    exact: true,
    description: "List",
    breadcrumb: [{ text: "Employee", url: "" }],
    icon: "fa-user-md"
  },
  {
    path: "/management/employee/add",
    name: "Employees",
    component: AddEmployeeIndex,
    description: "",
    breadcrumb: [
      { text: "Employees", url: "/management/employee" },
      { text: "Add Employee", url: "" }
    ],
    icon: "fa-user-md"
  },
  {
    path: "/management/employee/edit",
    name: "Employees",
    component: EditEmployeeIndex,
    description: "",
    breadcrumb: [
      { text: "Employees", url: "/management/employee" },
      { text: "Edit Employee", url: "" }
    ],
    icon: "fa-user-md"
  },
  {
    path: "/management/advertisement",
    name: "Advertisement",
    component: AdvertisementListIndex,
    exact: true,
    description: "List",
    breadcrumb: [{ text: "Advertisement", url: "" }],
    icon: "fa-paper-plane"
  },
  {
    path: "/management/advertisement/add",
    name: "Advertisement",
    component: AddAdvertisementIndex,
    description: "",
    breadcrumb: [
      { text: "Advertisement", url: "/management/advertisement" },
      { text: "Add Advertisement", url: "" }
    ],
    icon: "fa-paper-plane"
  },
  {
    path: "/management/advertisement/edit",
    name: "Advertisement",
    component: EditAdvertisementIndex,
    description: "",
    breadcrumb: [
      { text: "Advertisement", url: "/management/advertisement" },
      { text: "Edit Advertisement", url: "" }
    ],
    icon: "fa-paper-plane"
  },
  {
    path: "/management/service",
    name: "Service",
    component: ServiceListIndex,
    exact: true,
    description: "List",
    breadcrumb: [{ text: "Service", url: "" }],
    icon: "fa-medkit"
  },
  {
    path: "/management/service/add",
    name: "Service",
    component: AddServiceIndex,
    description: "",
    breadcrumb: [
      { text: "Service", url: "/management/service" },
      { text: "Add Service", url: "" }
    ],
    icon: "fa-medkit"
  },
  {
    path: "/management/service/edit",
    name: "Service",
    component: EditServiceIndex,
    description: "",
    breadcrumb: [
      { text: "Service", url: "/management/service" },
      { text: "Edit Service", url: "" }
    ],
    icon: "fa-medkit"
  },
  {
    path: "/management/legal-content",
    name: "Legal Content",
    component: LegalContentIndex,
    description: "",
    breadcrumb: [{ text: "Legal Content", url: "" }],
    icon: "fa-legal"
  },
  {
    path: "/management/system-bussiness-logic",
    name: "Bussiness Logic",
    component: SystemBussinessLogicIndex,
    description: "System",
    breadcrumb: [{ text: "Bussiness Logic", url: "" }],
    icon: "fa-briefcase"
  },
  {
    path: "/management/working-plan",
    name: "Working Plan",
    component: WorkingPlanIndex,
    description: "",
    breadcrumb: [{ text: "Working Plan", url: "" }],
    icon: "fa-briefcase"
  },
  {
    path: "/management/profile",
    name: "My Profile",
    component: ProfileIndex,
    description: "",
    breadcrumb: [{ text: "My Profile", url: "" }],
    icon: "fa-user"
  },
  {
    path: "/management/general",
    name: "General",
    component: GeneralIndex,
    description: "System Setting",
    breadcrumb: [{ text: "System Setting", url: "" },{ text: "General", url: "" }],
    icon: "fa-gear"
  },
  {
    path: "/management/message/inbox",
    name: "Inbox",
    component: InboxIndex,
    description: "Message",
    breadcrumb: [{ text: "Message", url: "" },{ text: "Inbox", url: "" }],
    icon: "fa-inbox"
  },
  {
    path: "/management/message/compose",
    name: "Compose",
    component: ComposeIndex,
    description: "Message",
    breadcrumb: [{ text: "Message", url: "" },{ text: "Compose", url: "" }],
    icon: "fa-pencil-square-o"
  },
  {
    path: "/management/message/content",
    name: "Content",
    component: ContentIndex,
    description: "Message",
    breadcrumb: [{ text: "Message", url: "" },{ text: "Content", url: "" }],
    icon: "fa-file-text-o"
  },
];

export default routes;
