export const SAVE_ADVERTISEMENT_SUCCESS = "SAVE_ADVERTISEMENT";
export const SAVE_ADVERTISEMENT_FAILURE = "SAVE_ADVERTISEMENT_FAILURE";
export const UPDATE_ADVERTISEMENT_SUCCESS = "UPDATE_ADVERTISEMENT";
export const UPDATE_ADVERTISEMENT_FAILURE = "UPDATE_ADVERTISEMENT_FAILURE";
export const DEACTIVATE_ADVERTISEMENT_SUCCESS = "DEACTIVATE_ADVERTISEMENT";
export const DEACTIVATE_ADVERTISEMENT_FAILURE = "DEACTIVATE_ADVERTISEMENT_FAILURE";
// export const ACTIVATE_ADVERTISEMENT_SUCCESS = "ACTIVATE_ADVERTISEMENT";
// export const ACTIVATE_ADVERTISEMENT_FAILURE = "ACTIVATE_ADVERTISEMENT_FAILURE";
export const DEACTIVATE_ALL_ADVERTISEMENT_SUCCESS = "DEACTIVATE_ALL_ADVERTISEMENT";
export const DEACTIVATE_ALL_ADVERTISEMENT_FAILURE = "DEACTIVATE_ALL_ADVERTISEMENT_FAILURE";
// export const ACTIVATE_ALL_ADVERTISEMENT_SUCCESS = "ACTIVATE_ALL_ADVERTISEMENT";
// export const ACTIVATE_ALL_ADVERTISEMENT_FAILURE = "ACTIVATE_ALL_ADVERTISEMENT_FAILURE";
export const LIST_ADVERTISEMENT = "LIST_ADVERTISEMENT";
export const FETCH_ADVERTISEMENT = "FETCH_ADVERTISEMENT";
