import React from "react";

class PDFViewer extends React.Component {
  constructor(props) {
    super(props);
    this.viewerRef = React.createRef();
    this.backend = new props.backend();
  }

  componentDidMount() {
    this.element = this.viewerRef.current;
  }

  render() {
    const { src, type } = this.props;
    this.backend.init(src, type, this.element);
    return (
      <div
        ref={this.viewerRef}
        id="viewer"
        style={{ width: "100%", height: "100%" }}
      />
    );
  }
}

export default PDFViewer;
