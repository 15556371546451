import axios from "axios";
import history from "../../../routes/history";
import { API_URL } from "../../../config";
import {
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  VERIFY_RESET_PASSWORD_SUCCESS,
  VERIFY_RESET_PASSWORD_FAILURE,
  AUTH_USER
} from "./types";

export function authError(CONST, error) {
  return {
    type: CONST,
    payload: error
  };
}

export function forgotPassword(props) {
  return function(dispatch) {
    axios
      .post(`${API_URL}/auth/forgot-password`, props, {
        headers: {
          Authorization: "Bearer NoAuth",
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        if (response.data) {
          const status = response.data.Status;
          const message = response.data.Message;
          if (status === "Successed") {
            dispatch({ type: FORGOT_PASSWORD_SUCCESS });
            history.push({
              pathname: `/auth/signin`,
              state: { message: message }
            });
          } else {
            dispatch(authError(FORGOT_PASSWORD_FAILURE, message));
          }
        }
      })
      .catch(response => {
        dispatch(authError(FORGOT_PASSWORD_FAILURE, response.data.error));
      });
  };
}

export function resetPassword(props) {
  return function(dispatch) {
    axios
      .post(`${API_URL}/auth/reset-password`, props, {
        headers: {
          Authorization: "Bearer NoAuth",
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        if (response.data) {
          const status = response.data.Status;
          const message = response.data.Message;
          if (status === "Successed") {
            dispatch({ type: RESET_PASSWORD_SUCCESS });
            history.push({
              pathname: `/auth/signin`,
              state: { message: message }
            });
          } else {
            dispatch(authError(RESET_PASSWORD_FAILURE, message));
            history.push({
              pathname: `/auth/forgot-password`,
              state: { message: message }
            });
          }
        }
      })
      .catch(response => {
        dispatch(authError(RESET_PASSWORD_FAILURE, response.data.error));
      });
  };
}

export function verifyResetPassword(props) {
  return function(dispatch) {
    axios
      .post(`${API_URL}/reset-password/verify`, props)
      .then(() => {
        dispatch({ type: VERIFY_RESET_PASSWORD_SUCCESS });
      })
      .catch(response => {
        dispatch(authError(VERIFY_RESET_PASSWORD_FAILURE, response.data.error));
      });
  };
}

export function resetPasswordNew(props) {
  return function(dispatch) {
    axios
      .post(`${API_URL}/reset-password/new`, props)
      .then(response => {
        sessionStorage.setItem("user", JSON.stringify(response.data));

        dispatch({ type: AUTH_USER });

        history.push("/reduxauth/users");
      })
      .catch(response =>
        dispatch(authError(VERIFY_RESET_PASSWORD_FAILURE, response.data))
      );
  };
}
