import {
    FETCH_PLTR_FORM,
    LIST_PLTR_FORM,
    SAVE_PLTR_FORM_SUCCESS,
    SAVE_PLTR_FORM_FAILURE,
    UPDATE_PLTR_FORM_SUCCESS,
    UPDATE_PLTR_FORM_FAILURE,
    DEACTIVATE_PLTR_FORM_SUCCESS,
    DEACTIVATE_PLTR_FORM_FAILURE,
    DEACTIVATE_ALL_PLTR_FORM_SUCCESS,
    DEACTIVATE_ALL_PLTR_FORM_FAILURE
  } from "../../actions/management/pltr-form/types";
  
  export default function(state = {}, action) {
    switch (action.type) {
      case SAVE_PLTR_FORM_SUCCESS:
        return { ...state, success: { message: action.payload } };
      case SAVE_PLTR_FORM_FAILURE:
        return { ...state, error: { message: action.payload } };
      case UPDATE_PLTR_FORM_SUCCESS:
        return { ...state, success: { message: action.payload } };
      case UPDATE_PLTR_FORM_FAILURE:
        return { ...state, error: { message: action.payload } };
      case DEACTIVATE_PLTR_FORM_SUCCESS:
        return { ...state, success: { message: action.payload } };
      case DEACTIVATE_PLTR_FORM_FAILURE:
        return { ...state, error: { message: action.payload } };
      case DEACTIVATE_ALL_PLTR_FORM_SUCCESS:
        return { ...state, success: { message: action.payload } };
      case DEACTIVATE_ALL_PLTR_FORM_FAILURE:
        return { ...state, error: { message: action.payload } };
      case LIST_PLTR_FORM:
        return { ...state, data: action.payload };
      case FETCH_PLTR_FORM:
        return { ...state, item: action.payload };
      default:
        return state;
    }
  }
  