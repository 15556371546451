import React, { Component } from "react";

class Banner extends Component {
  render() {
    const { hasChildren } = this.props;
    return (
      <section
        className={
          "section " +
          (hasChildren === undefined || hasChildren ? "section-lg section-hero" : " pb-0") +
          " section-shaped"
        }
      >
        <div className="shape shape-style-1 shape-primary" />
        {/* <div className="container shape-container d-flex align-items-center mt-1"> */}
          {/* <div className="row row-grid align-items-center"> */}
            {this.props.children}
          {/* </div> */}
        {/* </div> */}
      </section>
    );
  }
}

export default Banner;
