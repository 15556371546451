import React, { Component } from "react";
import Slider from "react-slick";
import WOW from "wowjs"; 

const SETTINGS = {
  autoplay: true,
  dots: false,
  fade: true,
  infinite: true,
  speed: 700,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  lazyLoad: true
};

class ContactSection extends Component {
  constructor() {
    super();
    this.scrolled = false;
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {
    if (!this.scrolled) {
      this.scrolled = true;
      new WOW.WOW({
        live: false
      }).init();
    }
  };

  render() {
    const { data } = this.props;
    return (
      <div className="wow fadeIn" data-wow-delay="0.4s">
        <Slider {...SETTINGS}>
          {data.map((item, index) => (
            <div
              key={index}
              className={
                item.bgColor +
                " text-uppercase text-center justify-content-center"
              }
            >
              {item.image ? (
                <img src={item.image} width="100%" alt="Testimonial" />
              ) : null}
              {item.message ? (
                <div className="p-4">
                  <h6
                    className={
                      "font-weight-light " +
                      (item.color ? item.color : "text-white")
                    }
                  >
                    {item.message}
                  </h6>
                  <h2
                    className={
                      "mb-0 " +
                      (item.color ? item.color : "text-white")
                    }
                  >
                    <i className="fa fa-phone mr-2"></i>{item.info}
                  </h2>
                  {item.description ? (
                    <h6
                      className={
                        "mb-0 " +
                        (item.color ? item.color : "text-white")
                      }
                    >
                      {item.description}
                    </h6>
                  ) : null}
                </div>
              ) : null}
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

export default ContactSection;
