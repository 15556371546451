import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ScrollIntoView from "../../../components/scroll-to-view";
import Header from "./header";
import SideBar from "./side-bar";
import Breadcrumb from "./breadcrumb";
import Footer from "./footer";
import "../../../assets/css/management-style.css";
import routes from "../../../routes/management-routes";
import { connect } from "react-redux";
import * as actions from "../../../actions/management/message";

class ManagementLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarMini: false
    };
  }

  async componentDidMount() {
    // try {
    //   this.props.countNewMessage();
    //   setInterval(async () => {
    //     this.props.countNewMessage();
    //   }, 600000);// 10 minutes
    // } catch (e) {}
  }

  toggle = () => {
    this.setState({
      sidebarMini: !this.state.sidebarMini
    });
  };

  renderRouter = () => {
    const { authenticated } = this.props;
    return authenticated ? (
      <Switch>
        {routes.map((route, index) => {
          return route.component ? (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              name={route.name}
              render={props => {
                return (
                  <div>
                    <Breadcrumb
                      breadcrumb={route.breadcrumb}
                      name={route.name}
                      description={route.description}
                      icon={route.icon}
                    />
                    <route.component {...props} />
                  </div>
                );
              }}
            />
          ) : null;
        })}
        <Redirect from="/management" to="/management/appointment/calendar" />
      </Switch>
    ) : null;
  };

  render() {
    return (
      <div>
        <ScrollIntoView>
          <div
            className={
              this.state.sidebarMini ? "sidebar-mini sidebar-collapse" : null
            }
          >
            <Header toggle={this.toggle} />
            <SideBar />
            <div
              className="content-wrapper"
            >
              {this.renderRouter()}
            </div>
            <Footer />
          </div>
        </ScrollIntoView>
      </div>
    );
  }
}

function mapStateToProps(state) {
  if (sessionStorage.getItem("user") === null) {
    state.auth.authenticated = false;
  }
  return {
    authenticated: state.auth.authenticated,
    data: state.auth.data,
    countNewMessage: state.message.countNewMessage
  };
}

export default connect(
  mapStateToProps,
  actions
)(ManagementLayout);
