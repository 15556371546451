import {
  FETCH_APPOINTMENT,
  SAVE_APPOINTMENT_SUCCESS,
  SAVE_APPOINTMENT_FAILURE,
  UPDATE_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_FAILURE,
  SAVE_UNAVAILABLE_SUCCESS,
  SAVE_UNAVAILABLE_FAILURE,
  UPDATE_UNAVAILABLE_SUCCESS,
  UPDATE_UNAVAILABLE_FAILURE,
  CANCEL_APPOINTMENT_SUCCESS,
  CANCEL_APPOINTMENT_FAILURE,
  DELETE_APPOINTMENT_SUCCESS,
  DELETE_APPOINTMENT_FAILURE,
  GET_APPOINTMENT,
  LIST_FILTER,
  FETCH_UNAVAILABLE,
  LIST_AVAILABLE_HOUR,
  LIST_APPOINTMENT_HISTORY
} from "../../actions/management/appointment/types";

export default function(state = {}, action) {
  switch (action.type) {
    case SAVE_APPOINTMENT_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case SAVE_APPOINTMENT_FAILURE:
      return { ...state, error: { message: action.payload } };
    case UPDATE_APPOINTMENT_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case UPDATE_APPOINTMENT_FAILURE:
      return { ...state, error: { message: action.payload } };
    case SAVE_UNAVAILABLE_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case SAVE_UNAVAILABLE_FAILURE:
      return { ...state, error: { message: action.payload } };
    case UPDATE_UNAVAILABLE_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case UPDATE_UNAVAILABLE_FAILURE:
      return { ...state, error: { message: action.payload } };
    case CANCEL_APPOINTMENT_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case CANCEL_APPOINTMENT_FAILURE:
      return { ...state, error: { message: action.payload } };
    case DELETE_APPOINTMENT_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case DELETE_APPOINTMENT_FAILURE:
      return { ...state, error: { message: action.payload } };
    case FETCH_APPOINTMENT:
      return { ...state, initialValues: action.payload };
    case LIST_FILTER:
      return { ...state, filter: action.payload };
    case GET_APPOINTMENT:
      return { ...state, calendar: action.payload };
    case FETCH_UNAVAILABLE:
      return { ...state, initialValues: action.payload };
    case LIST_AVAILABLE_HOUR:
      return { ...state, availableHours: action.payload };
    case LIST_APPOINTMENT_HISTORY:
      return { ...state, appointmentHistory: action.payload };
    default:
      return state;
  }
}
