import {
  GET_EMPLOYEE_YEAR_DATA,
  GET_EMPLOYEE_MONTH_DATA,
  GET_EMPLOYEE_WEEK_DATA,
  GET_PATIENT_YEAR_DATA,
  GET_PATIENT_MONTH_DATA
} from "../../actions/management/dashboard/types";

export default function(state = {}, action) {
  switch (action.type) {
    case GET_EMPLOYEE_YEAR_DATA:
      return { ...state, yearData: action.payload };
    case GET_EMPLOYEE_MONTH_DATA:
      return { ...state, monthData: action.payload };
    case GET_EMPLOYEE_WEEK_DATA:
      return { ...state, weekData: action.payload };
    case GET_PATIENT_YEAR_DATA:
      return { ...state, yearData: action.payload };
    case GET_PATIENT_MONTH_DATA:
      return { ...state, monthData: action.payload };
    default:
      return state;
  }
}
