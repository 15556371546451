export const SAVE_PATIENT_SUCCESS = "SAVE_PATIENT";
export const SAVE_PATIENT_FAILURE = "SAVE_PATIENT_FAILURE";
export const UPDATE_PATIENT_SUCCESS = "UPDATE_PATIENT";
export const UPDATE_PATIENT_FAILURE = "UPDATE_PATIENT_FAILURE";
export const DEACTIVATE_PATIENT_SUCCESS = "DEACTIVATE_PATIENT";
export const DEACTIVATE_PATIENT_FAILURE = "DEACTIVATE_PATIENT_FAILURE";
export const ACTIVATE_PATIENT_SUCCESS = "ACTIVATE_PATIENT";
export const ACTIVATE_PATIENT_FAILURE = "ACTIVATE_PATIENT_FAILURE";
export const DEACTIVATE_ALL_PATIENT_SUCCESS = "DEACTIVATE_ALL_PATIENT";
export const DEACTIVATE_ALL_PATIENT_FAILURE = "DEACTIVATE_ALL_PATIENT_FAILURE";
export const ACTIVATE_ALL_PATIENT_SUCCESS = "ACTIVATE_ALL_PATIENT";
export const ACTIVATE_ALL_PATIENT_FAILURE = "ACTIVATE_ALL_PATIENT_FAILURE";
export const LIST_PATIENT = "LIST_PATIENT";
export const FETCH_PATIENT = "FETCH_PATIENT";
export const UPDATE_PHOTO_SUCCESS = "UPDATE_PHOTO_SUCCESS";
export const UPDATE_PHOTO_FAILURE = "UPDATE_PHOTO_FAILURE";
export const FETCH_PHOTO = "FETCH_PHOTO";
export const SEND_RESET_PASSWORD_LINK_SUCCESS = "SEND_RESET_PASSWORD_LINK_SUCCESS";
export const SEND_RESET_PASSWORD_LINK_FAILURE = "SEND_RESET_PASSWORD_LINK_FAILURE";
