import React, { Component } from "react";
import WOW from "wowjs";

class Certification extends Component {
  constructor() {
    super();
    this.scrolled = false;
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = e => {
    if (!this.scrolled) {
      this.scrolled = true;
      new WOW.WOW({
        live: false
      }).init();
    }
  };

  renderCertification() {
    const { data } = this.props;
    if (data == null) return null;
    const certicationElement = data.map((item, index) => (
      <div
        key={index}
        className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-7 m-2 icon wow zoomIn"
      >
        <a href={item.href} aria-label={item.text} target={item.target}>
          {/* <img src={item.image} alt={item.text} width="100%" /> */}
          <div id={item.id} alt={item.text}/>
        </a>
      </div>
    ));
    return certicationElement;
  }

  render() {
    const certicationElement = this.renderCertification();
    return (
      <div className="container">
        <div
          className="row row-grid text-center justify-content-center align-items-center"
          id="certification"
        >
          {certicationElement}
        </div>
      </div>
    );
  }
}

export default Certification;
