import React, { Component } from "react";
import WOW from "wowjs";

class InfoSection extends Component {
  constructor() {
    super();
    this.scrolled = false;
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {
    if (!this.scrolled) {
      this.scrolled = true;
      new WOW.WOW({
        live: false
      }).init();
    }
  };

  render() {
    const { title, description, itemImage, itemLogo, className } = this.props;
    return title === "" && description === "" ? null : (
      <section
        className={
          "section pt-lg-0 section-contact-us mb-1 " +
          (itemImage !== "" || itemImage === "no-space" ? "" : "mb-lg-5")
        }
      >
        <div
          className={
            "row justify-content-center ml-0 mr-0 " +
            (className ? className : "mt--400") +
            " " +
            (itemImage !== "" || itemImage === "no-space"
              ? ""
              : "mb-lg-5 pb-lg-5")
          }
        >
          <div className="container row" style={{ zIndex: 1 }}>
            {itemImage !== "" && itemImage !== "no-space" ? (
              <div className="col-lg-4 col-md-5 col-9 wow fadeInLeft">
                <div className="view overlay rounded z-depth-2 mb-lg-0 mb-4">
                  <img className="img-fluid" src={itemImage} alt={title} />
                </div>
              </div>
            ) : null}

            <div
              id="technologyInfo"
              className={
                (itemImage !== "" && itemImage !== "no-space"
                  ? "col-lg-8 col-md-7 "
                  : "") + "col-12 pt-0"
              }
            >
              {itemLogo ? (
                <p
                  className={
                    itemImage !== "" && itemImage !== "no-space"
                      ? "wow fadeIn"
                      : "wow fadeInUp pt-5 mt-5 pt-md-4 mt-md-4 pt-lg-0 mt-lg-0"
                  }
                >
                  <img
                    src={itemLogo}
                    className="img-fluid"
                    alt={title}
                    style={{ width: "130px" }}
                  />
                </p>
              ) : null}

              <h3
                className={
                  itemImage !== "" && itemImage !== "no-space"
                    ? "wow fadeIn"
                    : "wow fadeIn pt-4 mt-4 pt-md-0 mt-md-0"
                }
                data-wow-delay="0.2s"
              >
                {title}
              </h3>
              <p
                className="wow fadeIn"
                data-wow-delay="0.3s"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default InfoSection;
