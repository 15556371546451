import {
  FETCH_ATTACHMENT,
  // SAVE_ATTACHMENT_SUCCESS,
  // SAVE_ATTACHMENT_FAILURE,
  LIST_ATTACHMENT
} from "../../actions/management/attachment/types";

export default function(state = {}, action) {
  switch (action.type) {
    // case SAVE_ATTACHMENT_SUCCESS:
    //   return { ...state, success: { message: action.payload } };
    // case SAVE_ATTACHMENT_FAILURE:
    //   return { ...state, error: { message: action.payload } };
    case FETCH_ATTACHMENT:
      return { ...state, file: action.payload };
    case LIST_ATTACHMENT:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}
