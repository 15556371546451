export default {
  items: [
    {
      name: "MAIN NAVIGATION",
      header: true,
      roles: [],
    },
    {
      name: "Dashboard",
      url: "/management/dashboard",
      icon: "fa-dashboard",
      roles: []
    },
    {
      name: "Patient",
      url: "/management/patient",
      icon: "fa-users",
      roles: [1,2,3]
    },
    {
      name: "Appointment",
      url: "/management/appointment/calendar",
      icon: "fa-calendar",
      roles: []
    },
    {
      name: "Documents",
      url: "/management/document",
      icon: "fa-folder",
      roles: [4]
    },
    // {
    //   name: "Forms",
    //   url: "/management/patient-form",
    //   icon: "fa-list-alt",
    //   roles: [4]
    // },
    {
      name: "CLINIC MANAGEMENT",
      header: true,
      roles: [1,2]
    },
    {
      name: "Clinic",
      url: "/management/clinic",
      icon: "fa-building",
      roles: [1,2]
    },
    {
      name: "Service",
      url: "/management/service",
      icon: "fa-medkit",
      roles: [1,2,3]
    },
    {
      name: "Employee",
      url: "/management/employee",
      icon: "fa-user-md",
      roles: [1,2]
    },
    // {
    //   name: "Advertisement",
    //   url: "/management/advertisement",
    //   icon: "fa-paper-plane",
    //   roles: [1,2]
    // },
    {
      name: "SYSTEM SETTING",
      header: true,
      roles: [1,2]
    },
    // {
    //   name: "General",
    //   url: "/management/general",
    //   icon: "fa-gear",
    //   roles: [1,2]
    // },
    {
      name: "Business Logic",
      url: "/management/system-bussiness-logic",
      icon: "fa-briefcase",
      roles: [1,2]
    },
    {
      name: "Legal Content",
      url: "/management/legal-content",
      icon: "fa-legal",
      roles: [1,2]
    },
    {
      name: "ACCOUNT SETTING",
      header: true,
      roles: [1,2,3]
    },
    // {
    //   name: "My Profile",
    //   url: "/management/profile",
    //   icon: "fa-user",
    //   roles: []
    // },
    {
      name: "Working Plan",
      url: "/management/working-plan",
      icon: "fa-briefcase",
      roles: [1,2,3]
    },
  ]
};
