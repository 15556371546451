import {
    FETCH_FOTONA_FORM,
    LIST_FOTONA_FORM,
    SAVE_FOTONA_FORM_SUCCESS,
    SAVE_FOTONA_FORM_FAILURE,
    UPDATE_FOTONA_FORM_SUCCESS,
    UPDATE_FOTONA_FORM_FAILURE,
    DEACTIVATE_FOTONA_FORM_SUCCESS,
    DEACTIVATE_FOTONA_FORM_FAILURE,
    DEACTIVATE_ALL_FOTONA_FORM_SUCCESS,
    DEACTIVATE_ALL_FOTONA_FORM_FAILURE
  } from "../../actions/management/fotona-form/types";
  
  export default function(state = {}, action) {
    switch (action.type) {
      case SAVE_FOTONA_FORM_SUCCESS:
        return { ...state, success: { message: action.payload } };
      case SAVE_FOTONA_FORM_FAILURE:
        return { ...state, error: { message: action.payload } };
      case UPDATE_FOTONA_FORM_SUCCESS:
        return { ...state, success: { message: action.payload } };
      case UPDATE_FOTONA_FORM_FAILURE:
        return { ...state, error: { message: action.payload } };
      case DEACTIVATE_FOTONA_FORM_SUCCESS:
        return { ...state, success: { message: action.payload } };
      case DEACTIVATE_FOTONA_FORM_FAILURE:
        return { ...state, error: { message: action.payload } };
      case DEACTIVATE_ALL_FOTONA_FORM_SUCCESS:
        return { ...state, success: { message: action.payload } };
      case DEACTIVATE_ALL_FOTONA_FORM_FAILURE:
        return { ...state, error: { message: action.payload } };
      case LIST_FOTONA_FORM:
        return { ...state, data: action.payload };
      case FETCH_FOTONA_FORM:
        return { ...state, item: action.payload };
      default:
        return state;
    }
  }
  