import React, { Component } from "react";
import Slider from "react-slick";
import { Modal, ModalBody } from "reactstrap";
import PDFViewer from "../../../../components/pdf-viewer";
import PDFJSBackend from "../../../../components/pdf-viewer/pdfjs";
import WOW from "wowjs"; 

const SETTINGS = {
  autoplay: true,
  dots: false,
  fade: true,
  infinite: true,
  speed: 700,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  lazyLoad: true
};

class RackCardSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedIndex: 0
    };
    this.scrolled = false;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {
    if (!this.scrolled) {
      this.scrolled = true;
      new WOW.WOW({
        live: false
      }).init();
    }
  };

  toggle = index => {
    this.setState({
      modal: !this.state.modal
    });
    if (index) {
      this.setState({
        selectedIndex: index
      });
    }
  };

  render() {
    const { data, title } = this.props;
    return data.length > 0 ? (
      <div className="wow fadeIn" data-wow-delay="0.2s">
        {data[this.state.selectedIndex].pdf ? (
          <Modal
            isOpen={this.state.modal}
            // size="xl"
            toggle={() => this.toggle(null)}
            className="modal-xl"
          >
            <ModalBody className="p-0 modal-xl">
                <PDFViewer
                  backend={PDFJSBackend}
                  src={data[this.state.selectedIndex].pdf}
                />
            </ModalBody>
          </Modal>
        ) : null}

        {title ? <p className="lead">{title}</p> : null}

        <Slider {...SETTINGS}>
          {data.map((item, index) => (
            <div key={index} onClick={() => this.toggle(index)}>
              <img
                src={item.image}
                width="100%"
                alt="Splendor Medical Aesthetics"
                style={{ cursor: item.pdf ? "pointer" : "default" }}
              />
              {item.pdf ? (
                <p style={{ cursor: "pointer" }} className="small">Click HERE to learn more!</p>
              ) : null}
            </div>
          ))}
        </Slider>
      </div>
    ) : null;
  }
}

export default RackCardSection;
