import React, { Component } from "react";
import LayoutIndex from "../../layout";

class PriceIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null
    };
    const link = "/static/data/sites/pages/price/index.json";
    fetch(link)
      .then(r => r.json())
      .then(data => {
        this.setState({
          data
        });
      });
  }

  render() {
    return (
      <LayoutIndex
        data={this.state.data}
        hideBanner={true}
        showContactForm={true}
      />
    );
  }
}

export default PriceIndex;
