import React, { Component } from "react";
import FooterLink from "./footer-link";
import FooterCopyright from "./footer-copyright";

class Footer extends Component {
  render() {
    const { triangleStyle, hideLinks } = this.props;
    return (
      <footer
        className={
          "footer " +
          (triangleStyle === undefined || triangleStyle ? "has-cards" : "")
        }
      >
        <div
          className="container"
          style={{
            paddingTop: triangleStyle === undefined || triangleStyle ? 150 : 0
          }}
        >
          {hideLinks ? null : <FooterLink />}
          <FooterCopyright />
        </div>
      </footer>
    );
  }
}

export default Footer;
