import React, { Component } from "react";
import { Collapse, CardBody, Card } from "reactstrap";
import WOW from "wowjs";

class CustomSection extends Component {
  constructor(props) {
    super(props);
    let collapse = [];
    const { data } = this.props;
    data.map((item, index) => collapse.push(item.collapse ? true : false));
    this.state = { collapse };
    this.scrolled = false;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {
    if (!this.scrolled) {
      this.scrolled = true;
      new WOW.WOW({
        live: false
      }).init();
    }
  };

  toggle = index => {
    let collapse = this.state.collapse;
    collapse[index] = !collapse[index];
    this.setState({ collapse });
  };

  render() {
    const { data, title, html } = this.props;
    return (
      <div>
        {title ? (
          <p className="lead pt-0 wow fadeIn" data-wow-delay="0.2s">
            {title}
          </p>
        ) : null}
        {html ? (
          <p
            className="wow fadeIn"
            data-wow-delay="0.3s"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        ) : null}
        {data.length > 0
          ? data.map((item, index) => (
              <div key={index}>
                <div
                  className="alert alert-secondary alert-missible alert-missible fade show wow fadeIn"
                  data-wow-delay={"0." + index + "s"}
                  role="alert"
                  onClick={() => this.toggle(index)}
                >
                  <div className="container m-0 p-0">
                    <div className="row m-0 p-0">
                      <div className="col-1 m-0 p-0">
                        <span className="alert-inner--icon text-muted">
                          <i
                            className={
                              "fa " +
                              (item.icon ? item.icon : "fa-question-circle-o")
                            }
                          />
                        </span>
                      </div>
                      <div className="col-10 m-0 p-0 pt-1">
                        <span className="alert-inner--text">{item.text}</span>
                      </div>
                      <div className="col-1 m-0 p-0">
                        <button
                          type="button"
                          className="close display-4 pt-1"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">
                            {this.state.collapse[index] ? "-" : "+"}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <Collapse isOpen={this.state.collapse[index]}>
                  <Card className="border-0">
                    <CardBody className="pt-1">
                      <div
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            ))
          : null}
      </div>
    );
  }
}

export default CustomSection;
