import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { CLINIC } from "../../../data/app.json";
import history from "../../../routes/history";
import routes from "../../../routes/site-routes";

class SiteLayout extends Component {
  componentDidMount() {
    this.unlisten = history.listen((location, action) => {
      let path = location.hash.substr(1);
      let pathname = location.pathname;
      let title = CLINIC;
      for (let i in routes) {
        let routePath = routes[i].path;
        if (routePath === path || routePath === pathname) {
          let name = routes[i].name;
          title = title + (name === "" ? "" : " - ") + name;
          break;
        }
      }
      document.title = title;
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return (
      <Switch>
        {routes.map((route, index) => {
          return route.component ? (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              name={route.name}
              render={props => <route.component {...props} />}
            />
          ) : null;
        })}
        <Redirect to="/error/404" />
      </Switch>
    );
  }
}

export default SiteLayout;
