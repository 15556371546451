import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ScrollIntoView from "../../../components/scroll-to-view";
import Header from "../../../components/sites/header";
import Footer from "../../../components/sites/footer";
import routes from "../../../routes/auth-routes";

class AuthLayout extends Component {
  
  render() {
    return (
      <ScrollIntoView>
        <Header />
        <Switch>
          {routes.map((route, index) => {
            return route.component ? (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={props => <route.component {...props} />}
              />
            ) : null;
          })}
          <Redirect from="/auth" to="/auth/signin" />
          {/* <Redirect to="/error/404" /> */}
        </Switch>
        <Footer triangleStyle={false} hideLinks={false} />
      </ScrollIntoView>
    );
  }
}

export default AuthLayout;
