import React, { Component } from "react";
import Slider from "react-slick";
import WOW from "wowjs";
import Modal from "../../../../components/modal";

class BeforeAfterSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selectedDataIndex: 0,
      selectedImageIndex: 0
    };
    this.scrolled = false;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {
    if (!this.scrolled) {
      this.scrolled = true;
      new WOW.WOW({
        live: false
      }).init();
    }
  };

  handleToggleModal = (dataIndex, imageIndex) => {
    this.setState({
      showModal: !this.state.showModal,
      selectedDataIndex: dataIndex,
      selectedImageIndex: imageIndex
    });
  };

  renderSlider(images, title, dataIndex) {
    const SETTINGS = {
      customPaging: function(i) {
        return <img src={images[i]} width="100%" alt="" />;
      },
      dots: true,
      dotsClass: "slick-dots slick-thumb",
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      lazyLoad: true
    };
    return images.length === 0 ? null : (
      <div className="mb-5 wow fadeIn" data-wow-delay="0.2s" key={dataIndex}>
        <p className="lead pt-3" data-wow-delay="0.4s">
          {title}
        </p>
        <Slider {...SETTINGS}>
          {images.map((image, index) => (
            <div key={index} className="btn p-0" onClick={() => this.handleToggleModal(dataIndex, index)}>
              <img src={image} width="100%" alt="Before And After" />
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  render() {
    const { data, title } = this.props;
    const { showModal } = this.state;

    return data.length === 0 ? null : (
      <div className="mb-5">
        <p className="lead pt-3 wow fadeIn" data-wow-delay="0.2s">
          {title}
        </p>
        {data.map((row, index) =>
          this.renderSlider(row.images, row.title, index)
        )}
        {showModal && (
          <Modal onCloseRequest={this.handleToggleModal}>
            <img
              src={
                data[this.state.selectedDataIndex].images[
                  this.state.selectedImageIndex
                ]
              }
              width="100%"
              height="100%"
              alt="Before And After"
            />
          </Modal>
        )}
      </div>
    );
  }
}

export default BeforeAfterSection;
