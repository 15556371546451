import React, { Component } from "react";
import ScrollIntoView from "../../../components/scroll-to-view";
import {
  HeaderSection,
  InfoSection,
  BeforeAfterSection,
  DetailSection,
  ExperienceSection,
  RackCardSection,
  InstructionSection,
  TestimonialsSection,
  TreatmentSection,
  TreatmentAreasSection,
  TreatmentTimeSection,
  FAQSection,
  CustomSection,
  SubMenuSection,
  ContactSection
} from "./sections";
import Contact from "../../../components/sites/contact";
import Footer from "../../../components/sites/footer";

class LayoutIndex extends Component {
  render() {
    const { data, column, hideBanner, showContactForm } = this.props;
    if (data === null) {
      return null;
    }
    return (
      <ScrollIntoView>
        <HeaderSection hideBanner={hideBanner} />
        <InfoSection
          title={data.info.title}
          description={data.info.description}
          itemImage={data.info.itemImage}
          itemLogo={data.info.itemLogo}
          className={data.info.className}
        />
        <div className="container mb-5 mt-5">
          <div className="row">
            <div
              className={
                column === 1
                  ? "col-12"
                  : "col-md-8 border-right mb-5 mb-md-0"
              }
            >
              <SubMenuSection
                className="d-block d-md-none"
                vertical={false}
                title={data.menu.title}
                items={data.menu.items}
              />
              <DetailSection
                title={data.detail.title}
                image={data.detail.image}
                videoUrl={data.detail.videoUrl}
                text={data.detail.text}
              />
              <TreatmentSection
                title={data.treatment.title}
                videoUrl={data.treatment.videoUrl}
                subTitle={data.treatment.subTitle}
                images={data.treatment.images}
              />
              <TreatmentAreasSection
                data={data.treatmentAreas.data}
                title={data.treatmentAreas.title}
              />
              <CustomSection
                data={data.custom.data}
                title={data.custom.title}
                html={data.custom.html}
              />
              <ExperienceSection
                data={data.experience.data}
                title={data.experience.title}
              />
              <BeforeAfterSection
                data={data.beforeafter.data}
                title={data.beforeafter.title}
              />
              <div className="d-none d-sm-none d-md-block">
                <FAQSection data={data.faqs.data} title={data.faqs.title} />
              </div>
            </div>
            <div className="col-md-4">
              <SubMenuSection
                className="d-none d-md-block"
                vertical={true}
                title={data.menu.title}
                items={data.menu.items}
              />
              <TreatmentTimeSection
                data={data.treatmentTime.data}
                bgColor={data.treatmentTime.bgColor}
              />
              <InstructionSection
                steps={data.instruction.steps}
                title={data.instruction.title}
              />
              <RackCardSection
                data={data.rackcard.data}
                title={data.rackcard.title}
              />
              <TestimonialsSection
                data={data.testimonials.data}
                title={data.testimonials.title}
              />
              {showContactForm ? (
                <Contact
                  sideContainer={true}
                  btnClassName={data.contact.btnClassName}
                />
              ) : null}

              <ContactSection data={data.contact.data} />
            </div>
          </div>
          <div className="container">
            <div className="row d-block d-md-none">
              {/* <div
              className={
                column === 1
                  ? "col-12"
                  : "col-md-8 border-right mb-5 mb-md-0"
              }
            > */}
              <FAQSection data={data.faqs.data} title={data.faqs.title} />
              {/* </div> */}
              {/* <div className="col-md-4" /> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row" />
        </div>
        <Footer triangleStyle={false} />
      </ScrollIntoView>
    );
  }
}

export default LayoutIndex;
