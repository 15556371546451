export const SAVE_EMPLOYEE_SUCCESS = "SAVE_EMPLOYEE";
export const SAVE_EMPLOYEE_FAILURE = "SAVE_EMPLOYEE_FAILURE";
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE";
export const UPDATE_EMPLOYEE_FAILURE = "UPDATE_EMPLOYEE_FAILURE";
export const DEACTIVATE_EMPLOYEE_SUCCESS = "DEACTIVATE_EMPLOYEE";
export const DEACTIVATE_EMPLOYEE_FAILURE = "DEACTIVATE_EMPLOYEE_FAILURE";
export const ACTIVATE_EMPLOYEE_SUCCESS = "ACTIVATE_EMPLOYEE";
export const ACTIVATE_EMPLOYEE_FAILURE = "ACTIVATE_EMPLOYEE_FAILURE";
export const DEACTIVATE_ALL_EMPLOYEE_SUCCESS = "DEACTIVATE_ALL_EMPLOYEE";
export const DEACTIVATE_ALL_EMPLOYEE_FAILURE = "DEACTIVATE_ALL_EMPLOYEE_FAILURE";
export const ACTIVATE_ALL_EMPLOYEE_SUCCESS = "ACTIVATE_ALL_EMPLOYEE";
export const ACTIVATE_ALL_EMPLOYEE_FAILURE = "ACTIVATE_ALL_EMPLOYEE_FAILURE";
export const LIST_EMPLOYEE = "LIST_EMPLOYEE";
export const FETCH_EMPLOYEE = "FETCH_EMPLOYEE";
export const LIST_SERVICE_PROVIDER = "LIST_SERVICE_PROVIDER";
export const LIST_SERVICE_MAPPING = "LIST_SERVICE_MAPPING";
export const LIST_PROVIDER = "LIST_PROVIDER";
