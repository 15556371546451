import React, { Component } from "react";
import WOW from 'wowjs';
import TeamStyle from "../../../../assets/js/views/sites/home/sections/team-style";

class TeamSection extends Component {
  constructor() {
    super();
    this.scrolled = false;
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {
    if (!this.scrolled) {
      this.scrolled = true;
      new WOW.WOW({
        live: false
      }).init();
    }
  };

  renderTeam() {
    const { data } = this.props;
    if (data == null) return null;
    const teamElement = data.map((item, index) => (
      <div key={index} className="col-md-6 col-lg-3 mb-5 mb-lg-0 wow zoomIn" data-wow-delay="0.3s">
        <div className="px-4">
          <img
            src={item.image}
            className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
            style={TeamStyle.width200}
            alt={item.name}
          />
          <div className="pt-4 text-center wow fadeIn" data-wow-delay="0.3s">
            <h5 className="title">
              <span className="d-block mb-1">{item.name}</span>
              <small className="h6 text-muted">{item.role}</small>
            </h5>
          </div>
        </div>
      </div>
    ));
    return teamElement;
  }

  render() {
    return (
      <section className="section section-lg">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-8">
              <h2 className="display-3 wow fadeIn" data-wow-delay="0.2s">OUR TEAM</h2>
              <p className="lead text-muted wow fadeIn" data-wow-delay="0.2s">
                Dr. Trieu and team will work to understand your unique needs and
                strive to provide you with the most personalized care possible
                to address your areas of concern.
              </p>
            </div>
          </div>
          {/* <div className="row">{this.renderTeam()}</div> */}
        </div>
      </section>
    );
  }
}

export default TeamSection;
