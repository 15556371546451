import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ScrollIntoView from "../../components/scroll-to-view";
import routes from "../../routes/error-routes";

class ErrorLayout extends Component {
  render() {
    return (
      <ScrollIntoView>
        <Switch>
          {routes.map((route, index) => {
            return route.component ? (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={props => <route.component {...props} />}
              />
            ) : null;
          })}
          <Redirect to="/error/404" />
        </Switch>
      </ScrollIntoView>
    );
  }
}

export default ErrorLayout;
