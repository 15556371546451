import React, { Component } from "react";
import Header from "../../../../components/sites/header";
import { CLINIC } from "../../../../data/app.json";
import WOW from 'wowjs';

const ADVANTAGES_BACKGROUND_IMAGE = "/static/media/sites/home/advantages/advantages-background.jpg";
const WHITE_LOGO_IMAGE = "/static/media/theme/white.png";

class HeaderSection extends Component {
  constructor() {
    super();
    this.scrolled = false;
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {
    if (!this.scrolled) {
      this.scrolled = true;
      new WOW.WOW({
        live: false
      }).init();
    }
  };

  render() {
    return (
      <Header background={ADVANTAGES_BACKGROUND_IMAGE}>
        <div className="container d-flex justify-content-center">
          <div className="col-lg-8 pl-4 pr-4">
            <p className="wow fadeIn">
              <img
                src={WHITE_LOGO_IMAGE}
                style={{ width: 200 }}
                className="img-fluid"
                alt={CLINIC}
              />
            </p>
            <p className="text-white wow fadeIn" data-wow-delay="0.2s">
              Thank you for having taken your time to provide us with your
              valuable feedback relating to your stay with us. Your helpful
              comments are much appreciated,and your feedback will help us to
              continually improve the standard of facilities and services
              offered to all our guests.
            </p>
          </div>
        </div>
      </Header>
    );
  }
}

export default HeaderSection;
