import React from "react";
import Loadable from "react-loadable";

import AuthLayout from "../views/management/auth/auth-layout";

function Loading() {
  return <div>Loading...</div>;
}

const SignInIndex = Loadable({
  loader: () => import("../views/management/auth/signin"),
  loading: Loading
});

const SignInAsIndex = Loadable({
  loader: () => import("../views/management/auth/signin-as"),
  loading: Loading
});

const SignUpIndex = Loadable({
  loader: () => import("../views/management/auth/signup"),
  loading: Loading
});

const ForgotPasswordIndex = Loadable({
  loader: () => import("../views/management/auth/forgot-password"),
  loading: Loading
});

const ResetPasswordIndex = Loadable({
  loader: () => import("../views/management/auth/reset-password"),
  loading: Loading
});

const AccountActivationIndex = Loadable({
  loader: () => import("../views/management/auth/account-activation"),
  loading: Loading
});

const LegalContentIndex = Loadable({
  loader: () => import("../views/management/auth/legal-content"),
  loading: Loading
});

const routes = [
  { path: "/auth", name: "Auth Layout", component: AuthLayout, exact: true },
  { path: "/auth/signin", name: "Sign In", component: SignInIndex },
  { path: "/auth/signin-as", name: "Sign In As", component: SignInAsIndex },
  {
    path: "/auth/signup",
    name: "Sign Up",
    component: SignUpIndex,
    exact: true
  },
  {
    path: "/auth/forgot-password",
    name: "Forgot Password",
    component: ForgotPasswordIndex
  },
  {
    path: "/auth/reset-password/:token",
    name: "Reset Password",
    component: ResetPasswordIndex
  },
  {
    path: "/auth/account-activation/:token",
    name: "Activate account",
    component: AccountActivationIndex
  },
  {
    path: "/auth/legal-content",
    name: "Legal Content",
    component: LegalContentIndex,
  },
];

export default routes;
