export const SAVE_FOTONA_FORM_SUCCESS = "SAVE_FOTONA_FORM";
export const SAVE_FOTONA_FORM_FAILURE = "SAVE_FOTONA_FORM_FAILURE";
export const UPDATE_FOTONA_FORM_SUCCESS = "UPDATE_FOTONA_FORM";
export const UPDATE_FOTONA_FORM_FAILURE = "UPDATE_FOTONA_FORM_FAILURE";
export const DEACTIVATE_FOTONA_FORM_SUCCESS = "DEACTIVATE_FOTONA_FORM";
export const DEACTIVATE_FOTONA_FORM_FAILURE = "DEACTIVATE_FOTONA_FORM_FAILURE";
export const DEACTIVATE_ALL_FOTONA_FORM_SUCCESS = "DEACTIVATE_ALL_FOTONA_FORM";
export const DEACTIVATE_ALL_FOTONA_FORM_FAILURE = "DEACTIVATE_ALL_FOTONA_FORM_FAILURE";
export const LIST_FOTONA_FORM = "LIST_FOTONA_FORM";
export const FETCH_FOTONA_FORM = "FETCH_FOTONA_FORM";
