import React, { Component } from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";

class ServicesSection extends Component {
  constructor() {
    super();
    this.scrolled = false;
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {
    if (!this.scrolled) {
      this.scrolled = true;
      new WOW.WOW({
        live: false
      }).init();
    }
  };

  renderServicesItem() {
    const { data } = this.props;
    if (data == null) return null;
    const servicesItemElement = data.map((item, index) => (
      <div key={index} className={"col-lg-4 col-md-6 col-sm-12 m-0 p-0"}>
        <figure
          className="effect-apollo wow fadeIn"
          data-wow-delay={"0." + (2 + index) + "s"}
        >
          <video
            className={"service-image service-image-" + item.align + "-align"}
            style={{
              background: "transparent url('" + item.image + "')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% auto",
              backgroundPosition: "right"
            }}
          >
          </video>
          <video
            loop
            muted
            autoPlay
            playsInline
            className={"service-video service-video-" + item.alignVideo + "-align"}
          >
            <source src={item.webm} type="video/webm" />
            <source src={item.mp4} type="video/mp4" />
          </video>
          <figcaption>
            <h2>
              {item.title}
              <br />
              <span>{item.subTitle}</span>
            </h2>
            <p>
              {item.links &&
                item.links.map((link, linkIndex) => {
                  return (
                    <span key={linkIndex}>
                      <Link to={link.href} className="text-white">
                        {link.text}
                      </Link>
                      <br />
                    </span>
                  );
                })}
            </p>
          </figcaption>
        </figure>
      </div>
    ));
    return servicesItemElement;
  }

  render() {
    return (
      <div className="position-relative">
        <section className="section section-components mt--0 pb-0">
          <div
            className="text-center justify-content-center wow fadeInUp waves-effect waves-light"
            data-wow-delay="0.2s"
          >
            <h2 className="display-3">OUR SERVICES</h2>
            <p className="lead text-muted">Body areas to improve</p>
          </div>
          <div className="row align-items-center justify-content-center mr-0 ml-0">
            <div
              className="col-lg-12 col-md-12 row row-grid grid pr-0 pl-0 service-container wow fadeIn"
              data-wow-delay="0.3s"
              // style={{ maxWidth: "1900px" }}
              // style={{ maxWidth: "1440px" }}
              // style={{ maxWidth: "1040px" }}
            >
              {this.renderServicesItem()}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ServicesSection;
