import {
    FETCH_LEGAL_CONTENT,
    SAVE_LEGAL_CONTENT_SUCCESS,
    SAVE_LEGAL_CONTENT_FAILURE
  } from "../../actions/management/legal-content/types";
  
  export default function(state = {}, action) {
    switch (action.type) {
      case SAVE_LEGAL_CONTENT_SUCCESS:
        return { ...state, success: { message: action.payload } };
      case SAVE_LEGAL_CONTENT_FAILURE:
        return { ...state, error: { message: action.payload } };
      case FETCH_LEGAL_CONTENT:
        return { ...state, data: action.payload };
      default:
        return state;
    }
  }
  