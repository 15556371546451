import axios from "axios";
import { API_URL } from "../../../config";
import {
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAILURE,
  DEACTIVATE_MESSAGE_SUCCESS,
  DEACTIVATE_MESSAGE_FAILURE,
  DELETE_ALL_MESSAGE_SUCCESS,
  DELETE_ALL_MESSAGE_FAILURE,
  DEACTIVATE_ALL_MESSAGE_SUCCESS,
  DEACTIVATE_ALL_MESSAGE_FAILURE,
  FETCH_MESSAGE,
  LIST_MESSAGE,
  COUNT_NEW_MESSAGE
} from "./types";
import { LIMIT, PATIENT, MAIL_CONFIG } from "../../../config";
import {
  auth401
} from "../../../utils";

export function payload(CONST, data) {
  return {
    type: CONST,
    payload: data
  };
}

export function fetch(id, cb = null) {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const accessToken = (user && user.AccessToken) || null;
  const token = (accessToken && accessToken.id) || "";
  if (!id) {
    return function(dispatch) {
      dispatch(payload(FETCH_MESSAGE, null));
    };
  }
  return function(dispatch) {
    axios
      .get(`${API_URL}/messages/fetch/${id}`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        dispatch(payload(FETCH_MESSAGE, response.data));
        if (cb) cb();
      })
      .catch((error) => {
        auth401(error);
      });
  };
}

export function list(
  q = "",
  order = "",
  offset = 0,
  limit = LIMIT,
  type = "email"
) {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const accessToken = (user && user.AccessToken) || null;
  const token = (accessToken && accessToken.id) || "";

  return function(dispatch) {
    axios
      .get(
        `${API_URL}/messages/list?filter[order]=${order}&filter[offset]=${offset}&filter[limit]=${limit}&q=${q}&type=${type}`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        dispatch(payload(LIST_MESSAGE, response.data));
      })
      .catch((error) => {
        auth401(error);
      });
  };
}

export function countNewMessage(cb = null) {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const accessToken = (user && user.AccessToken) || null;
  const token = (accessToken && accessToken.id) || "";

  return function(dispatch) {
    if (token !== "")
      axios
        .get(`${API_URL}/messages/count-new-message`, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json"
          }
        })
        .then(response => {
          dispatch(payload(COUNT_NEW_MESSAGE, response.data));
          if (cb) cb();
        })
        .catch((error) => {
          auth401(error);
        });
  };
}

export function send(data, cb = null) {
  const userObject = JSON.parse(sessionStorage.getItem("user"));
  const accessToken = (userObject && userObject.AccessToken) || null;
  const token = (accessToken && accessToken.id) || "";
  const roleMapping = (userObject && userObject.RoleMapping) || null;
  const roleId = (roleMapping[0] && roleMapping[0].roleId) || PATIENT;
  const user = (userObject && userObject.User) || null;
  const userData = (userObject && userObject.UserData) || null;
  let from = MAIL_CONFIG.email;
  let senderName = MAIL_CONFIG.senderName;
  let formData = new FormData();

  if (roleId === PATIENT) {
    from = (user && user.email) || from;
    senderName =
      (
        ((userData && userData.firstName) || "") +
        " " +
        ((userData && userData.lastName) || "")
      ).trim() || senderName;
  }
  formData.append("from", from);
  formData.append("senderName", senderName);
  Object.keys(data).forEach(key => {
    if (key !== "attachments") formData.append(key, data[key]);
  });
  formData.append("text", data["message"].replace(/(<([^>]+)>)/g, ""));
  if (data.attachments)
    for (let i = 0; i < data.attachments.length; i++) {
      let file = data.attachments[i];
      formData.append("attachments[" + i + "]", file);
    }
  return function(dispatch) {
    axios
      .post(`${API_URL}/messages/send`, formData, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        if (response.data) {
          const status = response.data.Status;
          const message = response.data.Message;
          if (status === "Successed") {
            dispatch(payload(SEND_MESSAGE_SUCCESS, message));
          } else {
            dispatch(payload(SEND_MESSAGE_FAILURE, message));
          }
          if (cb) cb();
        }
      })
      .catch((error) => {
        dispatch(payload(SEND_MESSAGE_FAILURE, "Permission denied"));
        auth401(error);
      });
  };
}

export function deleteMsg(props) {
  const { id } = props;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const accessToken = (user && user.AccessToken) || null;
  const token = (accessToken && accessToken.id) || "";

  return function(dispatch) {
    axios
      .patch(
        `${API_URL}/messages/delete`,
        {
          id
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        if (response.data) {
          const status = response.data.Status;
          const message = response.data.Message;
          if (status === "Successed") {
            dispatch(payload(DELETE_MESSAGE_SUCCESS, message));
          } else {
            dispatch(payload(DELETE_MESSAGE_FAILURE, message));
          }
        }
      })
      .catch((error) => {
        dispatch(payload(DELETE_MESSAGE_FAILURE, "Permission denied"));
        auth401(error);
      });
  };
}

export function deleteAll(props) {
  const { messageIds } = props;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const accessToken = (user && user.AccessToken) || null;
  const token = (accessToken && accessToken.id) || "";
  return function(dispatch) {
    axios
      .patch(
        `${API_URL}/messages/delete/all`,
        {
          messageIds
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        if (response.data) {
          const status = response.data.Status;
          const message = response.data.Message;
          if (status === "Successed") {
            dispatch(payload(DELETE_ALL_MESSAGE_SUCCESS, message));
          } else {
            dispatch(payload(DELETE_ALL_MESSAGE_FAILURE, message));
          }
        }
      })
      .catch((error) => {
        dispatch(payload(DELETE_ALL_MESSAGE_FAILURE, "Permission denied"));
        auth401(error);
      });
  };
}

export function deactivate(props) {
  const { id } = props;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const accessToken = (user && user.AccessToken) || null;
  const token = (accessToken && accessToken.id) || "";

  return function(dispatch) {
    axios
      .patch(
        `${API_URL}/messages/deactivate`,
        {
          id
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        if (response.data) {
          const status = response.data.Status;
          const message = response.data.Message;
          if (status === "Successed") {
            dispatch(payload(DEACTIVATE_MESSAGE_SUCCESS, message));
          } else {
            dispatch(payload(DEACTIVATE_MESSAGE_FAILURE, message));
          }
        }
      })
      .catch((error) => {
        dispatch(payload(DEACTIVATE_MESSAGE_FAILURE, "Permission denied"));
        auth401(error);
      });
  };
}

export function deactivateAll(props) {
  const { messageIds } = props;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const accessToken = (user && user.AccessToken) || null;
  const token = (accessToken && accessToken.id) || "";
  return function(dispatch) {
    axios
      .patch(
        `${API_URL}/messages/deactivate/all`,
        {
          messageIds
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        if (response.data) {
          const status = response.data.Status;
          const message = response.data.Message;
          if (status === "Successed") {
            dispatch(payload(DEACTIVATE_ALL_MESSAGE_SUCCESS, message));
          } else {
            dispatch(payload(DEACTIVATE_ALL_MESSAGE_FAILURE, message));
          }
        }
      })
      .catch((error) => {
        dispatch(payload(DEACTIVATE_ALL_MESSAGE_FAILURE, "Permission denied"));
        auth401(error);
      });
  };
}
