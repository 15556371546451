import {
    FETCH_PATIENT,
    LIST_PATIENT,
    SAVE_PATIENT_SUCCESS,
    SAVE_PATIENT_FAILURE,
    UPDATE_PATIENT_SUCCESS,
    UPDATE_PATIENT_FAILURE,
    DEACTIVATE_PATIENT_SUCCESS,
    DEACTIVATE_PATIENT_FAILURE,
    ACTIVATE_PATIENT_SUCCESS,
    ACTIVATE_PATIENT_FAILURE,
    DEACTIVATE_ALL_PATIENT_SUCCESS,
    DEACTIVATE_ALL_PATIENT_FAILURE,
    ACTIVATE_ALL_PATIENT_SUCCESS,
    ACTIVATE_ALL_PATIENT_FAILURE,
    UPDATE_PHOTO_SUCCESS,
    UPDATE_PHOTO_FAILURE,
    FETCH_PHOTO,
    SEND_RESET_PASSWORD_LINK_SUCCESS,
    SEND_RESET_PASSWORD_LINK_FAILURE
  } from "../../actions/management/patient/types";
  
  export default function(state = {}, action) {
    switch (action.type) {
      case SAVE_PATIENT_SUCCESS:
        return { ...state, success: { message: action.payload } };
      case SAVE_PATIENT_FAILURE:
        return { ...state, error: { message: action.payload } };
      case UPDATE_PATIENT_SUCCESS:
        return { ...state, success: { message: action.payload } };
      case UPDATE_PATIENT_FAILURE:
        return { ...state, error: { message: action.payload } };
      case DEACTIVATE_PATIENT_SUCCESS:
        return { ...state, success: { message: action.payload } };
      case DEACTIVATE_PATIENT_FAILURE:
        return { ...state, error: { message: action.payload } };
      case ACTIVATE_PATIENT_SUCCESS:
        return { ...state, success: { message: action.payload } };
      case ACTIVATE_PATIENT_FAILURE:
        return { ...state, error: { message: action.payload } };
      case DEACTIVATE_ALL_PATIENT_SUCCESS:
        return { ...state, success: { message: action.payload } };
      case DEACTIVATE_ALL_PATIENT_FAILURE:
        return { ...state, error: { message: action.payload } };
      case ACTIVATE_ALL_PATIENT_SUCCESS:
        return { ...state, success: { message: action.payload } };
      case ACTIVATE_ALL_PATIENT_FAILURE:
        return { ...state, error: { message: action.payload } };
      case LIST_PATIENT:
        return { ...state, data: action.payload };
      case FETCH_PATIENT:
        return { ...state, data: action.payload };
      case UPDATE_PHOTO_SUCCESS:
        return { ...state, success: { message: action.payload } };
      case UPDATE_PHOTO_FAILURE:
        return { ...state, error: { message: action.payload } };
      case FETCH_PHOTO:
        return { ...state, item: action.payload };
      case SEND_RESET_PASSWORD_LINK_SUCCESS:
        return { ...state, success: { message: action.payload } };
      case SEND_RESET_PASSWORD_LINK_FAILURE:
        return { ...state, error: { message: action.payload } };
      default:
        return state;
    }
  }
  