import React, { Component } from "react";
import WOW from "wowjs"; 

class DetailSection extends Component {
  constructor() {
    super();
    this.scrolled = false;
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {
    if (!this.scrolled) {
      this.scrolled = true;
      new WOW.WOW({
        live: false
      }).init();
    }
  };

  render() {
    const { title, videoUrl, image, text } = this.props;
    return (
      <div>
        {title ? <h3 className="">{title}</h3> : null}
        {videoUrl ? (
          <div className="detail-video-embed-responsive embed-responsive embed-responsive-16by9 wow fadeIn" data-wow-delay="0.2s">
            <video
              loop
              muted
              autoPlay
              controls
              playsInline
              className={"service-video"}
            >
              <source src={videoUrl} type="video/mp4" />
            </video>
            {/* <iframe
              className="embed-responsive-item"
              src={videoUrl}
              allowFullScreen
              title="detail"
            /> */}
          </div>
        ) : null}
        {image ? <img src={image} alt="" width="100%" className="wow fadeIn" data-wow-delay="0.2s" /> : null}
        <div className="wow fadeIn" data-wow-delay="0.4s" dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    );
  }
}

export default DetailSection;
