export const SAVE_SERVICE_SUCCESS = "SAVE_SERVICE";
export const SAVE_SERVICE_FAILURE = "SAVE_SERVICE_FAILURE";
export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE";
export const UPDATE_SERVICE_FAILURE = "UPDATE_SERVICE_FAILURE";
export const ACTIVATE_SERVICE_SUCCESS = "ACTIVATE_SERVICE";
export const ACTIVATE_SERVICE_FAILURE = "ACTIVATE_SERVICE_FAILURE";
export const ACTIVATE_ALL_SERVICE_SUCCESS = "ACTIVATE_ALL_SERVICE";
export const ACTIVATE_ALL_SERVICE_FAILURE = "ACTIVATE_ALL_SERVICE_FAILURE";
export const DEACTIVATE_SERVICE_SUCCESS = "DEACTIVATE_SERVICE";
export const DEACTIVATE_SERVICE_FAILURE = "DEACTIVATE_SERVICE_FAILURE";
export const DEACTIVATE_ALL_SERVICE_SUCCESS = "DEACTIVATE_ALL_SERVICE";
export const DEACTIVATE_ALL_SERVICE_FAILURE = "DEACTIVATE_ALL_SERVICE_FAILURE";
export const LIST_SERVICE = "LIST_SERVICE";
export const FETCH_SERVICE = "FETCH_SERVICE";
export const LIST_SERVICE_SELECT = "LIST_SERVICE_SELECT";
