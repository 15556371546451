import React, { Component } from "react";
import Slider from "react-slick";
import TestimonialsStyle from "../../../../assets/js/views/sites/home/sections/testimonials-style";

const TRIANGLE_IMAGE = "/static/media/sites/home/testimonials/triangle.png";
const TESTIMONIALS_BACKGROUND_IMAGE = "/static/media/sites/home/testimonials/testimonials-background.jpg";

const SETTINGS = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
  lazyLoad: true
};


class TestimonialsSection extends Component {
  renderTestimonialsItem() {
    const { data } = this.props;
    if (data == null) return null;
    const testimonialsItemElement = data.map((item, index) => (
      <div key={index} className="container">
        <div className="slide-wrapper">
          <div className="text-wrapper">
            <p>{item.message}</p>
          </div>
          <div className="client-name-wrapper">
            <h4 className="mb-0">
              {item.name}
              <span>{item.age}</span>
            </h4>
            <div className="line" />
            <p className="mb-0">{item.description}</p>
          </div>
          <img src={TRIANGLE_IMAGE} alt="" />
        </div>
      </div>
    ));
    return testimonialsItemElement;
  }

  render() {
    return (
      <div
        className="position-relative"
        style={{
          backgroundImage: "url('" + TESTIMONIALS_BACKGROUND_IMAGE + "')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat"
        }}
      >
        <section
          className="section section-lg bg-gradient-warning"
          style={TestimonialsStyle.backgroundOpacity}
        >
          <div className="container testimonials">
            <div className="row row-grid justify-content-center text-center align-items-center">
              <div className="col-lg-10 p-0 m-0">
                <h3 className="display-3 text-white">TESTIMONIALS</h3>
                <p className="lead text-white">Clients say about us</p>
                <Slider {...SETTINGS}>{this.renderTestimonialsItem()}</Slider>
              </div>
            </div>
          </div>
        </section>
        {/* SVG separator */}
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            x={0}
            y={0}
            viewBox="0 0 2560 100"
            preserveAspectRatio="none"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon
              className="fill-secondary"
              points="2560 0 2560 100 0 100"
            />
          </svg>
        </div>
      </div>
    );
  }
}

export default TestimonialsSection;
