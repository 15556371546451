import React, { Component } from "react";
import ScrollIntoView from "../../../components/scroll-to-view";
import {
  HeaderSection,
  ServicesSection,
  WorksSection,
  SliderSection,
  TeamSection,
  AdvantagesSection
} from "./sections";
import Certification from "../../../components/sites/certification";
import Contact from "../../../components/sites/contact";
import Footer from "../../../components/sites/footer";
import "../../../assets/css/animate.css";
import "../../../assets/css/hover.css";
import "../../../assets/css/normalize.css";
import "../../../assets/css/hover-effect.css";
import "../../../assets/css/slink-slider.css";
import "../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";

class HomeIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null
    };
  }

  componentDidMount() {
    const link = "/static/data/sites/home/index.json";
    fetch(link)
      .then(r => r.json())
      .then(data => {
        this.setState({
          data
        });
      });
  }

  render() {
    return this.state.data ? (
      <ScrollIntoView>
        <HeaderSection data={this.state.data.header} />
        <ServicesSection data={this.state.data.services} />
        <SliderSection data={this.state.data.slider} />
        <WorksSection data={this.state.data.works} />
        <TeamSection data={this.state.data.team} />
        <AdvantagesSection data={this.state.data.advantages} />
        <Contact />
        <Certification data={this.state.data.certification} />
        <Footer />
      </ScrollIntoView>
    ) : null;
  }
}

export default HomeIndex;
