import React, { Component } from "react";
import WOW from 'wowjs';
import AdvantagesStyle from "../../../../assets/js/views/sites/home/sections/advantages-style";

const ADVANTAGES_BACKGROUND_IMAGE = "/static/media/sites/home/advantages/advantages-background.jpg";

class AdvantagesSection extends Component {
  constructor() {
    super();
    this.scrolled = false;
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {
    if (!this.scrolled) {
      this.scrolled = true;
      new WOW.WOW({
        live: false
      }).init();
    }
  };

  renderAdvantagesItem() {
    const { data } = this.props;
    if (data == null) return null;
    const advantagesItemElement = data.map((item, index) => (
      <div key={index} className="col-lg-4">
        <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary wow bounceIn">
          <i className={"fa " + item.icon + " text-primary"} />
        </div>
        <h5 className="text-white mt-3 wow fadeIn" data-wow-delay="0.3s">{item.text}</h5>
        <p className="text-white mt-3 wow fadeIn" data-wow-delay="0.3s">{item.description}</p>
      </div>
    ));
    return advantagesItemElement;
  }

  render() {
    const advantagesItemElement = this.renderAdvantagesItem();

    return (
      <div
        className="position-relative"
        style={{
          backgroundImage: "url('" + ADVANTAGES_BACKGROUND_IMAGE + "')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat"
        }}
      >
        <section
          className="section section-lg bg-gradient-default"
          style={AdvantagesStyle.backgroundOpacity}
        >
          <div className="container pt-lg pb-300">
            <div className="row text-center justify-content-center">
              <div className="col-lg-10">
                <h2 className="display-3 text-white wow fadeIn" data-wow-delay="0.2s">OUR ADVANTAGES</h2>
                <p className="lead text-white wow fadeIn" data-wow-delay="0.2s">Why choose us?</p>
              </div>
            </div>
            <div className="row row-grid mt-5 text-center justify-content-center">
              {advantagesItemElement}
            </div>
          </div>
        </section>
        {/* SVG separator */}
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            x={0}
            y={0}
            viewBox="0 0 2560 100"
            preserveAspectRatio="none"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon className="fill-white" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </div>
    );
  }
}

export default AdvantagesSection;
