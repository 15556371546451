import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import WOW from "wowjs"; 

const SETTINGS = {
  className: "slider variable-width",
  dots: false,
  infinite: true,
  centerMode: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  autoplay: true,
  speed: 2500,
  fade: false,
  arrows: true,
  swipe: true,
  swipeToSlide: true,
  touchMove: true,
  touchThreshold: 5,
  useTransform: true,
  outerEdgeLimit: true,
  waitForAnimate: true,
};

class SliderSection extends Component {
  constructor() {
    super();
    this.scrolled = false;
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {
    if (!this.scrolled) {
      this.scrolled = true;
      new WOW.WOW({
        live: false
      }).init();
    }
  };

  render() {
    const { data } = this.props;
    if (data == null) return null;
    return (
      <div className="position-relative slider-background">
        <section
          className="section section-lg row align-items-center justify-content-center mr-0 ml-0"
        >
          <div
            className="slider-xs slider-container wow fadeIn"
            data-wow-delay="0.4s"
          >
            <Slider {...SETTINGS}>
              {data.map((item, index) => (
                <Link to={item.url} key={index}>
                  <div
                    className="m-2 mt-4"
                    style={{ position: "relative" }}
                  >
                    <div className="image">
                      <img src={item.image} alt="" />
                    </div>

                    <div className="slider-image-overlay">
                      <button className="text-uppercase btn-round btn-sm btn btn-warning">
                        Learn more
                      </button>
                    </div>
                  </div>
                  <div
                    className="m-2 mb-5 text-center justify-content-center"
                  >
                    <p className="lead text-white text-uppercase mt-1">
                      {item.title}
                    </p>
                  </div>
                </Link>
              ))}
            </Slider>
          </div>
        </section>
        {/* SVG separator */}
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            x={0}
            y={0}
            viewBox="0 0 2560 100"
            preserveAspectRatio="none"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon
              className="fill-secondary"
              points="2560 0 2560 100 0 100"
            />
          </svg>
        </div>
      </div>
    );
  }
}

export default SliderSection;
