import React, { Component } from "react";
import history from "../../../routes/history";

class Error500Index extends Component {
  render() {
    return (
      <div className="error-page">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-6 offset-lg-3 col-sm-6 offset-sm-3 col-12 p-3 error-main">
              <div className="row">
                <div className="col-lg-8 col-12 col-sm-10 offset-lg-2 offset-sm-1">
                  <h1 className="m-0">500</h1>
                  <h6 className="text-uppercase">Internal Error</h6>
                  <p>Please either report this error to an administrator.</p>
                  <p className="small">
                    <button
                      onClick={history.goBack}
                      className="btn-link border-0 text-default"
                    >
                      « Go Back
                    </button>{" "}
                    /{" "}
                    <a
                      href="/"
                      className="font-weight-light"
                    >
                      Go Home »
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Error500Index;
