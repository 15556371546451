import React from "react";
import Loadable from "react-loadable";

// import SiteLayout from "../views/sites/layout";

function Loading() {
  return <div>Loading...</div>;
}

const HomeIndex = Loadable({
  loader: () => import("../views/sites/home"),
  loading: Loading
});

const ContactIndex = Loadable({
  loader: () => import("../views/sites/contact"),
  loading: Loading
});

//technologies
const HydrafacialIndex = Loadable({
  loader: () => import("../views/sites/pages/hydrafacial-md"),
  loading: Loading
});

const BodyMiniFXIndex = Loadable({
  loader: () => import("../views/sites/pages/body-mini-fx"),
  loading: Loading
});

const CoolSculptingIndex = Loadable({
  loader: () => import("../views/sites/pages/coolsculpting"),
  loading: Loading
});

const PrecisionTXIndex = Loadable({
  loader: () => import("../views/sites/pages/precisiontx"),
  loading: Loading
});

//services
const ThreeForMeIndex = Loadable({
  loader: () => import("../views/sites/pages/threeforme"),
  loading: Loading
});

const SkinRenewalIndex = Loadable({
  loader: () => import("../views/sites/pages/skin-renewal"),
  loading: Loading
});

const FormaPlusIndex = Loadable({
  loader: () => import("../views/sites/pages/forma-plus"),
  loading: Loading
});

const FatGraftingIndex = Loadable({
  loader: () => import("../views/sites/pages/fat-grafting"),
  loading: Loading
});

const MicroFatTransferIndex = Loadable({
  loader: () => import("../views/sites/pages/micro-fat-transfer"),
  loading: Loading
});

const HairRemovalIndex = Loadable({
  loader: () => import("../views/sites/pages/hair-removal"),
  loading: Loading
});

const FractoraIndex = Loadable({
  loader: () => import("../views/sites/pages/fractora"),
  loading: Loading
});

const PRPHairGrowthTreatmentsIndex = Loadable({
  loader: () => import("../views/sites/pages/prp-hair-growth-treatments"),
  loading: Loading
});

const SkinRevitalizationIndex = Loadable({
  loader: () => import("../views/sites/pages/skin-revitalization"),
  loading: Loading
});

const SmartLipoIndex = Loadable({
  loader: () => import("../views/sites/pages/smartlipo"),
  loading: Loading
});

const LaserLegVeinRemovalIndex = Loadable({
  loader: () => import("../views/sites/pages/laser-leg-vein-removal"),
  loading: Loading
});

const LaserStretchMarkRemovalIndex = Loadable({
  loader: () => import("../views/sites/pages/laser-stretch-mark-removal"),
  loading: Loading
});

const LaserSkinResurfacingRemovalIndex = Loadable({
  loader: () => import("../views/sites/pages/laser-skin-resurfacing-removal"),
  loading: Loading
});

const LaserScarRemovalIndex = Loadable({
  loader: () => import("../views/sites/pages/laser-scar-removal"),
  loading: Loading
});

const SweatGlandAblationIndex = Loadable({
  loader: () => import("../views/sites/pages/sweat-gland-ablation"),
  loading: Loading
});

const PriceIndex = Loadable({
  loader: () => import("../views/sites/pages/price"),
  loading: Loading
});

const BotoxIndex = Loadable({
  loader: () => import("../views/sites/pages/botox"),
  loading: Loading
});

const JuvedermIndex = Loadable({
  loader: () => import("../views/sites/pages/juvederm"),
  loading: Loading
});

const KybellaIndex = Loadable({
  loader: () => import("../views/sites/pages/kybella"),
  loading: Loading
});

const LatisseIndex = Loadable({
  loader: () => import("../views/sites/pages/latisse"),
  loading: Loading
});

const GynecologyIndex = Loadable({
  loader: () => import("../views/sites/pages/gynecology"),
  loading: Loading
});

const ErectileDysfunctionIndex = Loadable({
  loader: () => import("../views/sites/pages/erectile-dysfunction"),
  loading: Loading
});

const NonSurgicalBlepharoplastyIndex = Loadable({
  loader: () => import("../views/sites/pages/non-surgical-blepharoplasty"),
  loading: Loading
});

const DrTrieuIndex = Loadable({
  loader: () => import("../views/sites/pages/dr-trieu"),
  loading: Loading
});

const routes = [
  { path: "/", name: "Home", component: HomeIndex, exact: true },
  {
    path: "/index",
    name: "Home",
    component: HomeIndex
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactIndex
  },
  {
    path: "/hydrafacial-md",
    name: "Hydrafacial MD",
    component: HydrafacialIndex
  },
  {
    path: "/body-mini-fx",
    name: "Body Mini FX",
    component: BodyMiniFXIndex
  },
  {
    path: "/coolsculpting",
    name: "Cool Sculpting",
    component: CoolSculptingIndex
  },
  {
    path: "/precisiontx",
    name: "Precision TX 2",
    component: PrecisionTXIndex
  },
  {
    path: "/three-for-me",
    name: "Three For Me",
    component: ThreeForMeIndex
  },
  {
    path: "/skin-renewal",
    name: "Skin Renewal",
    component: SkinRenewalIndex
  },
  {
    path: "/forma-plus",
    name: "Forma Plus",
    component: FormaPlusIndex
  },
  {
    path: "/fat-grafting",
    name: "Fat Grafting",
    component: FatGraftingIndex
  },
  {
    path: "/micro-fat-transfer",
    name: "Micro Fat Transfer",
    component: MicroFatTransferIndex
  },
  {
    path: "/hair-removal",
    name: "Hair Removal",
    component: HairRemovalIndex
  },
  {
    path: "/fractora",
    name: "Fractora",
    component: FractoraIndex
  },
  {
    path: "/prp-hair-growth-treatments",
    name: "PRP Hair Growth Treatments",
    component: PRPHairGrowthTreatmentsIndex
  },
  {
    path: "/skin-revitalization",
    name: "Skin Revitalization",
    component: SkinRevitalizationIndex
  },
  {
    path: "/smartlipo",
    name: "Smart Lipo",
    component: SmartLipoIndex
  },
  {
    path: "/laser-stretch-mark-removal",
    name: "Skin Resurfacing Wrinkle",
    component: LaserStretchMarkRemovalIndex
  },
  {
    path: "/laser-leg-vein-removal",
    name: "Leg Vein Treatment",
    component: LaserLegVeinRemovalIndex
  },
  {
    path: "/laser-skin-resurfacing-removal",
    name: "Laser Skin Resurfacing Removal",
    component: LaserSkinResurfacingRemovalIndex
  },
  {
    path: "/laser-scar-removal",
    name: "Scar Removal",
    component: LaserScarRemovalIndex
  },
  {
    path: "/sweat-gland-ablation",
    name: "Sweat Gland Ablation",
    component: SweatGlandAblationIndex
  },
  {
    path: "/price",
    name: "Price",
    component: PriceIndex
  },
  {
    path: "/botox",
    name: "Botox",
    component: BotoxIndex
  },
  {
    path: "/juvederm",
    name: "Juvederm",
    component: JuvedermIndex
  },
  {
    path: "/kybella",
    name: "Kybella",
    component: KybellaIndex
  },
  {
    path: "/latisse",
    name: "Latisse",
    component: LatisseIndex
  },
  {
    path: "/gynecology",
    name: "Gynecology",
    component: GynecologyIndex
  },
  {
    path: "/erectile-dysfunction",
    name: "Erectile Dysfunction",
    component: ErectileDysfunctionIndex
  },
  {
    path: "/non-surgical-blepharoplasty",
    name: "Non Surgical Blepharoplasty",
    component: NonSurgicalBlepharoplastyIndex
  },
  {
    path: "/dr-trieu",
    name: "About Dr. Trieu",
    component: DrTrieuIndex
  }
];

export default routes;
