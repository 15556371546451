export const SAVE_APPOINTMENT_SUCCESS = 'SAVE_APPOINTMENT_SUCCESS';
export const SAVE_APPOINTMENT_FAILURE = 'SAVE_APPOINTMENT_FAILURE';
export const UPDATE_APPOINTMENT_SUCCESS = 'UPDATE_APPOINTMENT_SUCCESS';
export const UPDATE_APPOINTMENT_FAILURE = 'UPDATE_APPOINTMENT_FAILURE';
export const SAVE_UNAVAILABLE_SUCCESS = 'SAVE_UNAVAILABLE_SUCCESS';
export const SAVE_UNAVAILABLE_FAILURE = 'SAVE_UNAVAILABLE_FAILURE';
export const UPDATE_UNAVAILABLE_SUCCESS = 'UPDATE_UNAVAILABLE_SUCCESS';
export const UPDATE_UNAVAILABLE_FAILURE = 'UPDATE_UNAVAILABLE_FAILURE';
export const CANCEL_APPOINTMENT_SUCCESS = 'CANCEL_APPOINTMENT_SUCCESS';
export const CANCEL_APPOINTMENT_FAILURE = 'CANCEL_APPOINTMENT_FAILURE';
export const DELETE_APPOINTMENT_SUCCESS = 'DELETE_APPOINTMENT_SUCCESS';
export const DELETE_APPOINTMENT_FAILURE = 'DELETE_APPOINTMENT_FAILURE';
export const GET_APPOINTMENT = 'GET_APPOINTMENT';
export const FETCH_APPOINTMENT = 'FETCH_APPOINTMENT';
export const LIST_FILTER = "LIST_FILTER";
export const FETCH_UNAVAILABLE = "FETCH_UNAVAILABLE";
export const LIST_AVAILABLE_HOUR = "LIST_AVAILABLE_HOUR";
export const LIST_APPOINTMENT_HISTORY = "LIST_APPOINTMENT_HISTORY";
