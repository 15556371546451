import React, { Component } from "react";
// import ReCAPTCHA from "react-google-recaptcha";
import history from "../../../../routes/history";
import { requiredValidation, emailValidation, maxLength255Validation } from "../../../../utils/index";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../../../actions/management/auth/reset-password";
import { connect } from "react-redux";
import InputField from "../../../../components/form/input-field";

class ForgotPasswordIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: ""
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const message = location.state && location.state.message;
    if (history.action === "PUSH") {
      this.setState({
        message
      });
    }
  }

  handleFormSubmit = props => {
    this.props.forgotPassword(props);
  };

  renderHeaderForm() {
    return (
      <div className="card-header pb-4 pt-4">
        <div className="btn-wrapper text-center">
          <p className="lead mt-0">FORGOT PASSWORD?</p>
          <small>
            Enter your email. We'll email instructions on how to reset your
            password.
          </small>
        </div>
      </div>
    );
  }

  renderMessage(message, valid = true) {
    return message !== "" ? (
      <div className={(valid ? "valid" : "invalid") + "-feedback d-block pb-3"}>
        {message}
      </div>
    ) : null;
  }

  renderForm() {
    const { handleSubmit, submitting } = this.props;
    const errorMessage =
      (this.props.errorMessage && this.props.errorMessage.forgotPassword) || "";
    return (
      <div className="card-body px-lg-5 py-lg-5">
        <form onSubmit={handleSubmit(this.handleFormSubmit)}>
          {errorMessage === ""
            ? this.renderMessage(this.state.message)
            : this.renderMessage(errorMessage, false)}

          <Field
            name="email"
            component={InputField}
            type="email"
            placeholder="Email"
            icon="fa-envelope"
            validate={[requiredValidation, emailValidation, maxLength255Validation]}
          />
          <div className="text-center">
            <button
              type="submit"
              className="btn-round btn btn-default font-weight-light mt-4"
              disabled={submitting}
            >
              Send
            </button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return (
      <section className="section section-shaped section-lg">
        <div className="shape shape-style-1 bg-gradient-default" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="card bg-gradient-secondary shadow border-0">
                {this.renderHeaderForm()}
                {this.renderForm()}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return { errorMessage: state.resetPass.error };
}

ForgotPasswordIndex = reduxForm({ form: "forgotPassword" })(
  ForgotPasswordIndex
);

export default connect(
  mapStateToProps,
  actions
)(ForgotPasswordIndex);
