import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import WOW from "wowjs";

class ExperienceSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedIndex: 0
    };
    this.scrolled = false;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {
    if (!this.scrolled) {
      this.scrolled = true;
      new WOW.WOW({
        live: false
      }).init();
    }
  };

  toggle = index => {
    this.setState({
      modal: !this.state.modal
    });
    if (index !== null) {
      this.setState({
        selectedIndex: index
      });
    }
  };

  render() {
    const { data, title } = this.props;
    return data.length > 0 ? (
      <div>
        <Modal
          isOpen={this.state.modal}
          size="lg"
          toggle={() => this.toggle(null)}
        >
          <ModalBody className="p-0">
            <div className="embed-responsive embed-responsive-16by9">
              <video
                loop
                autoPlay
                controls
                playsInline
                className={"service-video"}
                src={data[this.state.selectedIndex].video}
                type="video/mp4"
              />
              {/* <iframe
                className="embed-responsive-item"
                src={data[this.state.selectedIndex].video}
                allowFullScreen
                title="video"
              /> */}
            </div>
          </ModalBody>
        </Modal>
        <p className="lead pt-0 wow fadeIn" data-wow-delay="0.2s">
          {title}
        </p>
        <div className="row">
          {data.map((item, index) => (
            <div
              key={index}
              className={
                "col-lg-4 mb-4 " + (index === 0 ? "col-md-12" : "col-md-6")
              }
              onClick={() => this.toggle(index)}
            >
              <div
                className="video-thumbnail wow fadeIn"
                data-wow-delay={"0." + (3 + index) + "s"}
              >
                <img
                  className="img-fluid z-depth-1 shadow"
                  src={item.image}
                  alt=""
                  style={{ cursor: "pointer" }}
                />
              </div>
              <small>{item.title}</small>
            </div>
          ))}
        </div>
      </div>
    ) : null;
  }
}

export default ExperienceSection;
