import {
  FETCH_SERVICE,
  LIST_SERVICE,
  SAVE_SERVICE_SUCCESS,
  SAVE_SERVICE_FAILURE,
  UPDATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_FAILURE,
  DEACTIVATE_SERVICE_SUCCESS,
  DEACTIVATE_SERVICE_FAILURE,
  DEACTIVATE_ALL_SERVICE_SUCCESS,
  DEACTIVATE_ALL_SERVICE_FAILURE,
  ACTIVATE_SERVICE_SUCCESS,
  ACTIVATE_SERVICE_FAILURE,
  ACTIVATE_ALL_SERVICE_SUCCESS,
  ACTIVATE_ALL_SERVICE_FAILURE,
  LIST_SERVICE_SELECT
} from "../../actions/management/service/types";

export default function(state = {}, action) {
  switch (action.type) {
    case SAVE_SERVICE_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case SAVE_SERVICE_FAILURE:
      return { ...state, error: { message: action.payload } };
    case UPDATE_SERVICE_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case UPDATE_SERVICE_FAILURE:
      return { ...state, error: { message: action.payload } };
    case DEACTIVATE_SERVICE_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case DEACTIVATE_SERVICE_FAILURE:
      return { ...state, error: { message: action.payload } };
    case DEACTIVATE_ALL_SERVICE_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case DEACTIVATE_ALL_SERVICE_FAILURE:
      return { ...state, error: { message: action.payload } };
    case ACTIVATE_SERVICE_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case ACTIVATE_SERVICE_FAILURE:
      return { ...state, error: { message: action.payload } };
    case ACTIVATE_ALL_SERVICE_SUCCESS:
      return { ...state, success: { message: action.payload } };
    case ACTIVATE_ALL_SERVICE_FAILURE:
      return { ...state, error: { message: action.payload } };
    case LIST_SERVICE:
      return { ...state, data: action.payload };
    case FETCH_SERVICE:
      return { ...state, data: action.payload };
    case LIST_SERVICE_SELECT:
      return { ...state, select: action.payload };
    default:
      return state;
  }
}
