import React, { Component } from "react";
import * as actions from "../../../actions/management/toggle-theme";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import ToggleSwitch from "../../../components/form/toggle-switch";

class Footer extends Component {
  constructor() {
    super();
    this.toggleTheme = this.toggleTheme.bind(this);
  }

  componentDidMount() {
    this.props.fetch(() => {
      this.toggleTheme(sessionStorage.getItem("theme") === 'true');
    });
  }

  toggleTheme(value) {
    const theme = value;//true ? "dark" : "light";
    this.props.toggle(theme);
    document.documentElement.classList.add("color-theme-in-transition");
    document.documentElement.setAttribute("data-theme", theme ? 'dark' : 'light');
    window.setTimeout(() => {
      document.documentElement.classList.remove("color-theme-in-transition");
    }, 1000);
  }

  render() {
    return (
      <div className="main-footer pb-2">
        <div className="text-muted pull-left main-content-footer w-9">
          <small>
            Copyright &copy;{" "}
            <a href="https://clinixos.com" className="text-default">
              ClinixOS
            </a>
            .
          </small>
        </div>
        <div className="pull-right main-content-footer w-1">
          <ToggleSwitch
            id='theme'
            optionLabels={['Dark theme', 'Light theme']}
            onChange={this.toggleTheme}
            checked={sessionStorage.getItem("theme") === 'true'}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loaded: !!state.toggleTheme.data,
    data: state.toggleTheme.data,
    errorMessage: state.toggleTheme.error,
    successMessage: state.toggleTheme.success
  };
}

Footer = reduxForm({ form: "toggleTheme" })(
  Footer
);

export default connect(
  mapStateToProps,
  actions
)(Footer);
