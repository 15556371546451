import {
    FETCH_ADVERTISEMENT,
    LIST_ADVERTISEMENT,
    SAVE_ADVERTISEMENT_SUCCESS,
    SAVE_ADVERTISEMENT_FAILURE,
    UPDATE_ADVERTISEMENT_SUCCESS,
    UPDATE_ADVERTISEMENT_FAILURE,
    DEACTIVATE_ADVERTISEMENT_SUCCESS,
    DEACTIVATE_ADVERTISEMENT_FAILURE,
    // ACTIVATE_ADVERTISEMENT_SUCCESS,
    // ACTIVATE_ADVERTISEMENT_FAILURE,
    DEACTIVATE_ALL_ADVERTISEMENT_SUCCESS,
    DEACTIVATE_ALL_ADVERTISEMENT_FAILURE,
    // ACTIVATE_ALL_ADVERTISEMENT_SUCCESS,
    // ACTIVATE_ALL_ADVERTISEMENT_FAILURE
  } from "../../actions/management/advertisement/types";
  
  export default function(state = {}, action) {
    switch (action.type) {
      case SAVE_ADVERTISEMENT_SUCCESS:
        return { ...state, success: { message: action.payload } };
      case SAVE_ADVERTISEMENT_FAILURE:
        return { ...state, error: { message: action.payload } };
      case UPDATE_ADVERTISEMENT_SUCCESS:
        return { ...state, success: { message: action.payload } };
      case UPDATE_ADVERTISEMENT_FAILURE:
        return { ...state, error: { message: action.payload } };
      case DEACTIVATE_ADVERTISEMENT_SUCCESS:
        return { ...state, success: { message: action.payload } };
      case DEACTIVATE_ADVERTISEMENT_FAILURE:
        return { ...state, error: { message: action.payload } };
      // case ACTIVATE_ADVERTISEMENT_SUCCESS:
      //   return { ...state, success: { message: action.payload } };
      // case ACTIVATE_ADVERTISEMENT_FAILURE:
      //   return { ...state, error: { message: action.payload } };
      case DEACTIVATE_ALL_ADVERTISEMENT_SUCCESS:
        return { ...state, success: { message: action.payload } };
      case DEACTIVATE_ALL_ADVERTISEMENT_FAILURE:
        return { ...state, error: { message: action.payload } };
      // case ACTIVATE_ALL_ADVERTISEMENT_SUCCESS:
      //   return { ...state, success: { message: action.payload } };
      // case ACTIVATE_ALL_ADVERTISEMENT_FAILURE:
      //   return { ...state, error: { message: action.payload } };
      case LIST_ADVERTISEMENT:
        return { ...state, data: action.payload };
      case FETCH_ADVERTISEMENT:
        return { ...state, data: action.payload };
      default:
        return state;
    }
  }
  