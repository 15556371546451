export const SAVE_PLTR_FORM_SUCCESS = "SAVE_PLTR_FORM";
export const SAVE_PLTR_FORM_FAILURE = "SAVE_PLTR_FORM_FAILURE";
export const UPDATE_PLTR_FORM_SUCCESS = "UPDATE_PLTR_FORM";
export const UPDATE_PLTR_FORM_FAILURE = "UPDATE_PLTR_FORM_FAILURE";
export const DEACTIVATE_PLTR_FORM_SUCCESS = "DEACTIVATE_PLTR_FORM";
export const DEACTIVATE_PLTR_FORM_FAILURE = "DEACTIVATE_PLTR_FORM_FAILURE";
export const DEACTIVATE_ALL_PLTR_FORM_SUCCESS = "DEACTIVATE_ALL_PLTR_FORM";
export const DEACTIVATE_ALL_PLTR_FORM_FAILURE = "DEACTIVATE_ALL_PLTR_FORM_FAILURE";
export const LIST_PLTR_FORM = "LIST_PLTR_FORM";
export const FETCH_PLTR_FORM = "FETCH_PLTR_FORM";
