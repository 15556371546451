import React, { Component } from "react";
import WOW from "wowjs"; 

class TreatmentTimeSection extends Component {
  constructor() {
    super();
    this.scrolled = false;
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {
    if (!this.scrolled) {
      this.scrolled = true;
      new WOW.WOW({
        live: false
      }).init();
    }
  };

  render() {
    const { data, bgColor } = this.props;
    return data.length === 0 ? null : (
      <div className=" wow fadeInRight" data-wow-delay="0.2s" >
        <div className={bgColor + " text-white text-uppercase rounded-30 mb-5"}>
          <div className="p-5 p-md-2 p-lg-5 text-center justify-content-center">
            {data.map((item, index) => (
              <div key={index}>
                {item.title ? (
                  <div className="treatment-time-title">
                    {item.title}
                  </div>
                ) : null}
                {item.text ? (
                  <div className="treatment-time-title">{item.text}</div>
                ) : null}
                {item.description ? (
                  <div
                    className={
                      "font-weight-light m-0 " +
                      (item.descriptionClassName
                        ? item.descriptionClassName
                        : "lead")
                    }
                  >
                    {item.description}
                  </div>
                ) : null}
                {data.length === index + 1 ? null : <hr className="leaf m-1" />}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default TreatmentTimeSection;
