class PDFJs {
  init = (source, type, element) => {
    if (source && source !== "" && element) {
      let iframe = document.getElementById("pdf-viewer");
      if (!iframe) {
        iframe = document.createElement("iframe");
        iframe.id = "pdf-viewer";
        iframe.width = "100%";
        iframe.height = "100%";
        element.appendChild(iframe);
      }
      if (type === "base64") {
        const url = "./pdfjs/web/viewer.html?file=";
        const pdfAsArray = this.convertDataURIToBinary(source);
        let binaryData = [];
        binaryData.push(pdfAsArray);
        const dataPdf = window.URL.createObjectURL(
          new Blob(binaryData, { type: "application/pdf" })
        );
        iframe.src = url + encodeURIComponent(dataPdf);
      } else {
        const url = "./pdfjs/web/viewer-only.html?file=";
        iframe.src = `${url}${source}`;
      }
    }
  };

  convertDataURIToBinary = base64 => {
    if (base64 && base64 !== undefined && base64 !== "") {
      let raw = window.atob(base64);
      let rawLength = raw.length;
      let array = new Uint8Array(new ArrayBuffer(rawLength));

      for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
      }
      return array;
    }
    return null;
  };
}

export default PDFJs;
